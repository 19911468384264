import { createReducer } from 'redux-act';

import {
  MEDIA_CATEGORIES_FETCH_DATA_INIT,
  MEDIA_CATEGORIES_FETCH_DATA_SUCCESS,
  MEDIA_CATEGORIES_FETCH_DATA_FAIL,
  MEDIA_CATEGORIES_DELETE_CATEGORY_INIT,
  MEDIA_CATEGORIES_DELETE_CATEGORY_SUCCESS,
  MEDIA_CATEGORIES_DELETE_CATEGORY_FAIL,
  MEDIA_CATEGORIES_CLEAR_DATA,
  MEDIA_CATEGORIES_CREATE_CATEGORY_INIT,
  MEDIA_CATEGORIES_CREATE_CATEGORY_SUCCESS,
  MEDIA_CATEGORIES_CREATE_CATEGORY_FAIL,
  MEDIA_CATEGORIES_MODIFY_CATEGORY_INIT,
  MEDIA_CATEGORIES_MODIFY_CATEGORY_SUCCESS,
  MEDIA_CATEGORIES_MODIFY_CATEGORY_FAIL,
  MEDIA_CATEGORIES_CLEAN_UP,
  MEDIA_CATEGORIES_CLEAR_DATA_LOGOUT,
  SET_MEDIA_CATEGORY,
} from 'state/actions/mediaCategories';

const initialState = {
  list: [],
  mediaCategory: {
    _id: '',
    name: '',
    description: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const mediaCategoriesReducer = createReducer(
  {
    [MEDIA_CATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MEDIA_CATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      mediaCategory: payload.mediaCategory || initialState.mediaCategory,
      loading: false,
      error: null,
    }),
    [MEDIA_CATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [MEDIA_CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_CATEGORIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [MEDIA_CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.category),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [MEDIA_CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_CATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_CATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem._id === payload.id) {
              return {
                _id: payload.id,
                name: payload.mediaCategory.name,
                description: payload.mediaCategory.description,
                siteId: payload.mediaCategory.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.mediaCategory.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [MEDIA_CATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_CATEGORIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [MEDIA_CATEGORIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [SET_MEDIA_CATEGORY]: (state, payload) => ({
      ...state,
      mediaCategory: payload.data,
    }),
  },
  initialState
);
