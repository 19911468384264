import { format } from 'date-fns';
import axios from 'axios';
import moment from 'moment';

export const getAnalyticsReport = (report) => {
  const { siteId, startDate, endDate } = report;
  const getData = async () => {
    let data;
    try {
      data = await axios({
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        method: 'post',
        url: 'https://analytics.adactive.asia/analytics/get',
        data: {
          siteId: siteId,
          title: 'screensavers',
          start: moment(startDate).format('YYYY-MM-DD') + 'T00:00:00.000Z',
          end: moment(endDate).format('YYYY-MM-DD') + 'T23:59:59.000Z',
        },
      });
    } catch (e) {
      console.error(e);
    }
    return data;
  };
  return getData();
};

export const getAnalyticsReportByName = (report) => {
  const { siteId, name } = report;
  const getData = async () => {
    let data;
    try {
      data = await axios({
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        method: 'post',
        url: 'https://analytics.adactive.asia/analytics/getAllByName',
        data: {
          siteId: siteId,
          title: 'screensavers',
          name: name,
        },
      });
    } catch (e) {
      console.error(e);
    }
    return data;
  };
  return getData();
};

export const queryReport = (props) => {
  const { viewID, startDate, endDate, metrics, dimensions, orderBy, filter } =
    props;
  const requestDimensions = (dimensions) => {
    let result = [];
    dimensions.forEach((item) => {
      result.push({
        name: item,
      });
    });
    return result;
  };
  return window.gapi.client.request({
    path: '/v4/reports:batchGet',
    root: 'https://analyticsreporting.googleapis.com/',
    method: 'POST',
    body: {
      reportRequests: [
        {
          viewId: viewID,
          filtersExpression: filter,
          dateRanges: [
            {
              startDate: format(new Date(startDate), 'yyyy-MM-dd'),
              endDate: format(new Date(endDate), 'yyyy-MM-dd'),
            },
          ],
          metrics: [
            {
              expression: metrics,
            },
          ],
          dimensions: requestDimensions(dimensions),
          orderBys: orderBy
            ? [
                {
                  fieldName: orderBy.fieldName,
                  sortOrder: orderBy.order,
                },
              ]
            : [],
        },
      ],
    },
  });
};

export const deleteUserApiData = (uid) => {
  const getUidData = async () => {
    let data;
    try {
      data = await axios({
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        method: 'post',
        url: 'https://analytics.adactive.asia/user/delete',
        data: {
          uid: uid,
        },
      });
    } catch (e) {
      console.error(e);
    }
    return data;
  };
  return getUidData();
};
