/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { ExportIcon } from 'assets/icon/dynamic';
import {
  fetchInformationCategories,
  modifyInformationCategory,
  createInformationCategory,
  setInformationCategory,
} from 'state/actions/informationCategories';
import { setInformation } from 'state/actions/information';
import _ from 'underscore';
import './InformationForm.css';
import { TrashIcon } from 'assets/icon/dynamic';
import CategoryDropdown from 'components/CategoryDropdown';
import ModalInformationCategoryForm from 'components/ModalInformationCategoryForm';
import { createFloor, modifyFloor, setFloor } from 'state/actions/floor';
import ModalFloorForm from 'components/ModalFloorForm';

const InformationForm = ({
  //   poi,
  action,
  history,
  id,
  error,
}) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const {
    loading,
    userData,
    categoriesList,
    information,
    category,
    floorList,
    floor,
  } = useSelector(
    (state) => ({
      loading: state.information.loading,
      userData: state.auth.userData,
      categoriesList: state.informationCategories.list,
      information: state.information.information,
      floorList: state.floor.list,
      floor: state.floor.floor,
      category: state.informationCategories.category,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();
  const [tempSelectedCategory, setTempSelectedCategory] = useState();
  const [modalCategory, setModalCategory] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);

  const [tempSelectedFloor, setTempSelectedFloor] = useState();
  const [modalFloor, setModalFloor] = useState(false);
  const [floorOpen, setFloorOpen] = useState(false);

  useEffect(() => {
    if (information) {
      setTempSelectedCategory(information.categoryId);
    }
  }, [information]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // fetch floor and poi categories
    // dispatch(fetchFloor({ siteId }));
    dispatch(fetchInformationCategories({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // const onChangeHandler = useChangeHandler(setPoi);

  const onFileChangedHandler = (_, fileList) => {
    const files = [...information.files, ...fileList];
    dispatch(
      setInformation({
        ...information,
        files,
      })
    );
    return false;
  };
  const onQRFileChangedHandler = (qrFile) => {
    dispatch(
      setInformation({
        ...information,
        qrFile,
      })
    );
    return false;
  };
  const onThumbnailFileChangedHandler = (thumbFile) => {
    dispatch(
      setInformation({
        ...information,
        thumbFile,
      })
    );
    return false;
  };

  const handleDeleteQRImage = () => {
    console.log('delete image');
    dispatch(
      setInformation({
        ...information,
        qrCode: null,
        qrFile: null,
      })
    );
  };
  const handleDeleteThumbImage = () => {
    setInformation({
      ...information,
      thumbFile: null,
      thumbnail: null,
    });
  };

  const qrPreviewUrl = information.qrFile
    ? information.qrFile && URL.createObjectURL(information.qrFile)
    : information.qrCode && `${imgUrl}/information/${information.qrCode}`;

  const thumbPreviewUrl = information.thumbFile
    ? information.thumbFile && URL.createObjectURL(information.thumbFile)
    : information.thumbnail && `${imgUrl}/information/${information.thumbnail}`;

  const handleRemoveFile = (index) => {
    const findFiles = information.files[index];
    const deletedFiles = [];

    if (findFiles.logo) {
      deletedFiles.push(findFiles);
    }

    const files = [...information.files];
    files.splice(index, 1);

    dispatch(
      setInformation({
        ...information,
        files,
        deletedFiles,
      })
    );
  };

  const onSubmitCategory = () => {
    const action = category._id
      ? modifyInformationCategory
      : createInformationCategory;

    dispatch(
      action({ uid, ...category, siteId, id: category._id }, () =>
        setModalCategory(false)
      )
    );
  };

  const handleChange = (name, value) => {
    dispatch(
      setInformation({
        ...information,
        [name]: value,
      })
    );
  };

  const onSubmitFloor = () => {
    const action = floor._id ? modifyFloor : createFloor;

    dispatch(
      action({ uid, ...floor, siteId, id: floor._id }, () =>
        setModalFloor(false)
      )
    );
  };

  const handleSetFloor = (data) => {
    dispatch(setFloor(data));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (
      (!regexInput(information.name) && information.name) ||
      (!regexInput(information.location) && information.location) ||
      (!regexInput(information.eventTime) && information.eventTime) ||
      (!regexDescription(information.ticketing) && information.ticketing) ||
      (!regexDescription(information.description) && information.description) ||
      (!information.thumbnail && !information.thumbFile) ||
      information.files.length < 1
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(information.name) &&
        information.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];
      newErrors.errors.location = !regexInput(information.location) &&
        information.location && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];
      newErrors.errors.eventTime = !regexInput(information.eventTime) &&
        information.eventTime && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];
      newErrors.errors.ticketing = !regexDescription(information.ticketing) &&
        information.ticketing && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(
        information.description
      ) &&
        information.description && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.thumbnail = !information.thumbnail &&
        !information.thumbFile && [{ msg: 'This field is required' }];

      newErrors.errors.files = information.files.length < 1 && [
        { msg: 'This field require at least 1 media' },
      ];

      setErrors(newErrors);
    } else {
      dispatch(
        action(
          {
            uid,
            ...information,
            siteId,
            id,
          },
          () => history.push(`/${siteId}/information/${siteName}`)
        )
      );
    }
  };

  const onSelectCategoryHandler = () => {
    setPlaylistOpen(false);
    dispatch(
      setInformation({
        ...information,
        categoryId: tempSelectedCategory,
        categoryName: categoriesList.find(
          (cat) => cat._id === tempSelectedCategory
        )?.name,
        // categoryName,
      })
    );
  };

  const onSelectFloorHandler = () => {
    // const floorId = value.value;
    // const floorName = value.label;
    dispatch(
      setInformation({
        ...information,
        floorId: tempSelectedFloor,
        floorName: floorList.find((floor) => floor._id === tempSelectedFloor)
          ?.name,
        // categoryName,
      })
    );
    setFloorOpen(false);
  };

  const handleNew = () => {
    dispatch(
      setInformationCategory(
        {
          _id: '',
          name: '',
          description: '',
        },
        () => setModalCategory(true)
      )
    );
  };
  const handleSetCategory = (data) => {
    dispatch(setInformationCategory(data));
  };

  const getCategory = (categoryId) => {
    const findCategory = categoriesList.find((cat) => cat._id === categoryId);
    return findCategory?.name;
  };

  const handleNewFloor = () => {
    dispatch(
      setFloor(
        {
          _id: '',
          name: '',
          description: '',
        },
        () => setModalFloor(true)
      )
    );
  };

  const getFloor = (floorId) => {
    const findFloor = floorList.find((floor) => floor._id === floorId);
    return findFloor?.name;
  };

  return (
    <div className="poi-form">
      <ModalInformationCategoryForm
        visible={modalCategory}
        onCancel={() => {
          setModalCategory(false);
        }}
        onCreate={onSubmitCategory}
        category={category}
        setCategory={handleSetCategory}
        isEditing={category._id ? true : false}
      />
      <ModalFloorForm
        visible={modalFloor}
        onCancel={() => {
          setModalFloor(false);
        }}
        onCreate={onSubmitFloor}
        floor={floor}
        setFloor={handleSetFloor}
        isEditing={floor._id ? true : false}
      />
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Exhibition Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={information.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Category <span className="is-required">*</span>
            </label>
            <div
              className="playlist-wrapper"
              onClick={() => setPlaylistOpen(!playlistOpen)}
            >
              <div className="playlist-selection">
                <span>
                  {information.categoryId === 'all'
                    ? 'Show All'
                    : getCategory(information.categoryId)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={categoriesList}
                setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
                handleSetPlaylist={onSelectCategoryHandler}
                tempSelectedCategory={tempSelectedCategory}
                handleNew={handleNew}
                modalOpen={playlistOpen}
              />
            </div>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.categoryId[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">
              Floor
            </label>
            <div
              className="playlist-wrapper"
              onClick={() => setFloorOpen(!floorOpen)}
            >
              <div className="playlist-selection">
                <span>
                  {information.floorId === 'all'
                    ? 'Show All'
                    : getFloor(information.floorId)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  playlistOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={floorList}
                setTempSelectedCategory={(id) => setTempSelectedFloor(id)}
                handleSetPlaylist={onSelectFloorHandler}
                tempSelectedCategory={tempSelectedFloor}
                handleNew={handleNewFloor}
                modalOpen={floorOpen}
                title={'Floor'}
              />
            </div>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.floorId[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Location</label>
            <Input
              placeholder="Exhibition Location"
              className={`${
                errors && errors.errors && errors.errors.location
                  ? 'has-error'
                  : ''
              }`}
              name="location"
              value={information.location}
              onChange={(e) => handleChange('location', e.target.value)}
            />
            {errors && errors.errors && errors.errors.location && (
              <span className="error-message">
                {errors.errors.location[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Event Time</label>
            <Input.TextArea
              rows={2}
              placeholder="Exhibition Event Time"
              className={`${
                errors && errors.errors && errors.errors.eventTime
                  ? 'has-error'
                  : ''
              }`}
              name="eventTime"
              value={information.eventTime}
              onChange={(e) => handleChange('eventTime', e.target.value)}
            />
            {errors && errors.errors && errors.errors.eventTime && (
              <span className="error-message">
                {errors.errors.eventTime[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Ticketing Exhibition</label>
            <Input.TextArea
              rows={2}
              placeholder="Exhibition Ticketing Exhibition"
              className={`${
                errors && errors.errors && errors.errors.eventTime
                  ? 'has-error'
                  : ''
              }`}
              name="ticketing"
              value={information.ticketing}
              onChange={(e) => handleChange('ticketing', e.target.value)}
            />
            {errors && errors.errors && errors.errors.ticketing && (
              <span className="error-message">
                {errors.errors.ticketing[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">
              Description <span className="is-required">*</span>
            </label>
            <Input.TextArea
              rows={5}
              placeholder="Exhibition Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="description"
              value={information.description}
              onChange={(e) => handleChange('description', e.target.value)}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Thumbnail</label>
            {thumbPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeleteThumbImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={thumbPreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onThumbnailFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 50MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors &&
              errors.errors &&
              errors.errors.thumbnail &&
              errors.errors.thumbnail.map((file, idx) => (
                <div className="error-message" key={idx}>
                  {file.msg}
                </div>
              ))}
          </div>
          <div className="form">
            <label className="form-label">QR Code</label>
            {qrPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeleteQRImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={qrPreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onQRFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 50MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">Upload Content</label>

            <Upload
              accept="image/*,video/mp4"
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
              multiple
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`upload-drag-and-drop ${isDragged ? 'active' : ''}`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: jpg, png (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
            {errors &&
              errors.errors &&
              errors.errors.files &&
              errors.errors.files.map((file, idx) => (
                <div className="error-message" key={idx}>
                  {file.msg}
                </div>
              ))}
            <div className="upload-chunk-wrapper">
              {information.files.length > 0 &&
                _.chunk(information.files, 3).map((chunk, index) => {
                  return (
                    <div className="upload-chunk" key={index}>
                      {chunk.map((file, index2) => {
                        console.log(file, 'file ');
                        return (
                          <div className="upload-img-wrapper" key={index2}>
                            <button
                              type="button"
                              className="upload-remove-button"
                              onClick={() => handleRemoveFile(index2)}
                            >
                              <TrashIcon color="#CB3A31" size={18} />
                            </button>
                            {file.fileType === 'video/mp4' ||
                            file.type === 'video/mp4' ? (
                              <video
                                controls="controls"
                                preload="metadata"
                                key={file.logo}
                              >
                                <source
                                  src={`${imgUrl}/information/${file.logo}`}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <img
                                alt={file.logo}
                                src={
                                  file.logo
                                    ? `${imgUrl}/information/${file.logo}`
                                    : URL.createObjectURL(file)
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default InformationForm;
