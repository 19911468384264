import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import {url} from 'utils/url';
import { log, errorMsg, refreshToken  } from 'utils';

export const FEEDBACK_FETCH_DATA_INIT = createAction(
  'FEEDBACK_FETCH_DATA_INIT'
);
export const FEEDBACK_FETCH_DATA_SUCCESS = createAction(
  'FEEDBACK_FETCH_DATA_SUCCESS'
);
export const FEEDBACK_FETCH_DATA_FAIL = createAction(
  'FEEDBACK_FETCH_DATA_FAIL'
);

export const FEEDBACK_DELETE_FEEDBACK_INIT = createAction(
  'FEEDBACK_DELETE_FEEDBACK_INIT'
);
export const FEEDBACK_DELETE_FEEDBACK_SUCCESS = createAction(
  'FEEDBACK_DELETE_FEEDBACK_SUCCESS'
);
export const FEEDBACK_DELETE_FEEDBACK_FAIL = createAction(
  'FEEDBACK_DELETE_FEEDBACK_FAIL'
);

export const FEEDBACK_CLEAR_DATA = createAction('FEEDBACK_CLEAR_DATA');

export const FEEDBACK_CREATE_FEEDBACK_INIT = createAction(
  'FEEDBACK_CREATE_FEEDBACK_INIT'
);
export const FEEDBACK_CREATE_FEEDBACK_SUCCESS = createAction(
  'FEEDBACK_CREATE_FEEDBACK_SUCCESS'
);
export const FEEDBACK_CREATE_FEEDBACK_FAIL = createAction(
  'FEEDBACK_CREATE_FEEDBACK_FAIL'
);

export const FEEDBACK_MODIFY_FEEDBACK_INIT = createAction(
  'FEEDBACK_MODIFY_FEEDBACK_INIT'
);
export const FEEDBACK_MODIFY_FEEDBACK_SUCCESS = createAction(
  'FEEDBACK_MODIFY_FEEDBACK_SUCCESS'
);
export const FEEDBACK_MODIFY_FEEDBACK_FAIL = createAction(
  'FEEDBACK_MODIFY_FEEDBACK_FAIL'
);

export const FEEDBACK_CLEAN_UP = createAction('FEEDBACK_CLEAN_UP');

export const FEEDBACK_CLEAR_DATA_LOGOUT = createAction(
  'FEEDBACK_CLEAR_DATA_LOGOUT'
);

axios.defaults.withCredentials = true;

export const fetchFeedback = ({ feedbackId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(FEEDBACK_FETCH_DATA_INIT());
    if (feedbackId) {
      let feedbackData;
      try {
        const token = await refreshToken();
        feedbackData = await axios.get(`${url}/feedback/get`, {
          params: {
            id: feedbackId,
            siteId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(FEEDBACK_FETCH_DATA_FAIL({ error }));
      }

      const feedback = { ...feedbackData.data.data, id: feedbackId };
      return dispatch(
        FEEDBACK_FETCH_DATA_SUCCESS({
          list: getState().feedback.list,
          feedback,
        })
      );
    }

    if (siteId) {
      let feedback;
      try {
        const token = await refreshToken();
        feedback = await axios.get(`${url}/feedback/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(FEEDBACK_FETCH_DATA_FAIL({ error }));
      }
      return dispatch(
        FEEDBACK_FETCH_DATA_SUCCESS({
          list: feedback.data.data,
          feedback: getState().feedback.feedback,
        })
      );
    }
  };
};

export const deleteFeedback = ({id, siteId}) => {
  return async (dispatch, getState) => {
    dispatch(FEEDBACK_DELETE_FEEDBACK_INIT());

    try {
      const token = await refreshToken();
      await axios.delete(`${url}/feedback/delete`, {
        params: {
          id,
          siteId
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      log(errorMessage);
      return dispatch(
        FEEDBACK_DELETE_FEEDBACK_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The feedback was deleted.');
    return dispatch(FEEDBACK_DELETE_FEEDBACK_SUCCESS({ id }));
  };
};

export const clearFeedbackData = () => {
  return (dispatch) => {
    dispatch(FEEDBACK_CLEAR_DATA());
  };
};

export const clearFeedbackDataLogout = () => {
  return (dispatch) => {
    dispatch(FEEDBACK_CLEAR_DATA_LOGOUT());
  };
};

export const createFeedback = ({ question, start, end, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(FEEDBACK_CREATE_FEEDBACK_INIT());

    let createFeedback;
    try {
      const token = await refreshToken();
      createFeedback = await axios.post(
        `${url}/feedback/create`,
        {
          question,
          start,
          end,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        FEEDBACK_CREATE_FEEDBACK_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Feedback created successfully');
    return dispatch(
      FEEDBACK_CREATE_FEEDBACK_SUCCESS({ feedback: createFeedback.data.data })
    );
  };
};

export const modifyFeedback = ({ id, question, start, end, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(FEEDBACK_MODIFY_FEEDBACK_INIT());
    let updateFeedback;
    try {
      const token = await refreshToken();
      updateFeedback = await axios.post(
        `${url}/feedback/edit`,
        {
          id,
          question,
          start,
          end,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        FEEDBACK_MODIFY_FEEDBACK_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Feedback updated successfully');

    return dispatch(
      FEEDBACK_MODIFY_FEEDBACK_SUCCESS({
        feedback: updateFeedback.data.data,
        id,
      })
    );
  };
};

export const feedbackCleanUp = () => (dispatch) =>
  dispatch(FEEDBACK_CLEAN_UP());
