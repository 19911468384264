import { createAction } from 'redux-act';
export const ACTIVE_MENU = createAction('ACTIVE_MENU');
export const ACTIVE_TAB_MEDIA = createAction('ACTIVE_TAB_MEDIA');
export const ACTIVE_TAB_TENANT = createAction('ACTIVE_TAB_TENANT');

export const setBreadcrumb = (list) => {
  return async (dispatch, getState) => {
    return dispatch(ACTIVE_MENU({ list }));
  };
};

export const setTabMedia = (media) => {
  return async (dispatch, getState) => {
    return dispatch(ACTIVE_TAB_MEDIA({ media }));
  };
};

export const setTabTenant = (tenant) => {
  return async (dispatch, getState) => {
    return dispatch(ACTIVE_TAB_TENANT({ tenant }));
  };
};
