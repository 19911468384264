import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { url } from 'utils/url';
import { log, refreshToken } from 'utils';
import axios from 'axios';
import { getCsrf } from 'components/Report/utils';

export const INFORMATION_FETCH_DATA_INIT = createAction(
  'INFORMATION_FETCH_DATA_INIT'
);
export const INFORMATION_FETCH_DATA_SUCCESS = createAction(
  'INFORMATION_FETCH_DATA_SUCCESS'
);
export const INFORMATION_FETCH_DATA_FAIL = createAction(
  'INFORMATION_FETCH_DATA_FAIL'
);

export const INFORMATION_DELETE_INIT = createAction('INFORMATION_DELETE_INIT');
export const INFORMATION_DELETE_SUCCESS = createAction(
  'INFORMATION_DELETE_SUCCESS'
);
export const INFORMATION_DELETE_FAIL = createAction('INFORMATION_DELETE_FAIL');

export const INFORMATION_CLEAR_DATA = createAction('INFORMATION_CLEAR_DATA');

export const INFORMATION_CREATE_INIT = createAction('INFORMATION_CREATE_INIT');
export const INFORMATION_CREATE_SUCCESS = createAction(
  'INFORMATION_CREATE_SUCCESS'
);
export const INFORMATION_CREATE_FAIL = createAction('INFORMATION_CREATE_FAIL');

export const INFORMATION_MODIFY_INIT = createAction('INFORMATION_MODIFY_INIT');
export const INFORMATION_MODIFY_SUCCESS = createAction(
  'INFORMATION_MODIFY_SUCCESS'
);
export const INFORMATION_MODIFY_FAIL = createAction('INFORMATION_MODIFY_FAIL');

export const INFORMATION_CLEAN_UP = createAction('INFORMATION_CLEAN_UP');

export const INFORMATION_CLEAR_DATA_LOGOUT = createAction(
  'INFORMATION_CLEAR_DATA_LOGOUT'
);

export const SET_INFORMATION = createAction('SET_INFORMATION');

axios.defaults.withCredentials = true;

export const fetchInformation = ({ informationId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_FETCH_DATA_INIT());
    if (informationId) {
      let informationData;
      try {
        const token = await refreshToken();
        informationData = await axios.get(`${url}/information/get`, {
          params: {
            id: informationId,
            siteId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(INFORMATION_FETCH_DATA_FAIL({ error }));
      }
      const information = { ...informationData.data.data, id: informationId };
      return dispatch(
        INFORMATION_FETCH_DATA_SUCCESS({
          list: getState().information.list,
          information,
        })
      );
    }

    if (siteId) {
      let information;
      try {
        const token = await refreshToken();
        information = await axios.get(`${url}/information/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        log(information, '<<<< information');
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(INFORMATION_FETCH_DATA_FAIL({ error }));
      }
      return dispatch(
        INFORMATION_FETCH_DATA_SUCCESS({
          list: information.data.data,
          information: getState().information.information,
        })
      );
    }
  };
};

export const deleteInformation = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_DELETE_INIT());

    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      await axios.delete(`${url}/information/delete`, {
        params: {
          id,
          siteId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      log(errorMessage);
      return dispatch(
        INFORMATION_DELETE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    cb();
    toastr.success('', 'The information was deleted.');
    return dispatch(INFORMATION_DELETE_SUCCESS({ id }));
  };
};

export const clearInformationData = () => {
  return (dispatch) => {
    dispatch(INFORMATION_CLEAR_DATA());
  };
};

export const clearInformationDataLogout = () => {
  return (dispatch) => {
    dispatch(INFORMATION_CLEAR_DATA_LOGOUT());
  };
};

export const createInformation = (
  {
    name,
    description,
    siteId,
    categoryId,
    categoryName,
    floorId,
    floorName,
    location,
    eventTime,
    ticketing,
    qrFile,
    thumbFile,
    files,
  },
  cb
) => {
  return async (dispatch, getState) => {
    let createInformation;
    let fd = new FormData();

    fd.set('name', name);
    fd.set('description', description);
    fd.set('siteId', siteId);
    fd.set('categoryId', categoryId);
    fd.set('categoryName', categoryName);
    location && fd.set('location', location);
    eventTime && fd.set('eventTime', eventTime);
    ticketing && fd.set('ticketing', ticketing);
    floorId && fd.set('floorId', floorId);
    floorName && fd.set('floorName', floorName);

    qrFile && fd.append('file', qrFile, `qrcode-${qrFile.name}`);
    thumbFile && fd.append('file', thumbFile, `thumbnail-${thumbFile.name}`);
    files &&
      files.forEach((file) => {
        fd.append('file', file, `detail-${file.name}`);
      });
    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      createInformation = await axios.post(`${url}/information/create`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        INFORMATION_CREATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Information created successfully');
    log(createInformation, '<<<<< create information');
    cb();
    return dispatch(
      INFORMATION_CREATE_SUCCESS({
        information: createInformation.data.data,
      })
    );
  };
};

export const modifyInformation = (
  {
    id,
    name,
    description,
    siteId,
    categoryId,
    categoryName,
    floorId,
    floorName,
    location,
    eventTime,
    ticketing,
    files,
    deletedFiles,
    qrCode,
    thumbnail,
    qrFile,
    thumbFile,
  },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_MODIFY_INIT());
    let updateInformation;

    let fd = new FormData();
    fd.set('id', id);
    fd.set('name', name);
    fd.set('description', description);
    fd.set('siteId', siteId);
    fd.set('categoryId', categoryId);
    fd.set('categoryName', categoryName);
    if (floorId && floorName) {
      fd.set('floorId', floorId);
      fd.set('floorName', floorName);
    }
    location && fd.set('location', location);
    eventTime && fd.set('eventTime', eventTime);
    ticketing && fd.set('ticketing', ticketing);

    qrCode && fd.set('qrCode', qrCode);
    thumbnail && fd.set('thumbnail', thumbnail);

    qrFile && fd.append('file', qrFile, `qrcode-${qrFile.name}`);
    thumbFile && fd.append('file', thumbFile, `thumbnail-${thumbFile.name}`);
    files &&
      files
        .filter((file) => !file.logo)
        .forEach((file) => {
          fd.append('file', file, `detail-${file.name}`);
        });

    const deleted = deletedFiles.map((file) => file.logo);

    fd.set('files', JSON.stringify(deleted));
    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      updateInformation = await axios.put(`${url}/information/edit`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        INFORMATION_MODIFY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Information updated successfully');
    cb();
    return dispatch(
      INFORMATION_MODIFY_SUCCESS({
        information: updateInformation.data.data,
        id,
      })
    );
  };
};

export const informationCleanUp = () => (dispatch) =>
  dispatch(INFORMATION_CLEAN_UP());

// added by me
export const setInformation = (data) => {
  return (dispatch) => {
    dispatch(SET_INFORMATION({ data }));
  };
};
