import { createReducer } from 'redux-act';

import {
  POIS_FETCH_DATA_INIT,
  POIS_FETCH_DATA_SUCCESS,
  POIS_FETCH_DATA_FAIL,
  POIS_DELETE_POI_INIT,
  POIS_DELETE_POI_SUCCESS,
  POIS_DELETE_POI_FAIL,
  POIS_CLEAR_DATA,
  POIS_CREATE_POI_INIT,
  POIS_CREATE_POI_SUCCESS,
  POIS_CREATE_POI_FAIL,
  POIS_MODIFY_POI_INIT,
  POIS_MODIFY_POI_SUCCESS,
  POIS_MODIFY_POI_FAIL,
  POIS_CLEAN_UP,
  POIS_CLEAR_DATA_LOGOUT,
  POIS_EXPORT_POI_INIT,
  POIS_EXPORT_POI_SUCCESS,
  POIS_EXPORT_POI_FAIL,
} from 'state/actions/pois';

const initialState = {
  list: [],
  poi: {
    name: '',
    phone: '',
    priority: '',
    description: '',
    logo: null,
    opentime: '',
    url: '',
    category: '',
    siteId: '',
    screenId: '',
    tag: '',
    location: '',
    floor: '',
    isRemoved: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
    start: null,
    end: null,
    files: [],
    deletedFiles: [],
  },
  loading: false,
  downloading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
};

export const poisReducer = createReducer(
  {
    [POIS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [POIS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      poi: payload.poi || initialState.poi,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [POIS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POIS_DELETE_POI_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [POIS_DELETE_POI_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [POIS_DELETE_POI_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POIS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [POIS_CREATE_POI_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [POIS_CREATE_POI_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.poi),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [POIS_CREATE_POI_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POIS_MODIFY_POI_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [POIS_MODIFY_POI_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.poi.name,
                location: payload.poi.location,
                id: payload.id,
                logoUrl: payload.poi.logoUrl,
                createdAt: payload.poi.createdAt,
                email: elem.email,
                phone: payload.poi.phone,
                priority: payload.poi.priority,
                description: payload.poi.description,
                logo: null,
                opentime: payload.poi.opentime,
                url: payload.poi.url,
                category: payload.poi.category,
                siteId: payload.poi.site,
                screenId: payload.poi.screenId,
                tag: payload.poi.tag,
                floor: payload.poi.floor,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.poi.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [POIS_MODIFY_POI_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [POIS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [POIS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [POIS_EXPORT_POI_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [POIS_EXPORT_POI_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [POIS_EXPORT_POI_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);
