import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { url } from 'utils/url';
import { log, refreshToken } from 'utils';
import axios from 'axios';
import { getCsrf } from 'components/Report/utils';

export const PROGRAMME_FETCH_DATA_INIT = createAction(
  'PROGRAMME_FETCH_DATA_INIT'
);
export const PROGRAMME_FETCH_DATA_SUCCESS = createAction(
  'PROGRAMME_FETCH_DATA_SUCCESS'
);
export const PROGRAMME_FETCH_DATA_FAIL = createAction(
  'PROGRAMME_FETCH_DATA_FAIL'
);

export const PROGRAMME_DELETE_INIT = createAction('PROGRAMME_DELETE_INIT');
export const PROGRAMME_DELETE_SUCCESS = createAction(
  'PROGRAMME_DELETE_SUCCESS'
);
export const PROGRAMME_DELETE_FAIL = createAction('PROGRAMME_DELETE_FAIL');

export const PROGRAMME_CLEAR_DATA = createAction('PROGRAMME_CLEAR_DATA');

export const PROGRAMME_CREATE_INIT = createAction('PROGRAMME_CREATE_INIT');
export const PROGRAMME_CREATE_SUCCESS = createAction(
  'PROGRAMME_CREATE_SUCCESS'
);
export const PROGRAMME_CREATE_FAIL = createAction('PROGRAMME_CREATE_FAIL');

export const PROGRAMME_MODIFY_INIT = createAction('PROGRAMME_MODIFY_INIT');
export const PROGRAMME_MODIFY_SUCCESS = createAction(
  'PROGRAMME_MODIFY_SUCCESS'
);
export const PROGRAMME_MODIFY_FAIL = createAction('PROGRAMME_MODIFY_FAIL');

export const PROGRAMME_CLEAN_UP = createAction('PROGRAMME_CLEAN_UP');

export const PROGRAMME_CLEAR_DATA_LOGOUT = createAction(
  'PROGRAMME_CLEAR_DATA_LOGOUT'
);

export const SET_PROGRAMME = createAction('SET_PROGRAMME');

axios.defaults.withCredentials = true;

export const fetchProgramme = ({ programmeId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(PROGRAMME_FETCH_DATA_INIT());
    if (programmeId) {
      let programmeData;
      try {
        const token = await refreshToken();
        programmeData = await axios.get(`${url}/programme/get`, {
          params: {
            id: programmeId,
            siteId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(PROGRAMME_FETCH_DATA_FAIL({ error }));
      }
      const programme = { ...programmeData.data.data, id: programmeId };
      return dispatch(
        PROGRAMME_FETCH_DATA_SUCCESS({
          list: getState().programme.list,
          programme,
        })
      );
    }

    if (siteId) {
      let programme;
      try {
        const token = await refreshToken();
        programme = await axios.get(`${url}/programme/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        log(programme, '<<<< programme');
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(PROGRAMME_FETCH_DATA_FAIL({ error }));
      }
      return dispatch(
        PROGRAMME_FETCH_DATA_SUCCESS({
          list: programme.data.data,
          programme: getState().programme.programme,
        })
      );
    }
  };
};

export const deleteProgramme = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(PROGRAMME_DELETE_INIT());

    console.log(id, 'id');
    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      await axios.delete(`${url}/programme/delete`, {
        params: {
          id,
          siteId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      log(errorMessage);
      return dispatch(
        PROGRAMME_DELETE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    cb();
    toastr.success('', 'The programme was deleted.');
    return dispatch(PROGRAMME_DELETE_SUCCESS({ id }));
  };
};

export const clearProgrammeData = () => {
  return (dispatch) => {
    dispatch(PROGRAMME_CLEAR_DATA());
  };
};

export const clearProgrammeDataLogout = () => {
  return (dispatch) => {
    dispatch(PROGRAMME_CLEAR_DATA_LOGOUT());
  };
};

export const createProgramme = (
  {
    name,
    description,
    siteId,
    categoryId,
    categoryName,
    floorId,
    floorName,
    eventTime,
    location,
    ticketing,
    files,
    qrFile,
    thumbFile,
  },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(PROGRAMME_CREATE_INIT());
    let createProgramme;
    let fd = new FormData();

    fd.set('name', name);
    fd.set('description', description);
    fd.set('siteId', siteId);
    fd.set('categoryId', categoryId);
    fd.set('categoryName', categoryName);
    eventTime && fd.set('eventTime', eventTime);
    ticketing && fd.set('ticketing', ticketing);
    location && fd.set('location', location);
    if (floorId && floorName) {
      fd.set('floorId', floorId);
      fd.set('floorName', floorName);
    }

    qrFile && fd.append('file', qrFile, `qrcode-${qrFile.name}`);
    thumbFile && fd.append('file', thumbFile, `thumbnail-${thumbFile.name}`);
    files &&
      files.forEach((file) => {
        fd.append('file', file, `detail-${file.name}`);
      });

    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      createProgramme = await axios.post(`${url}/programme/create`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PROGRAMME_CREATE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Programme created successfully');
    log(createProgramme, '<<<<< create programme');
    cb();
    return dispatch(
      PROGRAMME_CREATE_SUCCESS({
        programme: createProgramme.data.data,
      })
    );
  };
};

export const modifyProgramme = (
  {
    id,
    name,
    description,
    siteId,
    categoryId,
    categoryName,
    floorId,
    floorName,
    eventTime,
    ticketing,
    location,
    files,
    deletedFiles,
    qrCode,
    thumbnail,
    qrFile,
    thumbFile,
  },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(PROGRAMME_MODIFY_INIT());
    let updateProgramme;

    let fd = new FormData();
    fd.set('id', id);
    fd.set('name', name);
    fd.set('description', description);
    fd.set('siteId', siteId);
    fd.set('categoryId', categoryId);
    fd.set('categoryName', categoryName);
    if (floorId && floorName) {
      fd.set('floorId', floorId);
      fd.set('floorName', floorName);
    }
    eventTime && fd.set('eventTime', eventTime);
    ticketing && fd.set('ticketing', ticketing);
    location && fd.set('location', location);
    qrCode && fd.set('qrCode', qrCode);
    thumbnail && fd.set('thumbnail', thumbnail);

    qrFile && fd.append('file', qrFile, `qrcode-${qrFile.name}`);
    thumbFile && fd.append('file', thumbFile, `thumbnail-${thumbFile.name}`);
    files &&
      files
        .filter((file) => !file.logo)
        .forEach((file) => {
          fd.append('file', file, `detail-${file.name}`);
        });

    const deleted = deletedFiles.map((file) => file.logo);

    fd.set('files', JSON.stringify(deleted));

    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      updateProgramme = await axios.put(`${url}/programme/edit`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PROGRAMME_MODIFY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Programme updated successfully');
    cb();
    return dispatch(
      PROGRAMME_MODIFY_SUCCESS({
        programme: updateProgramme.data.data,
        id,
      })
    );
  };
};

export const programmeCleanUp = () => (dispatch) =>
  dispatch(PROGRAMME_CLEAN_UP());

// added by me
export const setProgramme = (data) => {
  return (dispatch) => {
    dispatch(SET_PROGRAMME({ data }));
  };
};
