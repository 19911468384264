import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  RESPONDENT_FETCH_DATA_INIT,
  RESPONDENT_FETCH_DATA_SUCCESS,
  RESPONDENT_FETCH_DATA_FAIL,
} from 'state/actions/respondent';

const initialState = {
  list: [],
  respondent: {
    question: '',
    start: '',
    end: '',
    siteId: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const respondentReducer = createReducer(
  {
    [RESPONDENT_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [RESPONDENT_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      respondent: payload.respondent || initialState.respondent,
      loading: false,
      error: null,
    }),
    [RESPONDENT_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
