import {
  Checkbox,
  Input,
  message as alert,
  Select,
  Tooltip,
  // Tooltip, Upload
} from 'antd';
import { useChangeHandler, useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { regexEmail, regexInput } from 'utils/regex';
// import {url} from 'utils/url';
import './UserForm.css';
import { getRole } from 'utils/secrets';
// import './UserForm.scss';

const { Password } = Input;
const { Option } = Select;

function UserForm({
  isEditing,
  isProfile,
  user,
  setUser,
  action,
  success,
  message,
  history,
  roles,
  isScreen = false,
}) {
  // const [isDragged, setIsDragged] = useState(false);

  const [userRole, setUserRole] = useState();

  const [isEditingEmail, setEditingEmail] = useState(!isEditing);

  const [defaultOrg, setDefaultOrg] = useState({
    organizationId: '',
    organizationName: '',
  });
  const [passwords, setPasswords] = useState({
    new: '',
    confirmation: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  let inputs = {
    new: {
      modifier: null,
      message: { modifier: null, content: null },
    },
    confirmation: {
      modifier: null,
      message: { modifier: null, content: null },
    },
  };

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };
  const newPasswordsAreEqual =
    passwords.new &&
    passwords.confirmation &&
    passwords.new === passwords.confirmation;

  const passwordsMatchMessagge = useFormatMessage(
    `ChangePassword.matchPassword`
  );

  const notMatchPasswordMessage = useFormatMessage(
    `ChangePassword.notMatchPassword`
  );

  if (newPasswordsAreEqual) {
    setInputs('confirmation', {
      modifier: 'is-success',
      message: {
        modifier: 'is-success',
        content: passwordsMatchMessagge,
      },
    });
  } else if (passwords.confirmation) {
    setInputs('confirmation', {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: notMatchPasswordMessage,
      },
    });
  }

  const { loading, userData } = useSelector(
    (state) => ({
      loading: state.users.loading,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      setDefaultOrg({
        organizationId: userData.organizationId,
        organizationName: userData.organizationName,
      });
    }

    if (isEditing) {
      if (role === 'admin') {
        if (user.role === 'admin') {
          setUserRole(user.role);
        }
        if (user.role === 'user') {
          setUserRole(user.role);
        }
      }

      if (role && role === 'superadmin') {
        if (user.role === 'admin') {
          setUserRole(user.role);
        }
        if (user.role === 'user') {
          setUserRole(user.role);
        }
      }
    } else {
      if (role === 'admin') {
        setUserRole('user');
        setUser((prevState) => ({
          ...prevState,
          role: 'user',
        }));
      }
    }
  }, [userData, user.role]); // eslint-disable-line

  useEffect(() => {
    if (newPasswordsAreEqual) {
      setUser((prevState) => ({
        ...prevState,
        password: passwords.new,
      }));
    }
  }, [passwords]); // eslint-disable-line

  useEffect(() => {
    if (success) {
      alert.success(message);
      history.push('/users');
    }
  }, [success]); // eslint-disable-line

  useEffect(() => {
    if (defaultOrg.organizationId || defaultOrg.organizationName) {
      setUser((prevState) => ({
        ...prevState,
        organizationId: defaultOrg.organizationId,
        organizationName: defaultOrg.organizationName,
      }));
    }
  }, [defaultOrg.organizationId, defaultOrg.organizationName]); // eslint-disable-line

  // const imagePreviewUrl = user.file
  //   ? user.file && URL.createObjectURL(user.file)
  //   : user.logoUrl && `${imgUrl}/user/${user.logoUrl}`;

  useEffect(() => {
    if (isScreen) {
      setUserRole('user');
      setUser((prevState) => ({
        ...prevState,
        role: 'user',
      }));
    }
  }, [isScreen]); // eslint-disable-line

  const onChangeHandler = useChangeHandler(setUser);
  const onChangePasswordHandler = useChangeHandler(setPasswords);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    const lowercase = passwords.new.match(/[a-z]/g) ? true : false;
    const uppercase = passwords.new.match(/[A-Z]/g) ? true : false;
    const number = passwords.new.match(/[0-9]/g) ? true : false;
    const length = passwords.new.length < 12;
    const special = passwords.new.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g)
      ? true
      : false;

    if (isEditing) {
      if (
        (!regexInput(user.name) && user.name) ||
        (!regexEmail(user.email) && user.email)
      ) {
        const newErrors = {
          ...errors,
          errors: {
            ...errors.errors,
          },
          message: 'Something went wrong!',
        };

        newErrors.errors.name = !regexInput(user.name) &&
          user.name && [
            {
              msg: 'This field cannot contain any special characters',
            },
          ];

        newErrors.errors.email = !regexEmail(user.email) &&
          user.email && [
            {
              msg: 'This field cannot contain any special characters',
            },
          ];

        if (!userRole || !userData.role) {
          newErrors.errors.role = [
            {
              msg: 'Must select the role',
            },
          ];
        }

        setErrors(newErrors);
      } else {
        setErrors({
          errors: null,
          message: '',
        });
        dispatch(
          action(
            {
              ...user,
              createdAt: Date.now(),
              isEditing,
              isProfile,
              fromScreenPage: isScreen,
            },
            history
          )
        );
      }
    } else {
      if (
        (!regexInput(user.name) && user.name) ||
        (!regexEmail(user.email) && user.email) ||
        length ||
        !lowercase ||
        !uppercase ||
        !number ||
        !special ||
        passwords.new !== passwords.confirmation ||
        !userRole
      ) {
        const newErrors = {
          ...errors,
          errors: {
            ...errors.errors,
          },
          message: 'Something went wrong!',
        };

        newErrors.errors.name = !regexInput(user.name) &&
          user.name && [
            {
              msg: 'This field cannot contain any special characters',
            },
          ];

        newErrors.errors.email = !regexEmail(user.email) &&
          user.email && [
            {
              msg: 'This field cannot contain any special characters',
            },
          ];

        if (!userRole || !userData.role) {
          newErrors.errors.role = [
            {
              msg: 'Must select the role',
            },
          ];
        }

        if (!special) {
          newErrors.errors.password = [
            {
              msg: 'Must be contain special character',
            },
          ];
        }

        if (length) {
          newErrors.errors.password = [
            {
              msg: 'Minimum 12 Characters',
            },
          ];
        }

        if (!lowercase) {
          newErrors.errors.password = [
            {
              msg: 'Must be contain lowercase',
            },
          ];
        }

        if (!uppercase) {
          newErrors.errors.password = [
            {
              msg: 'Must be contain uppercase',
            },
          ];
        }

        if (!number) {
          newErrors.errors.password = [
            {
              msg: 'Must be contain number',
            },
          ];
        }

        if (passwords.new !== passwords.confirmation) {
          newErrors.errors.password_confirmation = [
            {
              msg: 'Password not match',
            },
          ];
        }

        setErrors(newErrors);
      } else {
        setErrors({
          errors: null,
          message: '',
        });
        dispatch(
          action(
            {
              ...user,
              createdAt: Date.now(),
              isEditing,
              isProfile,
            },
            history
          )
        );
      }
    }
  };

  const onChangeRoleHandler = (value) => {
    setUserRole(value);
    setUser((prevState) => ({
      ...prevState,
      role: value,
    }));
  };
  const onChangeRoleAccessHandler = (value) => {
    setUser((prevState) => ({
      ...prevState,
      roleId: value,
    }));
  };

  const handleReset = () => {
    setUser({
      ...user,
      email: '',
      file: null,
      role: '',
      location: '',
      name: '',
      password: '',
      organizationId: '',
      organizationName: '',
      isScreen: false,
    });
  };

  const handleEntailmentRequest = (e) => {
    e.preventDefault();
    setEditingEmail(true);
  };

  const handleChange = (name, value) => {
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="user-form">
      <form className={isEditing ? 'form-wrapper-edit' : 'form-wrapper'}>
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              onChange={onChangeHandler}
              value={user.name}
              placeholder="John Doe"
              name="name"
              type="text"
            />

            {errors &&
              errors.errors &&
              errors.errors.name &&
              errors.errors.name.map((name, idx) => (
                <div className="error-message" key={idx}>
                  {name.msg}
                </div>
              ))}
          </div>
          <div className="form">
            <label className="form-label">
              Email <span className="is-required">*</span>
            </label>
            {isEditingEmail ? (
              <Input
                onChange={onChangeHandler}
                value={user.email}
                placeholder="example@mail.com"
                name="email"
                type="email"
              />
            ) : (
              <span className="span-email">{user.email}</span>
            )}
            {role === 'superadmin' && !isEditingEmail && (
              <Tooltip title="Edit Email">
                <button
                  onClick={(e) => {
                    handleEntailmentRequest(e);
                  }}
                  data-tooltip="Edit"
                  className="btn btn--edit"
                >
                  <i className="feather-edit" />
                </button>
              </Tooltip>
            )}
            {errors &&
              errors.errors &&
              errors.errors.email &&
              errors.errors.email.map((email, idx) => (
                <div className="error-message" key={idx}>
                  {email.msg}
                </div>
              ))}
          </div>

          {!isScreen && (
            <div className="form">
              <label className="form-label">
                Role <span className="is-required">*</span>
              </label>
              <Select
                placeholder="Select Role"
                value={userRole}
                onChange={onChangeRoleHandler}
                disabled={role && role === 'admin'}
              >
                <Option value="user">Regular User</Option>
                <Option value="admin">Admin</Option>
              </Select>

              {errors &&
                errors.errors &&
                errors.errors.role &&
                errors.errors.role.map((role, idx) => (
                  <div className="error-message" key={idx}>
                    {role.msg}
                  </div>
                ))}
            </div>
          )}
          <div className="form">
            <label className="form-label">
              Access <span className="is-required">*</span>
            </label>
            <Select
              placeholder="Select Access"
              value={user.roleId}
              onChange={onChangeRoleAccessHandler}
              disabled={role && role === 'admin'}
            >
              {roles.map((role) => (
                <Option key={role._id} value={role._id}>
                  {role.name}
                </Option>
              ))}
            </Select>
            {errors &&
              errors.errors &&
              errors.errors.access &&
              errors.errors.access.map((role, idx) => (
                <div className="error-message" key={idx}>
                  {role.msg}
                </div>
              ))}
          </div>

          <div className="form">
            <Checkbox
              onChange={(e) => handleChange('isScreen', e.target.checked)}
              checked={user.isScreen}
              disabled={role !== 'superadmin'}
            >
              User is a Screen
            </Checkbox>
          </div>
        </div>
        <div>
          {!isEditing && (
            <div className="form">
              <label className="form-label">
                Password <span className="is-required">*</span>
              </label>
              <Password
                onChange={onChangePasswordHandler}
                placeholder="********"
                name="new"
                type="password"
                value={passwords.new}
              />

              {errors &&
                errors.errors &&
                errors.errors.password &&
                errors.errors.password.map((password, idx) => (
                  <div className="error-message" key={idx}>
                    {password.msg}
                  </div>
                ))}

              {inputs.new.message.content ? (
                <p className={`help is-${inputs.new.message.modifier}`}>
                  {inputs.new.message.content}
                </p>
              ) : null}
            </div>
          )}

          {!isEditing && (
            <div className="user-form-message">
              <h3 className="user-form-message-title">
                Password must contain the following:
              </h3>
              <p
                className="user-form-message-invalid"
                style={{
                  color: passwords.new.match(/[a-z]/g)
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[a-z]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                A lowercase letter
              </p>
              <p
                className="user-form-message-invalid"
                style={{
                  color: passwords.new.match(/[A-Z]/g)
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[A-Z]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                A capital (uppercase) letter
              </p>
              <p
                className="user-form-message-invalid"
                style={{
                  color: passwords.new.match(/[0-9]/g)
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[0-9]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                A number
              </p>
              <p
                className="change-password__message-invalid"
                style={{
                  color: passwords.new.match(
                    /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g
                  )
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                Special Character
              </p>
              <p
                className="user-form-message-invalid"
                style={{
                  color:
                    passwords.new.length >= 12
                      ? 'green'
                      : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.length >= 12 ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                Minimum 12 Characters
              </p>
            </div>
          )}

          {!isEditing && (
            <div className="form">
              <label className="form-label">
                Confirm Password <span className="is-required">*</span>
              </label>
              <Password
                onChange={onChangePasswordHandler}
                placeholder="********"
                name="confirmation"
                type="password"
                value={passwords.confirmation}
              />

              {errors &&
                errors.errors &&
                errors.errors.password_confirmation &&
                errors.errors.password_confirmation.map(
                  (password_confirmation, idx) => (
                    <div className="error-message" key={idx}>
                      {password_confirmation.msg}
                    </div>
                  )
                )}
            </div>
          )}
          <div className="button-wrapper">
            {loading ? (
              <button type="button" disabled className="button-primary">
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="button-primary"
                onClick={onSubmitHandler}
              >
                {isEditing ? 'Save Changes' : 'Save'}
              </button>
            )}
            <button className="button-secondary" onClick={handleReset}>
              Reset
            </button>
          </div>

          {/* <div className="form">
            <label className="form-label">Profile Picture</label>
            {user.file || user.logoUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img src={imagePreviewUrl} alt="img-preview" />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <i className="feather-upload" />
                  <p>Drag or click to upload</p>
                </div>
              </Upload>
            )}
          </div> */}
        </div>
      </form>
    </div>
  );
}

export default UserForm;
