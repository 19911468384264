/* eslint-disable jsx-a11y/label-has-associated-control */
import { useChangeHandler, useFormatMessage } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { categoriesCleanUp } from 'state/actions/mediaCategories';
import './MediaCategoryForm.scss';

const MediaCategoryForm = ({
  category,
  setCategory,
  action,
  categories,
  success,
  history,
  id,
  error,
}) => {
  const { siteId, siteName } = useParams();

  const { loading, userData } = useSelector(
    (state) => ({
      loading: state.mediaCategories.loading,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(categoriesCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setCategory);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action({ uid: uid, ...category, siteId: siteId, id }));
  };

  const handleReset = () => {
    setCategory({
      ...category,
      name: '',
      description: '',
    });
  };

  // const canSubmit = category.name && category.description;

  const goBackMessage = useFormatMessage('CategoryForm.goBack');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('CategoryForm.categoryInfo')}
              </p>
            </header>
            <div className="card-content">
              <form>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CategoryForm.name')}{' '}
                      <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="name"
                          className={`input ${
                            error && error.errors && error.errors.name
                              ? 'is-danger'
                              : ''
                          }`}
                          type="text"
                          required
                          name="name"
                          value={category.name}
                          onChange={onChangeHandler}
                        />
                      </div>
                      {error && error.errors && error.errors.name && (
                        <p className="help is-danger">
                          {error.errors.name[0].msg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CategoryForm.description')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          style={{
                            height: 100,
                          }}
                          id="description"
                          className="input"
                          type="text"
                          required
                          name="description"
                          value={category.description}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            onClick={onSubmitHandler}
                            type="button"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                            // disabled={!canSubmit}
                          >
                            <span>
                              {useFormatMessage('CategoryForm.submit')}
                            </span>
                          </button>
                        </div>
                        <div className="control">
                          <Link
                            to={{
                              pathname: `/${siteId}/information/${siteName}`,
                              id: 'media-category',
                            }}
                            className="button"
                          >
                            {goBackMessage}
                          </Link>
                        </div>
                        <button
                          type="reset"
                          className="button"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MediaCategoryForm.propTypes = {
  categoryData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    siteId: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default MediaCategoryForm;
