import React from 'react';

function BoxIcon({ color = '#000000', size=24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.3001C11.87 13.3001 11.74 13.2701 11.62 13.2001L2.78996 8.0901C2.43996 7.8801 2.30996 7.4201 2.51996 7.0601C2.72996 6.7001 3.18996 6.5801 3.54996 6.7901L12 11.6801L20.4 6.8201C20.76 6.6101 21.22 6.7401 21.43 7.0901C21.64 7.4501 21.51 7.9101 21.16 8.1201L12.39 13.2001C12.26 13.2601 12.13 13.3001 12 13.3001Z"
        fill={color}
      />
      <path
        d="M12 22.36C11.59 22.36 11.25 22.02 11.25 21.61V12.54C11.25 12.13 11.59 11.79 12 11.79C12.41 11.79 12.75 12.13 12.75 12.54V21.61C12.75 22.02 12.41 22.36 12 22.36Z"
        fill={color}
      />
      <path
        d="M12 22.75C11.12 22.75 10.25 22.56 9.56002 22.18L4.22001 19.21C2.77001 18.41 1.64001 16.48 1.64001 14.82V9.17C1.64001 7.51 2.77001 5.59 4.22001 4.78L9.56002 1.82C10.93 1.06 13.07 1.06 14.44 1.82L19.78 4.79C21.23 5.59 22.36 7.52 22.36 9.18V14.83C22.36 16.49 21.23 18.41 19.78 19.22L14.44 22.18C13.75 22.56 12.88 22.75 12 22.75ZM12 2.75C11.37 2.75 10.75 2.88 10.29 3.13L4.95001 6.1C3.99001 6.63 3.14001 8.07 3.14001 9.17V14.82C3.14001 15.92 3.99001 17.36 4.95001 17.9L10.29 20.87C11.2 21.38 12.8 21.38 13.71 20.87L19.05 17.9C20.01 17.36 20.86 15.93 20.86 14.82V9.17C20.86 8.07 20.01 6.63 19.05 6.09L13.71 3.12C13.25 2.88 12.63 2.75 12 2.75Z"
        fill={color}
      />
    </svg>
  );
}

export default BoxIcon;
