import { Tabs } from 'antd';
// import Feedbacks from 'pages/Feedbacks';
import { log } from 'utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setActiveTab } from 'state/actions/screens';
import './ScreenInfo.css';
import ScreenSettings from 'pages/ScreenSettings';
import ConfigVariable from 'pages/ConfigVariable';
import ScreenConfiguration from 'pages/ScreenConfiguration';
import FileManagement from 'pages/FileManagement';
import {
  BoxIcon,
  CodeIcon,
  DocumentTextIcon,
  SettingsIcon,
} from 'assets/icon/dynamic';

const { TabPane } = Tabs;

function ScreenInfo({ history, setCollapsed }) {
  const dispatch = useDispatch();
  const { siteId, siteName } = useParams();

  const { tab: activeTab, activeScreen} = useSelector((state) => ({
    tab: state.screens.tab,
    activeScreen: state.screens.activeScreen,
  }));

  const handleChangeTab = (activeKey) => {
    log(activeKey);
    dispatch(setActiveTab({ tab: activeKey }));
  };

  return (
    <div className="siteinfo">
      <div className="siteinfo-nav p-20">
        <div>
          {console.log(activeScreen)}
          <h3 className="title">{activeScreen.name}</h3>
          <span>{activeScreen.description}</span>
        </div>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>

      <Tabs
        activeKey={activeTab}
        onTabClick={handleChangeTab}
        className="adsign-tab"
      >
        <TabPane
          // onMouseOver={() => setHoverTab('screenSettings')}
          // onMouseLeave={() => setHoverTab('')}
          tab={
            <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
              <SettingsIcon
                color={activeTab === 'screenSettings' ? '#0851B5' : '#9E9E9E'}
                size={18}
              />
              <span>Screen Settings</span>
            </div>
          }
          key="screenSettings"
        >
          <div className="tab-content">
            <ScreenSettings />
          </div>
        </TabPane>
        <TabPane
          // onMouseOver={() => setHoverTab('configVariable')}
          // onMouseLeave={() => setHoverTab('')}
          tab={
            <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
              <CodeIcon
                color={activeTab === 'configVariable' ? '#0851B5' : '#9E9E9E'}
                size={18}
              />
              <span>Config Variable</span>
            </div>
          }
          key="configVariable"
        >
          <div className="tab-content">
            <ConfigVariable />
          </div>
        </TabPane>
        <TabPane
          // onMouseOver={() => setHoverTab('screenConfiguration')}
          // onMouseLeave={() => setHoverTab('')}
          tab={
            <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
              <BoxIcon
                color={
                  activeTab === 'screenConfiguration' ? '#0851B5' : '#9E9E9E'
                }
                size={18}
              />
              <span>Screen Configuration</span>
            </div>
          }
          key="screenConfiguration"
        >
          <div className="tab-content">
            <ScreenConfiguration />
          </div>
        </TabPane>
        <TabPane
          // onMouseOver={() => setHoverTab('fileManagement')}
          // onMouseLeave={() => setHoverTab('')}
          tab={
            <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
              <DocumentTextIcon
                color={activeTab === 'fileManagement' ? '#0851B5' : '#9E9E9E'}
                size={18}
              />
              <span>File Management</span>
            </div>
          }
          key="fileManagement"
        >
          <div className="tab-content">
            <FileManagement />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default ScreenInfo;
