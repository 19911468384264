import { Tooltip } from 'antd';
import React from 'react';
import './style.css';

const CreatedInfo = ({ user, limit = 40 }) => {
  return (
    <div className="created-by">
      <p>Last modified by:</p>
      {user && user.length > 40 ? (
        <Tooltip title={user}>
          <p>{`${user.substr(0, limit)}...`}</p>
        </Tooltip>
      ) : (
        <p>{user}</p>
      )}
    </div>
  );
};

export default CreatedInfo;
