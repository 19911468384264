import { Button, Modal, Table, Tooltip } from 'antd';
import ModalMediaCategoryForm from 'components/ModalMediaCategoryForm';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
// import Table from 'components/Table';
import {
  createMediaCategory,
  deleteMediaCategory,
  fetchMediaCategories,
  modifyMediaCategory,
} from 'state/actions/mediaCategories';
import './MediaCategories.scss';
import { regexInput } from 'utils/regex';
import { getRole } from 'utils/secrets';

const MediaCategories = () => {
  const { siteId } = useParams();

  const {
    categoriesList,
    loading,
    deleted,
    userData,
    updated,
  } = useSelector(
    (state) => ({
      categoriesList: state.mediaCategories.list,
      loading: state.mediaCategories.loading,
      deleted: state.mediaCategories.deleted,
      userData: state.auth.userData,
      updated: state.mediaCategories.updated,
    }),
    shallowEqual
  );

  if (categoriesList) {
    log(categoriesList, '<<< cat list');
  }

  const [deleteModal, setDeleteModal] = useState({
    categoryId: null,
    isOpen: false,
  });

  const defaultCategory = {
    name: '',
    description: '',
  };

  const dispatch = useDispatch();

  const [search, setSearch] = useState();
  const [visible, setVisible] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [category, setCategory] = useState(defaultCategory);
  const [error, setError] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };


  const uid = userData.id;

  useEffect(() => {
    dispatch(fetchMediaCategories({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchMediaCategories({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        categoryId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchMediaCategories({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect = !role && <Redirect to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (categoryId) => {
    setDeleteModal((prevState) => ({
      categoryId,
      isOpen: !prevState.isOpen,
    }));
  };

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ categoryId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteMediaCategory({ id: deleteModal.categoryId, siteId }));
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyMediaCategory : createMediaCategory;

    dispatch(action({ uid, ...values, siteId }));
  };

  const onNewMediaCategoryHandler = () => {
    setCategory(defaultCategory);
    setIsEditing(false);
    setVisible(true);
  };

  const columns = [
    {
      title: useFormatMessage('Categories.name'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: useFormatMessage('Categories.description'),
      dataIndex: 'description',
      sorter: {
        compare: (a, b) => a.description - b.description,
        multiple: 2,
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',

      render: (text, record) => (
        <div className="media-categories__actions">
          {role === "superadmin" ? (
            <div className="buttons is-right">
              <Tooltip title="Edit Category">
                <button
                  data-tooltip="Edit"
                  className="btn btn--edit"
                  onClick={() => {
                    setCategory(record);
                    setVisible(true);
                    setIsEditing(true);
                  }}
                >
                  <i className="feather-edit"></i>
                </button>
              </Tooltip>

              <Tooltip title="Delete Category">
                <button
                  data-tooltip="Delete"
                  type="button"
                  className="btn btn--delete"
                  onClick={() => onRemoveButtonClickHandler(record._id)}
                >
                  <i className="feather-trash-2"></i>
                </button>
              </Tooltip>
            </div>
          ) :
            (
              <div className="buttons is-right">
                <Tooltip title="Edit Category">
                  <button
                    disabled={true}
                    data-tooltip="Edit"
                    className="btn btn--edit"
                    onClick={() => { }}
                  >
                    <i className="feather-edit"></i>
                  </button>
                </Tooltip>

                <Tooltip title="Delete Category">
                  <button
                    disabled={true}
                    data-tooltip="Delete"
                    type="button"
                    className="btn btn--delete"
                    onClick={() => null}
                  >
                    <i className="feather-trash-2"></i>
                  </button>
                </Tooltip>
              </div>
            )

          }
        </div>
      ),
    },
  ];

  const data = search
    ? categoriesList.filter((el) => {
      const clonedElem = { ...el };
      delete clonedElem.id;
      delete clonedElem.role;
      delete clonedElem.logoUrl;
      return clonedElem.name.toLowerCase().includes(search.toLowerCase());
    })
    : categoriesList;

  if (data) {
    log(data, '<<< data category lists');
  }

  // const deleteMessage = useFormatMessage('Categories.delete');

  const confirmMessage = useFormatMessage('Categories.confirm');

  const permDeleteMessage = useFormatMessage('Categories.permDelete');

  // const cancelMessage = useFormatMessage('Categories.cancel');

  return (
    <section className="media-categories">
      {role && redirect}
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        >
          {permDeleteMessage}
        </Modal>
      )}

      {visible && (
        <ModalMediaCategoryForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          category={category}
          setCategory={setCategory}
          isEditing={isEditing}
        />
      )}
      <div className="media-categories__nav">
        <div className="media-categories__search-wrapper">
          <div className="media-categories__search">
            <i className="feather-search"></i>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {error && <div className="search-error">{error}</div>}
        </div>

        <Button
          type="primary"
          className="button-primary"
          onClick={onNewMediaCategoryHandler}
        >
          {useFormatMessage('Categories.newCategory')}
        </Button>
      </div>

      <div className="media-categories__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="_id" />
        )}
      </div>
    </section>
  );
};

export default MediaCategories;
