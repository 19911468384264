import PoiForm from 'components/PoiForm';
import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { createPoi, fetchPois, modifyPoi } from 'state/actions/pois';
import './Poi.css';

const Poi = ({ history }) => {
  const { id, siteId, siteName } = useParams();

  const { success, poisList, poiData, sitesList, error } = useSelector(
    (state) => ({
      success: state.pois.success,
      poisList: state.pois.list,
      poiData: state.pois.poi,
      sitesList: state.sites.list,
      error: state.pois.error,
    }),
    shallowEqual
  );

  const [poi, setPoi] = useState(poiData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const poiFetched = poisList.find((fetchedPoi) => fetchedPoi._id === id);
      if (poiFetched) {
        setPoi(poiFetched);
      } else if (poiData._id === id) {
        setPoi(poiData);
      } else {
        dispatch(fetchPois(id));
      }
    }
  }, [id, poiData]);

  const isEditing = !!id;

  const poiForm =
    !poi && id ? (
      <ClipLoader />
    ) : (
      <PoiForm
        isEditing={isEditing}
        poi={poi}
        sites={sitesList}
        setPoi={setPoi}
        action={isEditing ? modifyPoi : createPoi}
        success={success}
        id={id}
        history={history}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editPoiMessage = useFormatMessage('Poi.editPoi');

  const newPoiMessage = useFormatMessage('Poi.newPoi');

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editPoiMessage : newPoiMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{poiForm}</div>
    </div>
  );
};

export default Poi;
