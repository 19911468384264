import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg, refreshToken } from 'utils';
import { getCsrf } from 'components/Report/utils';

export const PROGRAMME_CATEGORIES_FETCH_DATA_INIT = createAction(
  'PROGRAMME_CATEGORIES_FETCH_DATA_INIT'
);
export const PROGRAMME_CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'PROGRAMME_CATEGORIES_FETCH_DATA_SUCCESS'
);
export const PROGRAMME_CATEGORIES_FETCH_DATA_FAIL = createAction(
  'PROGRAMME_CATEGORIES_FETCH_DATA_FAIL'
);

export const PROGRAMME_CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'PROGRAMME_CATEGORIES_DELETE_CATEGORY_INIT'
);
export const PROGRAMME_CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'PROGRAMME_CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const PROGRAMME_CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'PROGRAMME_CATEGORIES_DELETE_CATEGORY_FAIL'
);

export const PROGRAMME_CATEGORIES_CLEAR_DATA = createAction(
  'PROGRAMME_CATEGORIES_CLEAR_DATA'
);

export const PROGRAMME_CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'PROGRAMME_CATEGORIES_CREATE_CATEGORY_INIT'
);
export const PROGRAMME_CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'PROGRAMME_CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const PROGRAMME_CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'PROGRAMME_CATEGORIES_CREATE_CATEGORY_FAIL'
);

export const PROGRAMME_CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'PROGRAMME_CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const PROGRAMME_CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'PROGRAMME_CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const PROGRAMME_CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'PROGRAMME_CATEGORIES_MODIFY_CATEGORY_FAIL'
);

export const PROGRAMME_CATEGORIES_CLEAN_UP = createAction(
  'PROGRAMME_CATEGORIES_CLEAN_UP'
);

export const PROGRAMME_CATEGORIES_CLEAR_DATA_LOGOUT = createAction(
  'PROGRAMME_CATEGORIES_CLEAR_DATA_LOGOUT'
);

export const SET_PROGRAMME_CATEGORY = createAction('SET_PROGRAMME_CATEGORY');

axios.defaults.withCredentials = true;

export const fetchProgrammeCategories = ({ programmeCategoryId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(PROGRAMME_CATEGORIES_FETCH_DATA_INIT());
    if (programmeCategoryId) {
      let programmeCategoryData;
      try {
        const token = await refreshToken();
        programmeCategoryData = await axios.get(
          `${url}/programmeCategory/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(PROGRAMME_CATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      const programmeCategory = {
        ...programmeCategoryData,
        id: programmeCategoryId,
      };

      return dispatch(
        PROGRAMME_CATEGORIES_FETCH_DATA_SUCCESS({
          list: getState().categories.list,
          programmeCategory,
        })
      );
    }

    if (siteId) {
      let programmeCategories;
      try {
        const token = await refreshToken();
        programmeCategories = await axios.get(`${url}/programmeCategory/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        log(programmeCategories, '<<< infor cat');
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(PROGRAMME_CATEGORIES_FETCH_DATA_FAIL({ error }));
      }

      programmeCategories = programmeCategories.data.data.filter(
        (programmeCategory) => programmeCategory.siteId === siteId
      );

      return dispatch(
        PROGRAMME_CATEGORIES_FETCH_DATA_SUCCESS({
          list: programmeCategories,
          programmeCategory: getState().programmeCategories.programmeCategory,
        })
      );
    }
  };
};

export const deleteProgrammeCategory = (id, cb) => {
  return async (dispatch) => {
    dispatch(PROGRAMME_CATEGORIES_DELETE_CATEGORY_INIT());
    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      await axios.delete(`${url}/programmeCategory/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PROGRAMME_CATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }
    cb();
    toastr.success('', 'The programme category was deleted.');
    return dispatch(PROGRAMME_CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const clearProgrammeCategoriesData = () => {
  return (dispatch) => {
    dispatch(PROGRAMME_CATEGORIES_CLEAR_DATA());
  };
};

export const clearProgrammeCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(PROGRAMME_CATEGORIES_CLEAR_DATA_LOGOUT());
  };
};

export const createProgrammeCategory = ({ name, description, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(PROGRAMME_CATEGORIES_CREATE_CATEGORY_INIT());
    let programmeCategory;
    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      programmeCategory = await axios.post(
        `${url}/programmeCategory/create`,
        {
          name,
          description,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-CSRF-TOKEN': csrfToken,
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      log(error.response, '<<< error response');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        PROGRAMME_CATEGORIES_CREATE_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Programme category created successfully');
    cb();
    return dispatch(
      PROGRAMME_CATEGORIES_CREATE_CATEGORY_SUCCESS({
        programmeCategory: programmeCategory.data.data,
      })
    );
  };
};

export const modifyProgrammeCategory = (
  { id, name, description, siteId, modifiedAt, modifiedBy },
  cb
) => {
  return async (dispatch) => {
    dispatch(PROGRAMME_CATEGORIES_MODIFY_CATEGORY_INIT());

    const programmeCategoryData = {
      id,
      name,
      description,
      siteId,
      modifiedAt,
      modifiedBy,
    };

    const csrfToken = await getCsrf();
    const token = await refreshToken();
    const updateProgrammeCategoryDbTask = await axios.post(
      `${url}/programmeCategory/edit`,
      programmeCategoryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      }
    );

    try {
      await Promise.all([updateProgrammeCategoryDbTask]);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      cb();
      return dispatch(
        PROGRAMME_CATEGORIES_MODIFY_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Programme category updated successfully');
    cb();
    return dispatch(
      PROGRAMME_CATEGORIES_MODIFY_CATEGORY_SUCCESS({
        programmeCategory: updateProgrammeCategoryDbTask.data.data,
        id,
      })
    );
  };
};

export const programmeCategoriesCleanUp = () => (dispatch) =>
  dispatch(PROGRAMME_CATEGORIES_CLEAN_UP());

export const setProgrammeCategory = (data, cb) => {
  return (dispatch) => {
    dispatch(SET_PROGRAMME_CATEGORY({ data }));
    if (cb) {
      cb();
    }
  };
};
