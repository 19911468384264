import { Breadcrumb, Input, Select, Checkbox, Tooltip } from 'antd';
import { useChangeHandler } from 'hooks';
import { useParams, Link } from 'react-router-dom';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createRole, modifyRole } from 'state/actions/roles';
import './Role.css';
import { log } from 'utils';
// import './Users.scss';
const { Option } = Select;

function Role() {
  const { id } = useParams();
  const {
    // isAdmin,
    loading,
    userData,
    sites,
    organizations,
    success,
    roles,
  } = useSelector(
    (state) => ({
      error: state.roles.error,
      success: state.roles.success,
      sites: state.sites.list,
      organizations: state.organizations.list,
      loading: state.roles.loading,
      roles: state.roles.list,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [newRole, setNewRole] = useState({});
  const [access, setAccess] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [siteList, setSiteList] = useState([]);
  const [count, setCount] = useState(0);
  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

 const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };


  const data = {
    api: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    poi: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    media: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    information: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    feedback: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    map: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    screen : false
  };

  useEffect(() => {
    if (id) {
      const userRole = roles.find((role) => role._id === id);
      setNewRole(userRole);
      setAccess(userRole.access);
      const userSiteId = [];
      userRole.access.map((acc) => userSiteId.push(acc.siteId));
      setSelectedSites(userSiteId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newSiteList = sites.filter(
      (site) => site.organizationId === selectedOrganization
    );
    setSiteList(newSiteList);
  }, [selectedOrganization]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectOrgHandler = (item, child) => {
    const organizationId = child.value;

    setSelectedOrganization(organizationId);
  };

  const onSelectSiteHandler = (item, child) => {
    const siteId = child.value;

    const newSelectedSites = selectedSites.includes(siteId)
      ? selectedSites
      : [...selectedSites, siteId];
    const dataAccess = {
      ...data,
      siteId: siteId,
    };
    const newAccess = access.find((acc) => acc.siteId === siteId)
      ? access
      : [...access, dataAccess];

    setSelectedSites(newSelectedSites);
    setAccess(newAccess);
  };

  const onDeleteSite = (siteId) => {
    const newAccess = access.filter((acc) => acc.siteId !== siteId);
    const newSelectedSites = selectedSites.filter((site) => site !== siteId);
    setSelectedSites(newSelectedSites);
    setAccess(newAccess);
  };

  const onChangeHandler = useChangeHandler(setNewRole);

  const onSubmitHandler = () => {
    const submitRole = {
      name: newRole.name,
      access,
    };
    if (id) {
      dispatch(modifyRole({ ...submitRole, _id: id }));
    } else {
      dispatch(createRole(submitRole));
    }
  };

  const handleReset = () => {
    setNewRole({});
    setAccess([]);
  };

  const onSubCheckboxChanged = (event, siteId, item, crud) => {
    const index = access.findIndex((acc) => acc.siteId === siteId);
    const checkedAccess = access[index];
    checkedAccess[item][crud] = event.target.checked;
    const newAccess = access;
    newAccess[index] = checkedAccess;
    setAccess(newAccess);
    setCount(count + 1);
  };

  const onCheckboxChanged = (event, siteId, item) => {
    let newAccess;
    if (event.target.checked) {
      const index = access.findIndex((acc) => acc.siteId === siteId);
      const checkedAccess = access[index];

      Object.keys(checkedAccess[item]).map(
        (i) => (checkedAccess[item][i] = true)
      );
      newAccess = access;
      newAccess[index] = checkedAccess;
    } else {
      const index = access.findIndex((acc) => acc.siteId === siteId);
      const checkedAccess = access[index];

      Object.keys(checkedAccess[item]).map(
        (i) => (checkedAccess[item][i] = false)
      );
      newAccess = access;
      newAccess[index] = checkedAccess;
    }

    setAccess([...newAccess]);
    // setCount(count + 1)
  };

  const redirect = success && <Redirect to={paths.ROLES} />;

  const keys = Object.keys(data);

  return (
    <section className="user">
      {role && redirect}
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Roles</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                Add New Roles
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">Add New Role</h1>
        </div>
        <div className="header-right-content">
          <Link to="/roles" className="button-secondary">
            <i className="feather-arrow-left" />
            Back
          </Link>
        </div>
      </div>
      <div className="role-form">
        <div className="form-wrapper">
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              onChange={onChangeHandler}
              value={newRole.name}
              placeholder="John Doe"
              name="name"
              type="text"
            />
          </div>
          <div className="form">
            <label className="form-label">
              Organization <span className="is-required">*</span>
            </label>
            <Select
              onChange={onSelectOrgHandler}
              allowClear
              defaultValue={
                role === 'admin' ? userData && userData.organizationId : null
              }
              placeholder="Select Organization"
              value={selectedOrganization && selectedOrganization}
            >
              {organizations.map((organization) => (
                <Option key={organization._id} value={organization._id}>
                  {organization.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form">
            <label className="form-label">Site</label>
            <Select
              onChange={onSelectSiteHandler}
              allowClear
              placeholder="Select Site"
            >
              {siteList.map((site) => (
                <Option key={site._id} value={site._id}>
                  {site.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="role-access">
            <div className="column">
              <div className="title-point">Access</div>
              {keys.map((d) => (
                <div className="access-group">
                  <div className="point">{d.toUpperCase()}</div>
                  <div>
                    {Object.keys(data[d]).map((crud) => (
                      <div className="sub-point">{crud}</div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {access.map((acc) => (
              <div className="column" key={acc.siteId}>
                <div className="title-point">
                  <div className="sub-point">
                    {sites.find((sites) => sites._id === acc.siteId).name}
                  </div>
                  <Tooltip title="Delete Site Access">
                    <button
                      className="btn btn--delete"
                      onClick={() => onDeleteSite(acc.siteId)}
                    >
                      <i className="feather-trash-2" />
                    </button>
                  </Tooltip>
                </div>
                {Object.keys(acc).map((obj) => {
                  return (
                    <>
                      {obj !== 'siteId' && (
                        <div className="access-group">
                          <div key={acc[obj]} className="point">
                            <Checkbox
                              checked={!Object.values(acc[obj]).includes(false)}
                              onChange={(event) =>
                                onCheckboxChanged(event, acc.siteId, obj)
                              }
                            />
                          </div>
                          {Object.keys(acc[obj]).map((crud) => (
                            <div
                              key={`${acc[obj]}.${crud}`}
                              className="sub-point"
                            >
                              <Checkbox
                                checked={acc[obj][crud]}
                                onChange={(event) =>
                                  onSubCheckboxChanged(
                                    event,
                                    acc.siteId,
                                    obj,
                                    crud
                                  )
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            ))}
          </div>
          <div>
            {loading ? (
              <button className="button-primary" disabled>
                Loading...
              </button>
            ) : (
              <button className="button-primary" onClick={onSubmitHandler}>
                Save
              </button>
            )}
            <button
              className="button-secondary button-reset"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Role;