import { Tooltip } from 'antd';
import React from 'react';
import './style.scss';

const TooltipMenu = ({ title,
  icon,
  clickAction, }) => {
  return (
    <Tooltip title={title}>
      <button
        onClick={clickAction}
      >
        {icon}
      </button>
    </Tooltip>
  );
};

export default TooltipMenu;
