import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, refreshToken } from 'utils';
import { logout } from './auth';
import { ORGANIZATIONS_CLEAR_DATA } from './organizations';
import { SITES_CLEAR_DATA } from './sites';
import { errorMsg } from 'utils';
import _ from 'underscore';

export const ROLES_FETCH_DATA_INIT = createAction('ROLES_FETCH_DATA_INIT');
export const ROLES_FETCH_DATA_SUCCESS = createAction(
  'ROLES_FETCH_DATA_SUCCESS'
);
export const ROLES_FETCH_DATA_FAIL = createAction('ROLES_FETCH_DATA_FAIL');
export const ROLES_GET_DATA_INIT = createAction('ROLES_GET_DATA_INIT');
export const ROLES_GET_DATA_SUCCESS = createAction('ROLES_GET_DATA_SUCCESS');
export const ROLES_GET_DATA_FAIL = createAction('ROLES_GET_DATA_FAIL');

export const ROLES_DELETE_ROLE_INIT = createAction('ROLES_DELETE_ROLE_INIT');
export const ROLES_DELETE_ROLE_SUCCESS = createAction(
  'ROLES_DELETE_ROLE_SUCCESS'
);
export const ROLES_DELETE_ROLE_FAIL = createAction('ROLES_DELETE_ROLE_FAIL');

export const ROLES_CLEAR_DATA = createAction('ROLES_CLEAR_DATA');

export const ROLES_FETCH_ROLE_DATA_INIT = createAction(
  'ROLES_FETCH_ROLE_DATA_INIT'
);
export const ROLES_FETCH_ROLE_DATA_FAIL = createAction(
  'ROLES_FETCH_ROLE_DATA_FAIL'
);
export const ROLES_FETCH_ROLE_DATA_SUCCESS = createAction(
  'ROLES_FETCH_ROLE_DATA_SUCCESS'
);

export const ROLES_CREATE_ROLE_INIT = createAction('ROLES_CREATE_ROLE_INIT');
export const ROLES_CREATE_ROLE_SUCCESS = createAction(
  'ROLES_CREATE_ROLE_SUCCESS'
);

export const ROLES_GET_ROLE_SUCCESS = createAction('ROLES_GET_ROLE_SUCCESS');

export const ROLES_CREATE_ROLE_FAIL = createAction('ROLES_CREATE_ROLE_FAIL');

export const ROLES_MODIFY_ROLE_INIT = createAction('ROLES_MODIFY_ROLE_INIT');
export const ROLES_MODIFY_ROLE_SUCCESS = createAction(
  'ROLES_MODIFY_ROLE_SUCCESS'
);
export const ROLES_MODIFY_ROLE_FAIL = createAction('ROLES_MODIFY_ROLE_FAIL');

export const ROLES_CLEAN_UP = createAction('ROLES_CLEAN_UP');

export const ROLES_CLEAR_DATA_LOGOUT = createAction('ROLES_CLEAR_DATA_LOGOUT');

axios.defaults.withCredentials = true;

export const fetchRoles = () => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(ROLES_FETCH_DATA_INIT());
    let roles;

    try {
      const token = await refreshToken();
      const res = await axios.get(`${url}/role`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      roles = res.data.data;
      log(roles);
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ROLES_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      ROLES_FETCH_DATA_SUCCESS({
        list: roles,
      })
    );
  };
};

export const fetchRole = (id) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_GET_DATA_INIT());
    let roles;

    try {
      const token = await refreshToken();
      const res = await axios.get(`${url}/role/get`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      roles = res.data.data;
      log(roles);
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ROLES_GET_DATA_FAIL({ error }));
    }

    return dispatch(
      ROLES_GET_DATA_SUCCESS({
        role: roles,
      })
    );
  };
};

export const deleteRole = (id) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_DELETE_ROLE_INIT());

    try {
      const token = await refreshToken();
      await axios.delete(`${url}/role/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        ROLES_DELETE_ROLE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The role was deleted.');
    return dispatch(ROLES_DELETE_ROLE_SUCCESS({ id }));
  };
};

export const clearUsersData = () => {
  return (dispatch) => {
    dispatch(ROLES_CLEAR_DATA());
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(ROLES_CLEAR_DATA_LOGOUT());
    dispatch(SITES_CLEAR_DATA());
    dispatch(ORGANIZATIONS_CLEAR_DATA());
  };
};

export const createRole = ({ name, access }) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_CREATE_ROLE_INIT());
    // const { locale } = getState().preferences;
    let response;

    // const accessData = {
    //   access: access,
    // };

    // log(accessData, '<<<< access data');

    const data = {
      name,
      access,
    };

    // for (let dataKey in accessData) {
    //   if (dataKey === 'access') {
    //     // append nested object
    //     for (let accessKey in accessData[dataKey]) {
    //       formData.append(
    //         `access[${accessKey}]`,
    //         accessData[dataKey][accessKey]
    //       );
    //     }
    //   } else {
    //     formData.append(dataKey, accessData[dataKey]);
    //   }
    // }

    try {
      const token = await refreshToken();
      response = await axios.post(`${url}/role/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'content-type': 'multipart/form-data',
        },
      });
      log(response, '<<< create user response');
    } catch (error) {
      let errorMessage;
      if (error.response) {
        log(error.response, '<<<< ERROR RESPOINSE');
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        ROLES_CREATE_ROLE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'User created successfully');
    return dispatch(ROLES_CREATE_ROLE_SUCCESS({ role: data }));
  };
};

export const modifyRole = ({
  id,
  name,
  access,
  expirationDuration,
  enableMFA,
  failedAttempt,
}) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_MODIFY_ROLE_INIT());
    let data;
    const filteredAccess = [];
    if (!access) {
      if (enableMFA) {
        data = { id, enableMFA };
      } else {
        data = {
          id,
          expirationDuration,
          failedAttempt,
        };
      }
    } else {
      const checkedAccess = access
        .map((items) => {
          return Object.values(items).map((item) => {
            if (item instanceof Object) {
              return Object.values(item).reduce(
                (previousValue, currentValue) => previousValue || currentValue,
                false
              );
            }
            return item;
          });
        })
        .map((item) => {
          let site = '';
          let res = false;
          item.map((acc) => {
            if (_.isString(acc)) {
              site = acc;
            } else {
              res = res || acc;
            }
            return acc;
          });
          return { siteId: site, result: res };
        });

      checkedAccess
        .filter((item) => item.result)
        .map((acc) => {
          access.map((item) => {
            if (acc.siteId === item.siteId) {
              filteredAccess.push(item);
            }
            return null;
          });
          return null;
        });

      data = {
        id,
        enableMFA,
        name,
        access: filteredAccess,
        // expirationDuration,
        // failedAttempt,
      };
    }

    console.log(data, filteredAccess);
    let response;

    try {
      const token = await refreshToken();
      response = await axios.post(`${url}/role/edit`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      let errorMessage;

      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        ROLES_MODIFY_ROLE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    return dispatch(
      ROLES_MODIFY_ROLE_SUCCESS({
        role: response.data.data,
        _id: id,
        message: response.data.message,
      })
    );
  };
};

export const modifyUserAccess = ({ id, user, accessObject }) => {
  return async (dispatch, getState) => {
    dispatch(ROLES_MODIFY_ROLE_INIT());

    const access = JSON.parse(JSON.stringify(accessObject));

    const userData = {
      ...user,
      id,
      access,
    };

    try {
      const token = await refreshToken();
      await axios.post(`${url}/user/access`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        ROLES_MODIFY_ROLE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'User access updated successfully');
    return dispatch(ROLES_MODIFY_ROLE_SUCCESS({ user: userData, id }));
  };
};

export const checkUserData = () => {
  return (dispatch, getState) => {
    const { id } = getState().auth.userData;

    if (!id) {
      dispatch(fetchUserData());
    }
  };
};

export const fetchUserData = () => {
  return async (dispatch, getState) => {
    dispatch(ROLES_FETCH_ROLE_DATA_INIT());

    const uid = getState().users.user.id;

    let user;

    try {
      const token = await refreshToken();
      user = await axios.post(
        `${url}/user/get`,
        {
          id: uid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      dispatch(logout(user.id));
      return dispatch(ROLES_FETCH_ROLE_DATA_FAIL({ error }));
    }

    if (!user) {
      return dispatch(logout(uid));
    }

    return dispatch(
      ROLES_FETCH_ROLE_DATA_SUCCESS({
        id: uid,
        ...user,
      })
    );
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(ROLES_CLEAN_UP());
