import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { log } from 'utils';
import { fetchPerHour, fetchStatus } from 'state/actions/screenshots';
import Table from 'components/Table';

import './screenshot.css';
import { useFormatMessage } from 'hooks';

const Screenshot = ({ history }) => {
  const { siteId, siteName, mapId } = useParams();

  log(siteId, '<<<<<< SITE ID ON SCREENSHOT PAGE');
  log(mapId, '<<<<< map id ss page');

  const {
    screenshotList,
    // screenshotData,
    status,
  } = useSelector(
    (state) => ({
      status: state.screenshot.status,
      screenshotList: state.screenshot.list,
      // screenshotData: state.screenshot,
      error: state.screenshot.error,
    }),
    shallowEqual
  );

  if (screenshotList) {
    log(screenshotList, '<<<<< screenshot list on ss page');
  }

  if (status) {
    log(status.message, '<<<<< STATUSSS');
  }

  // const [search, setSearch] = useState();
  // const [screenshot, setScreenshot] = useState(screenshotData || {});
  const [imgPreviewUrl, setImgPreviewUrl] = useState('');
  const [mapName, setMapName] = useState('');
  const [dateTaken, setDateTaken] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPerHour({ siteId, mapId }));
    dispatch(fetchStatus({ mapId }));
  }, [siteId, mapId]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = screenshotList

    // eslint-disable-next-line
    .sort(function (a, b) {
      if (a.latest && b.latest) {
        return new Date(b.latest.createdAt) - new Date(a.latest.createdAt);
      }
    })

    // eslint-disable-next-line
    .filter(function (item) {
      if (item.latest) {
        return item.latest.mapId === mapId;
      }
    });

  if (data) {
    log(data, '<<<< data');
  }

  const onBtnClick = (item) => {
    setImgPreviewUrl(item.latest.image);
    setMapName(item.latest.mapName);
    setDateTaken(new Date(item.latest.createdAt));
  };

  const columns = [
    {
      Header: 'Player Name',
      accessor: 'player name',
      Cell: ({ row }) =>
        row.original.latest.mapName ? row.original.latest.mapName : '-',
    },
    {
      Header: 'Date Taken',
      accessor: 'date taken',
      Cell: ({ row }) => {
        const time = row.original.latest.createdAt;
        const localTime = new Date(time);

        return <div>{localTime.toString()}</div>;
      },
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          <button onClick={(e) => onBtnClick(row.original)}>
            <span className="icon is-small">
              <i className="mdi mdi-file-image" />
            </span>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* {role && redirect} */}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">Screen Preview</h1>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-account-edit default" />
                  </span>
                  {useFormatMessage('Screenshot.screenInformation')}
                </p>
              </header>
              <div className="card-content">
                {data && <Table columns={columns} data={data} />}
              </div>
            </div>
          </div>
          <div className="tile is-parent preview">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-account default" />
                  </span>
                  {useFormatMessage('Screenshot.screenPreview')}
                </p>
              </header>
              <div className="card-content">
                {imgPreviewUrl && (
                  <>
                    <div className="is-media-avatar image has-max-width is-aligned-center">
                      <img
                        className="media-avatar"
                        src={imgPreviewUrl}
                        alt="User profile logo preview"
                      />
                    </div>
                    <hr />
                  </>
                )}

                <div className="field">
                  <label className="label">
                    {useFormatMessage('Screenshot.mapName')}
                  </label>
                  <div className="control is-clearfix">
                    <input
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={mapName}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">
                    {useFormatMessage('Screenshot.dateTaken')}
                  </label>
                  <div className="control is-clearfix">
                    <input
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={dateTaken.toLocaleString()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <div className="field is-grouped">
                <Link
                  to={`/${siteId}/information/${siteName}`}
                  className="button"
                >
                  {useFormatMessage('MediaForm.goBack')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Screenshot;
