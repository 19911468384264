import { Form, Input, Modal } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { screensCleanUp } from 'state/actions/screens';
import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';

export default function ModalScreenForm({
  screen,
  setScreen,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) {
  const dispatch = useDispatch();
  const { siteId } = useParams();

  const [form] = Form.useForm();

  const { loading } = useSelector(
    (state) => ({
      loading: state.sites.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    return () => dispatch(screensCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setScreen);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...screen,
        id: screen._id,
        name: values.name,
        siteId,
        description: values.description,
      });
      form.resetFields();
    }
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={isEditing ? 'Edit Screen' : 'Create New Screen'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onSave(values);
                })
                .catch((info) => {
                  log('Validate Failed:', info);
                });
            }}
          >
            Submit
          </button>
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: screen.name,
          description: screen.description,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea rows={4} placeholder="Description" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ModalScreenForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};
