import { Form, Input, Modal } from 'antd';
// import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { categoriesCleanUp } from 'state/actions/mediaCategories';
import { regexDescription, regexInput } from 'utils/regex';
import './ModalMediaCategoryForm.scss';
import { log } from 'utils';

const ModalMediaCategoryForm = ({
  category,
  setCategory,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) => {
  const [form] = Form.useForm();

  const { loading } = useSelector(
    (state) => ({
      loading: state.mediaCategories.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(categoriesCleanUp());
  }, [dispatch]);

  // const onChangeHandler = useChangeHandler(setCategory);

  const handleChange = (name, value) => {
    setCategory({
      ...category,
      [name]: value,
    });
  };

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...category,
        id: category._id,
        name: values.name,
        description: values.description,
        // orientation: values.orientation,
      });
      form.resetFields();
    }
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={isEditing ? 'Edit Playlist' : 'Add New Playlist'}
      closeIcon={<i className="feather-x" />}
      okText="Submit"
      okButtonProps={{
        className: 'button-submit',
      }}
      cancelButtonProps={{
        className: 'button-cancel',
      }}
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        // onChange={onChangeHandler}
        initialValues={{
          name: category.name,
          description: category.description,
        }}
      >
        <Form.Item
          label="Name"
          // name="name"
          rules={[{ required: true, message: `'Name' is required` }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input
            value={category.name}
            placeholder="Enter playlist's name"
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          // name="description"
          validateStatus={errors.description ? 'error' : 'success'}
          rules={[{ required: true, message: `'Description' is required` }]}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea
            rows={4}
            placeholder="Description"
            value={category.description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </Form.Item>

        {/* <Form.Item
          label="Orientation"
          name="orientation"
          rules={[{ required: true, message: `This field is required` }]}
        >
          <Select placeholder="Select orientation">
            <Select.Option value="Portrait">Portrait</Select.Option>
            <Select.Option value="Landscape">Landscape</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

ModalMediaCategoryForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    orientation: PropTypes.string,
  }),
  setCategory: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default ModalMediaCategoryForm;
