import React from 'react';
import { Tag } from 'antd';
import { trimString } from '../../utils';
import moment from 'moment';
import { Tooltip } from 'antd';
import './style.css';
import { EditGrey, TrashGrey } from 'assets/icon';
import CreatedInfo from 'elements/created-info';

const DataCard = ({ data, info, editAction, removeAction }) => {
  return (
    <div className="card-wrapper">
      {data.map((d) => (
        <div className="data-card" key={d._id}>
          <div className="card-data-header">
            <div className="card-info">
              <CreatedInfo user={d.modifiedBy} />
            </div>
            <div className="card-date">
              <Tag className="table-date-card">
                <div className='date-text'>
                  {moment(d.modifiedAt)
                    .utcOffset('+08:00')
                    .format('h:mm A, MMM Do, YYYY')}
                </div>
              </Tag>
            </div>
          </div>
          <div className="card-data">
            <div className="details">
              <div className="title-wrapper">
                <h3>{trimString(d.name, 50)}</h3>
              </div>
              <p>{d.description ? trimString(d.description, 50) : '\u00a0'}</p>
            </div>
          </div>
          <div className="card-line"></div>
          <div className="card-bottom">
            <div className="info">
              {info
                ? d.organizationName
                : d && d.site
                  ? d.site.length && d.site.length === 1
                    ? `${d.site.length} Site`
                    : `${d.site.length} Sites`
                  : '-'}
            </div>
            <div className="actions">
              <Tooltip title="Edit">
                <button className='btn btn--edit' onClick={() => editAction(d)}>
                  <EditGrey /><span className='btn-text'>Edit</span>
                </button>
              </Tooltip>
              <Tooltip title="Delete">
                <button className='btn btn--edit' onClick={() => removeAction(d._id, d.name)}>
                  <TrashGrey /><span className='btn-text'>Delete</span>
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DataCard;
