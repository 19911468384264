import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from 'state/actions/auth';

import './BaseLayout.css';

import { ReactComponent as HomeIcon } from '../../assets/icon/home.svg';
import { ReactComponent as HomeWhiteIcon } from '../../assets/icon/homewhite.svg';
import Sidebar from 'design-components/Sidebar';


function BaseLayout({
  children,
  // history,
  collapsed,
  handleToggle,
  setCollapsed,
}) {
  const dispatch = useDispatch();
  const [openProfile, setOpenProfile] = useState(false);

  const {
    user,
    breadcrumb,
  } = useSelector(
    (state) => ({
      user: state.auth.userData,
      logoUrl: state.auth.userData.logoUrl,
      breadcrumb: state.breadcrumb.list,
    }),
    shallowEqual
  );


  return (
    <div className="base-layout">
      {/* Sidebar */}
      <Sidebar />
      {/* Sidebar Collapsed */}
      {breadcrumb[0].menu === "General Overview" && <div className='blue-background' />}
      <div className={`container ${collapsed ? 'container-collapsed' : ''}`}>
        <div className={`navbar-header ${breadcrumb[0].menu === 'General Overview' ? "overview" : ""}`}>
          <div className={`${breadcrumb[0].menu === 'General Overview' ? "navbar-overview" : "navbar-breadcrumb"}`}>
            <ul>
              <li>
                {breadcrumb[0].menu === 'General Overview' ? <HomeWhiteIcon /> : <HomeIcon />}
              </li>
              {breadcrumb.map((bread, index) => (
                <li className={`${breadcrumb[0].menu === 'General Overview' ? "overview-breadcrumb" : ""}`} key={index}>
                  {bread.menu && bread.menu.length > 30 ? (
                    <Link to={bread.link} >{bread.menu.substr(0, 30)}</Link>
                  ) : (
                    <Link to={bread.link}>{bread.menu}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className={`${breadcrumb[0].menu === 'General Overview' ? "navbar-right-overview" : "navbar-right"}`}>
            <div
              className="profile-header"
              onClick={() => setOpenProfile(!openProfile)}
            >
              <div className="profile-name">
                <p className="profile-title">Hello! {user.name}</p>
                <p className="profile-subtitle">{user.email}</p>
              </div>
              <img alt="default" src={require('../../assets/default.png')} />
            </div>
            <div
              className={`dropdown ${openProfile ? 'dropdown-open' : 'dropdown-close'
                }`}
            >
              <ul>
                <li>
                  <Link to="/profile">
                    <i className="feather-user" />
                    Profile
                  </Link>
                </li>
                <li onClick={() => dispatch(logout(user.id))}>
                  <i className="feather-log-out" />
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`content-children ${breadcrumb[0].menu === 'General Overview' ? "overview" : ""}`}>{children}</div>
        <div className="footer">
          <span>
            &copy; Copyright {new Date().getFullYear()} Adactive Asia Ltd.
            Allright Reserved.
          </span>
        </div>
      </div>
    </div>
  );
}

export default BaseLayout;
