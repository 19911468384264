import { createReducer } from 'redux-act';
import { OPEN_CHILDREN, OPEN_MENU, OPEN_PARENT } from 'state/actions/menu';

const initialState = {
  openChildren: [],
  openParent: '',
  openParents: [],
  openMenu: 'overview',
};

export const menuReducer = createReducer(
  {
    [OPEN_CHILDREN]: (state, payload) => ({
      ...state,
      openChildren: payload.data,
    }),
    [OPEN_PARENT]: (state, payload) => ({
      ...state,
      openParents: payload.data,
    }),
    [OPEN_MENU]: (state, payload) => ({
      ...state,
      openMenu: payload.data,
      openParent: payload.parent,
    }),
  },
  initialState
);
