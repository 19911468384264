import { Empty, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { log } from 'utils';
// import Table from 'components/Table';
import './ScreenConfiguration.css';
import { fetchConfig } from 'state/actions/config';
import { fetchSettings, modifysettings } from 'state/actions/settings';
import { EmptyIcon } from 'assets/icon/bigicon';

const ScreenConfiguration = ({ handleChangeTab }) => {
  const { siteId, screenId } = useParams();

  const [data, setData] = useState({});

  const { configList, settings } = useSelector(
    (state) => ({
      configList: state.config.list,
      settings: state.settings.settings,
      error: state.settings.error,
      loading: state.settings.loading,
      deleted: state.settings.deleted,
      updated: state.settings.updated,
      success: state.settings.success,
      screenList: state.screens.list,
    }),
    shallowEqual
  );

  if (configList) {
    log(configList, '<<<< information categories list');
  }

  if (settings) {
    log(settings, '<<<< settings');
  }

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchScreens({ siteId }));
  // }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (screenId) {
      dispatch(fetchConfig({ siteId, screenId: screenId }));
    }
  }, [siteId, screenId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (screenId) {
      dispatch(fetchSettings({ siteId, screenId: screenId }));
    }
  }, [siteId, screenId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const settingsData = settings ? settings.settings : null;
    setData({
      ...settingsData,
    });
  }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSave = () => {
    dispatch(modifysettings({ siteId, settings: data, screenId: screenId }));
  };

  return (
    <section className="information-categories px-20">
      {screenId && (
        <>
          <div className="categories__table">
            {configList.length > 0 ? (
              <div>
                <h2 className="page-title">Screen Configuration</h2>
                <Form layout="vertical" className="form-adsign">
                  {configList.map((config) => {
                    return (
                      <Form.Item label={config.variable} key={config._id}>
                        <Select
                          value={data[config.variable] && data[config.variable]}
                          onChange={(value) =>
                            handleChange(config.variable, value)
                          }
                          placeholder={`Select ${config.variable}`}
                        >
                          {config.values.map((value, index) => {
                            return (
                              <Select.Option value={value} key={index}>
                                {value}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    );
                  })}
                  <button onClick={handleSave} className="button-primary mt-1">
                    Save Changes
                  </button>
                </Form>
              </div>
            ) : (
              <div className="screen-not-connected">
                <Empty image={EmptyIcon} description="" />
                <h1>No Config variable exists yet</h1>
                <p>Please create first!</p>
              </div>
            )}
            {/* {error && 'Show error'} */}
          </div>
        </>
      )}
    </section>
  );
};

export default ScreenConfiguration;
