import { createReducer } from 'redux-act';

import {
  ORGANIZATIONS_FETCH_DATA_INIT,
  ORGANIZATIONS_FETCH_DATA_SUCCESS,
  ORGANIZATIONS_FETCH_DATA_FAIL,
  ORGANIZATIONS_DELETE_ORGANIZATION_INIT,
  ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS,
  ORGANIZATIONS_DELETE_ORGANIZATION_FAIL,
  ORGANIZATIONS_CLEAR_DATA,
  ORGANIZATIONS_CREATE_ORGANIZATION_INIT,
  ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS,
  ORGANIZATIONS_CREATE_ORGANIZATION_FAIL,
  ORGANIZATIONS_MODIFY_ORGANIZATION_INIT,
  ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS,
  ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL,
  ORGANIZATIONS_CLEAN_UP,
  ORGANIZATIONS_CLEAR_DATA_LOGOUT,
} from 'state/actions/organizations';

const initialState = {
  list: [],
  organization: {
    name: '',
    description: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const organizationsReducer = createReducer(
  {
    [ORGANIZATIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ORGANIZATIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      organization: payload.organization || initialState.organization,
      loading: false,
      error: null,
    }),
    [ORGANIZATIONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ORGANIZATIONS_DELETE_ORGANIZATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [ORGANIZATIONS_DELETE_ORGANIZATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ORGANIZATIONS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [ORGANIZATIONS_CREATE_ORGANIZATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.organization),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [ORGANIZATIONS_CREATE_ORGANIZATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ORGANIZATIONS_MODIFY_ORGANIZATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.organization.name,
                description: payload.organization.description,
                modifiedAt: new Date().toDateString(),
                modifiedBy: elem.email,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ORGANIZATIONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [ORGANIZATIONS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
