import { createAction } from 'redux-act';

export const OPEN_CHILDREN = createAction('OPEN_CHILDREN');
export const OPEN_PARENT = createAction('OPEN_PARENT');
export const OPEN_MENU = createAction('OPEN_MENU');

export const doOpenChildren = (data) => {
  return (dispatch) => {
    return dispatch(OPEN_CHILDREN({ data }));
  };
};

export const doOpenParents = (data) => {
  return (dispatch) => {
    return dispatch(OPEN_PARENT({ data }));
  };
};

export const doOpenMenu = (data, parent) => {
  return (dispatch) => {
    return dispatch(OPEN_MENU({ data, parent }));
  };
};
