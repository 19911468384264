import React from 'react';
import './SelectDropdown.css';
import { useState } from 'react';

const SelectDropdown = ({
  list,
  selected,
  addNewList,
  onAddNewList,
  onSet,
  valueParams,
  labelParams,
  allTitle,
  Icon,
}) => {
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [tempSelected, setTempSelected] = useState(selected ?? 'all');

  return (
    <div>
      <div
        className="playlist-wrapper"
        onClick={() => setPlaylistOpen(!playlistOpen)}
      >
        <div className="playlist-selection">
          {Icon && <Icon />}
          <span>
            {selected === 'all' || !selected
              ? allTitle
              : valueParams
              ? list.find((data) => data[valueParams] === selected)[labelParams]
              : selected}
          </span>
        </div>
        <i
          className={`feather-chevron-down ${
            playlistOpen ? 'playlist-open' : 'playlist-closed'
          }`}
        />

        <div
          className={`playlist-dropdown ${
            playlistOpen ? 'playlist-dropdown-open' : 'playlist-dropdown-closed'
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <ul>
            {addNewList && (
              <li className="header" onClick={onAddNewList}>
                <i className="feather-plus" />
                Add New Category
              </li>
            )}
            <li>
              <div
                className={`radio ${tempSelected === 'all' ? 'active' : ''}`}
                onClick={() => setTempSelected('all')}
              >
                {tempSelected === 'all' && <div className="radio-checked" />}
              </div>
              {allTitle}
            </li>

            {list.map((cat) => {
              return (
                <li key={valueParams ? cat[valueParams] : cat}>
                  <div className="playlist-filter">
                    <div className="playlist-filter-radio">
                      <div
                        className={`radio ${
                          tempSelected ===
                          (valueParams ? cat[valueParams] : cat)
                            ? 'active'
                            : ''
                        }`}
                        onClick={() =>
                          setTempSelected(valueParams ? cat[valueParams] : cat)
                        }
                      >
                        {tempSelected ===
                          (valueParams ? cat[valueParams] : cat) && (
                          <div className="radio-checked" />
                        )}
                      </div>
                      {labelParams ? cat[labelParams] : cat}
                    </div>
                    {/* <Tooltip title="Options">
                      <Dropdown
                        trigger="click"
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="edit"
                              onClick={() => handleEditCategory(cat)}
                            >
                              <i className="feather-edit mr-2" />
                              Edit
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              key="delete"
                              onClick={() => handleDeleteCategory(cat)}
                            >
                              <i className="feather-trash-2 mr-2" />
                              Delete
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <button>
                          <i className="feather-more-horizontal" />
                        </button>
                      </Dropdown>
                    </Tooltip> */}
                  </div>
                </li>
              );
            })}
            <li
              className="playlist-button"
              onClick={() => {
                setPlaylistOpen(false);
                onSet(tempSelected);
              }}
            >
              Set Category
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectDropdown;
