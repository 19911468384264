import { createReducer } from 'redux-act';

import {
  FILES_FETCH_DATA_INIT,
  FILES_FETCH_DATA_SUCCESS,
  FILES_FETCH_DATA_FAIL,
  FILES_DELETE_FILE_INIT,
  FILES_DELETE_FILE_SUCCESS,
  FILES_DELETE_FILE_FAIL,
  FILES_CLEAR_DATA,
  FILES_CREATE_FILE_INIT,
  FILES_CREATE_FILE_SUCCESS,
  FILES_CREATE_FILE_FAIL,
  FILES_MODIFY_FILE_INIT,
  FILES_MODIFY_FILE_SUCCESS,
  FILES_MODIFY_FILE_FAIL,
  FILES_CLEAN_UP,
  FILES_CLEAR_DATA_LOGOUT,
} from 'state/actions/files';

const initialState = {
  list: [],
  file: {
    name: '',
    url: '',
    path: '',
    screenId: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const filesReducer = createReducer(
  {
    [FILES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [FILES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      file: payload.file || initialState.file,
      loading: false,
      error: null,
    }),
    [FILES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FILES_DELETE_FILE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FILES_DELETE_FILE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [FILES_DELETE_FILE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FILES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [FILES_CREATE_FILE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FILES_CREATE_FILE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.file),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [FILES_CREATE_FILE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FILES_MODIFY_FILE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FILES_MODIFY_FILE_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.file.name,
                url: payload.file.url,
                path: payload.file.path,
                screenId: payload.file.screenId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: elem.email,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [FILES_MODIFY_FILE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FILES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [FILES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
