import React from 'react';

const PasteIcon = ({ size = 24, color = '#000' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.71 8.11998V17.53C20.71 19.99 18.7 22 16.24 22H7.76004C5.30004 22 3.29004 19.99 3.29004 17.53V8.11998C3.29004 6.40998 4.25004 4.91998 5.66004 4.16998C6.15004 3.90998 6.76004 4.25998 6.76004 4.81998C6.76004 6.40998 8.06004 7.70998 9.65004 7.70998H14.35C15.94 7.70998 17.24 6.40998 17.24 4.81998C17.24 4.25998 17.84 3.90998 18.34 4.16998C19.75 4.91998 20.71 6.40998 20.71 8.11998Z"
        fill={color}
      />
      <path
        d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z"
        fill={color}
      />
    </svg>
  );
};

export default PasteIcon;
