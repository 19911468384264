import React, { useState, useEffect, useRef } from 'react';
import { useChangeHandler } from 'hooks';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import { logout, mfa } from 'state/actions/auth';
import { toastr } from 'react-redux-toastr';
import path from 'pages/Router/paths';
import { log } from 'utils';
import { ScanIcon } from 'assets/icon';
import NotFoudImage from 'assets/404.gif';
import { ReactComponent as LockLogo } from '../../assets/Iconly/Light-Outline/Lock-logo.svg';
import './MFA.css';
import { getRole } from 'utils/secrets';



const MFA = ({ history }) => {
  const { isAuth, userData, error, configUri } = useSelector(
    (state) => ({
      isAuth: !!state.auth.userData.id,
      userData: state.auth.userData,
      error: state.auth.error,
      configUri: state.auth.configUri,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [otp, setOtp] = useState('');
  const [isShowCode, setIsShowCode] = useState(true);

  const otpInput = useRef(null);
  const onChangeHandler = useChangeHandler(setOtp);

  const userPath = isAuth ? path.ROOT : path.LOGIN;

  useEffect(() => {
    if (otpInput.current) {
      otpInput.current.input.focus();
    }
  }, [otpInput]);

  useEffect(() => {
    console.log(userData, 'onmfa')
    if (error) {
      toastr.error(error);
      console.log(error, 'error before login')
      dispatch(logout(userData.id));
      setOtp('');
      history.push(path.LOGIN);
    }
    if (isAuth) {
      sessionStorage.setItem('activeMenu', 'dashboard');
      history.push(path.ROOT);
    }
    onCheckRole();
    // if (role !== 'superadmin'){
    //   history.push(path.ROOT);
    // }
    if (configUri) {
      log(configUri);
    }
    log(userData);

    setIsShowCode(!userData.mfaEnabled);
  }, [error, isAuth]); // eslint-disable-line

  const onCheckRole = async () => {
    const roleData = await getRole();
    if (roleData !== 'superadmin' && !userData.roleMfaEnabled) {
      sessionStorage.setItem('activeMenu', 'dashboard');
      history.push(path.ROOT);
    }
  };

  const onClickLogoutHandler = () => {
    dispatch(logout(userData.id));
    history.push(path.ROOT);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(mfa(otp.otp));
  };

  const showMFAInput = () => {
    setIsShowCode(false);
  };

  const showQRPage = () => {
    setIsShowCode(true);
  };

  return !userData ? (
    <div className="container is-widescreen">
      <h1 className="title">Error 404: page not found</h1>
      <p className="subtitle">The requested URL was not found</p>
      <Link className="button is-info is-normal" to={userPath}>
        Go Back
      </Link>
      <img src={NotFoudImage} alt="404 error" />
    </div>
  ) : (
    <div className="mfa">
      <div className="mfa-header">
        <img
          src={require('../../assets/logo-new.png')}
          className="login-logo"
          alt="logo"
        />
        <h1>Multi-factor Authentication</h1>
        <p>Enter an MFA code to complete sign in.</p>
      </div>
      {!isShowCode ? (
        userData.mfaEnabled ? (
          <>
            <form className="form-wrapper-mfa" onSubmit={onSubmitHandler}>
              <span>Code </span>
              <div>
                <Input
                  id="otp"
                  name="otp"
                  placeholder="Enter your MFA Code"
                  prefix={<LockLogo width="22" height="22" />}
                  ref={otpInput}
                  maxLength={6}
                  required
                  onChange={onChangeHandler}
                  value={otp.otp}
                />
              </div>
              <div className="buttons">
                <button type="submit" className="button mfa-button">
                  Submit
                </button>
                <div className="back" onClick={onClickLogoutHandler}>
                  <i className="feather-arrow-left" />
                  Back to Login
                </div>
              </div>
            </form>
          </>
        ) : (
          <>
            <form className="form-wrapper-mfa" onSubmit={onSubmitHandler}>
              <div>Code </div>
              <div>
                <Input
                  id="otp"
                  name="otp"
                  placeholder="Enter your MFA Code"
                  prefix={<LockLogo width="22" height="22" />}
                  ref={otpInput}
                  maxLength={6}
                  required
                  onChange={onChangeHandler}
                  value={otp.otp}
                />
              </div>
              <div className="scan" onClick={showQRPage}>
                <ScanIcon width="24" height="24" />
                <p>Open Code</p>
              </div>
              <div className="buttons">
                <button type="submit" className="button mfa-button">
                  Submit
                </button>
                <div className="back" onClick={onClickLogoutHandler}>
                  <Link to="/login" className="btn-primary-ghost">
                    <i className="feather-arrow-left" />
                    Back to Login
                  </Link>
                </div>
              </div>
            </form>
          </>
        )
      ) : (
        <div className="mfa-qr">
          <div className="qr-wrapper">
            {configUri && (
              <QRCodeSVG width={160} height={160} value={configUri}></QRCodeSVG>
            )}
          </div>
          <ul className="step">
            <li>
              <span>1</span>
              Install Google Authenticator
            </li>
            <li>
              <span>2</span>
              Open the Application and tap the (+) button
            </li>
            <li>
              <span>3</span>
              Scan Barcode
            </li>
            <li>
              <span>4</span>
              Enter the one time code provided by the application
              <button className="btn-primary-ghost" onClick={showMFAInput}>
                Click Here
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MFA;
