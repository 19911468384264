import { createReducer } from 'redux-act';

import {
  CONFIG_FETCH_DATA_INIT,
  CONFIG_FETCH_DATA_SUCCESS,
  CONFIG_FETCH_DATA_FAIL,
  CONFIG_DELETE_INIT,
  CONFIG_DELETE_SUCCESS,
  CONFIG_DELETE_FAIL,
  CONFIG_CLEAR_DATA,
  CONFIG_CREATE_INIT,
  CONFIG_CREATE_SUCCESS,
  CONFIG_CREATE_FAIL,
  CONFIG_MODIFY_INIT,
  CONFIG_MODIFY_SUCCESS,
  CONFIG_MODIFY_FAIL,
  CONFIG_CLEAN_UP,
  CONFIG_CLEAR_DATA_LOGOUT,
} from 'state/actions/config';

const initialState = {
  list: [],
  config: {
    variable: '',
    values: [],
    siteId: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
  updated: false,
};

export const configReducer = createReducer(
  {
    [CONFIG_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CONFIG_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      config: payload.config || initialState.config,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [CONFIG_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIG_DELETE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIG_DELETE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [CONFIG_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIG_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [CONFIG_CREATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIG_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.config),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [CONFIG_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIG_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIG_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem._id === payload.id) {
              return {
                _id: payload.id,
                variable: payload.config.variable,
                values: payload.config.values,
                siteId: payload.config.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.config.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [CONFIG_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIG_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
      updated: false,
    }),
    [CONFIG_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
