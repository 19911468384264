import React from "react";
import ScreensaverReport from './ScreensaverReport';

const Report = (props) => {

    return (<div>
        <ScreensaverReport siteId={props.siteId} />
        {/* <SearchPOIReport siteId={siteId} /> */}
    </div>)
};

export default Report;