import React from 'react';
import './style.css';

const MainTitle = ({ children }) => {
  return (
    <span className="main-title">{children}</span>
  );
};

export default MainTitle;
