import axios from 'axios';
import { format } from 'date-fns';
import { url } from 'utils/url';
axios.defaults.withCredentials = true;

export const formatDate = (string) => {
  return format(
    new Date(
      string.substring(0, 4),
      string.substring(5, 7),
      string.substring(8, 10)
    ),
    'yyyyMMdd'
  );
};

export const transformToDate = (string) => {
  return new Date(
    string.substring(0, 4),
    string.substring(5, 7),
    string.substring(8, 10)
  );
};

export const getCsrf = async () => {
  try {
    const token = await axios.get(`${url}/form/`, {
      headers: {
        'X-CSRF-TOKEN': 'token',
      },
      withCredentials: true,
    });
    console.log(token, 'token data');
    return token.data.csrfToken;
  } catch (error) {
    return null;
  }
};
