import React from 'react';

const CodeIcon = ({ size = 24, color = '#000' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.88991 15.75C6.60991 15.75 6.34991 15.6 6.21991 15.34C6.02991 14.97 6.17991 14.52 6.55991 14.33C7.42991 13.9 8.16991 13.24 8.69991 12.44C8.87991 12.17 8.87991 11.83 8.69991 11.56C8.15991 10.76 7.41991 10.1 6.55991 9.67002C6.17991 9.49002 6.02991 9.04002 6.21991 8.66002C6.39991 8.29002 6.84991 8.14002 7.21991 8.33002C8.31991 8.88002 9.25991 9.71002 9.93991 10.73C10.4499 11.5 10.4499 12.5 9.93991 13.27C9.25991 14.29 8.31991 15.12 7.21991 15.67C7.11991 15.72 6.99991 15.75 6.88991 15.75Z"
        fill={color}
      />
      <path
        d="M17 15.75H13C12.59 15.75 12.25 15.41 12.25 15C12.25 14.59 12.59 14.25 13 14.25H17C17.41 14.25 17.75 14.59 17.75 15C17.75 15.41 17.41 15.75 17 15.75Z"
        fill={color}
      />
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill={color}
      />
    </svg>
  );
};

export default CodeIcon;
