import React from 'react';
import './style.css';


const CardInfo = ({ 
  title,
  icon,
 }) => {
  return (
    <div className="media-date">
      {icon}
      <span>
        {title}
      </span>
    </div>
  );
};

export default CardInfo;
