import { Table, Tooltip } from 'antd';
import ModalPanelForm from 'components/ModalPanelForm';
import PageHeader from 'design-components/PageHeader';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import { fetchRoles, modifyRole } from 'state/actions/roles';
import { fetchUsers } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import './Users.scss';

function Panel() {
  const [search, setSearch] = useState('');
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    role: {},
  });

  const {
    roleList,
    userList,
    // isAdmin,
    error,
    loading,
    updated,
  } = useSelector(
    (state) => ({
      roleList: state.roles.list,
      userList: state.users.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.roles.error,
      loading: state.roles.loading,
      deleted: state.roles.deleted,
      userData: state.auth.userData,
      updated: state.roles.updated,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

 const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };


  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchUsers());
    }
  }, [dispatch, role]);

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(
      setBreadcrumb([
        { menu: 'Admin Panel', link: '/panel' },
        { menu: 'Roles Authentication Policy', link: '/panel' },
      ])
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(fetchRoles());
    setOpenModal({ isOpen: false, role: {} });
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchError, setSearchError] = useState(null);

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: 'USERS WITH THE ROLE',
      dataIndex: 'access',
      sorter: (a, b) =>
        userList.filter((user) => user.roleId && user.roleId === a._id).length -
        userList.filter((user) => user.roleId && user.roleId === b._id).length,
      render: (text, record) => {
        return (
          <>
            {
              userList.filter(
                (user) => user.roleId && user.roleId === record._id
              ).length
            }
          </>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'option',
      render: (text, record) => {
        return (
          <div className="users__actions" key={record._id}>
            {(role === 'admin' && record.role === 'user') ||
            role === 'superadmin' ? (
              <Tooltip title="Edit Role">
                <button
                  className="btn btn--edit"
                  onClick={() => setOpenModal({ isOpen: true, role: record })}
                >
                  <i className="feather-edit" />
                  Edit Authentication Policy
                </button>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  const data = search
    ? roleList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        const name =
          clonedElem.name &&
          clonedElem.name.toLowerCase().includes(search.toLowerCase());
        if (name) {
          return clonedElem;
        }
        return false;
      })
    : // eslint-disable-next-line
      roleList;

  return (
    <section className="panel">
      {role && redirect}

      {openModal.isOpen && (
        <ModalPanelForm
          visible={openModal.isOpen}
          onCancel={() => {
            setOpenModal({ isOpen: false, role: {} });
          }}
          role={openModal.role}
          onCreate={(role) =>
            dispatch(
              modifyRole({
                id: role._id,
                expirationDuration:
                  role.expirationDuration > 0 ? role.expirationDuration : 180,
                failedAttempt: role.failedAttempt > 0 ? role.failedAttempt : 5,
              })
            )
          }
        />
      )}
      <PageHeader
        title="Roles Authentication Policy"
        isGrid={false}
        isSearch={true}
        searchValue={search}
        searchAction={handleSearch}
        searchPlaceholder="Enter Role's Name"
        searchError={searchError}
        isAdd={false}
        addLabel=""
      />

      <div className="panel__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="_id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}

export default Panel;
