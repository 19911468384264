import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const SidebarItem = ({ title, className, to, clickAction }) => {
  return (
    <li className={className}>
      <Link to={to} onClick={clickAction}>
        {title.length > 15 ? title.substring(0,15)+'...': title}
      </Link>
    </li>
  );
};

export default SidebarItem;
