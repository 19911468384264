import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  FEEDBACK_FETCH_DATA_INIT,
  FEEDBACK_FETCH_DATA_SUCCESS,
  FEEDBACK_FETCH_DATA_FAIL,
  FEEDBACK_DELETE_FEEDBACK_INIT,
  FEEDBACK_DELETE_FEEDBACK_SUCCESS,
  FEEDBACK_DELETE_FEEDBACK_FAIL,
  FEEDBACK_CLEAR_DATA,
  FEEDBACK_CREATE_FEEDBACK_INIT,
  FEEDBACK_CREATE_FEEDBACK_SUCCESS,
  FEEDBACK_CREATE_FEEDBACK_FAIL,
  FEEDBACK_MODIFY_FEEDBACK_INIT,
  FEEDBACK_MODIFY_FEEDBACK_SUCCESS,
  FEEDBACK_MODIFY_FEEDBACK_FAIL,
  FEEDBACK_CLEAN_UP,
  FEEDBACK_CLEAR_DATA_LOGOUT,
} from 'state/actions/feedback';

const initialState = {
  list: [],
  feedback: {
    question: '',
    start: '',
    end: '',
    siteId: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const feedbackReducer = createReducer(
  {
    [FEEDBACK_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [FEEDBACK_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      feedback: payload.feedback || initialState.feedback,
      loading: false,
      error: null,
    }),
    [FEEDBACK_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FEEDBACK_DELETE_FEEDBACK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FEEDBACK_DELETE_FEEDBACK_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [FEEDBACK_DELETE_FEEDBACK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FEEDBACK_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [FEEDBACK_CREATE_FEEDBACK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FEEDBACK_CREATE_FEEDBACK_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.feedback),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [FEEDBACK_CREATE_FEEDBACK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FEEDBACK_MODIFY_FEEDBACK_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FEEDBACK_MODIFY_FEEDBACK_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                question: payload.feedback.question,
                start: payload.feedback.start,
                end: payload.feedback.end,
                siteId: payload.feedback.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.feedback.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [FEEDBACK_MODIFY_FEEDBACK_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FEEDBACK_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [FEEDBACK_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
