import React, { useState, useEffect } from 'react';
import './ScreenSettings.css';
import { Slider, Skeleton, Tooltip } from 'antd';
import socketIoClient from 'socket.io-client';
import { url } from 'utils/url';
import { log } from 'utils';
import { useSelector } from 'react-redux';
import {
  connectSocket,
  changeVolume,
  // changeMute,
  changeDisplay,
  screenShot,
  forceUpdate,
  restartScreen,
} from 'state/actions/settings';
// import { useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { Empty } from 'antd';
import { NotConnectedIcon } from 'assets/icon/bigicon';
import { CameraIcon, TrashIcon } from 'assets/icon/dynamic';

function ScreenSettings({ handleChangeTab }) {
  // const { siteId } = useParams();

  const { screenLoading, activeScreen } = useSelector((state) => ({
    screenList: state.screens.list,
    screenLoading: state.screens.loading,
    activeScreen: state.screens.activeScreen,
  }));

  const [socket, setSocket] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  // const [captureLoading, setCaptureLoading] = useState(false);

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState({
    device: true,
    sound: true,
    picture: true,
  });

  const [screenshoot, setScreenshoot] = useState(null);

  const [soundInfo, setSoundInfo] = useState({
    level: 0,
    muted: false,
  });

  const [pictureInfo, setPictureInfo] = useState({
    brightness: 0,
    backlight: 0,
    contrast: 0,
  });

  // const dispatch = useDispatch();

  const data = {
    name: 'adsign',
  };

  // const onChangeHandler = useChangeHandler(setDownloadUrl);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    if (!deviceInfo) {
      setError(true);
    }
  }, [deviceInfo]);

  useEffect(() => {
    setSocket(
      socketIoClient(url, {
        query: {
          name: 'adsign',
        },
      })
    );
  }, []);

  useEffect(() => {
    setDeviceInfo(null);
    if (socket && activeScreen._id) {
      socket.on('connect', () => {
        socket.emit('message', data);
      });
      socket.on('in_device', (data) => {
        log(data);
        if (data.screenId === activeScreen._id) {
          setError(false);
          setDeviceInfo(data);
          getPictureInfo();
          setLoading({
            ...loading,
            device: false,
          });
        } else {
          setDeviceInfo(null);
          setLoading({
            device: true,
            sound: true,
            picture: true,
          });
        }
      });
    } else {
      log('socket and activeScreen is not called');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, activeScreen._id]);

  // useEffect(() => {
  //   dispatch(fetchScreens({ siteId }));
  // }, [dispatch, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeScreen._id) {
      connectSocket(activeScreen._id);
    }
  }, [activeScreen._id]);

  const handleTryAgain = () => {
    connectSocket(activeScreen._id);
    setLoading({
      device: true,
      sound: true,
      picture: true,
    });
    setError(false);
    getDeviceInfo();
  };

  const getDeviceInfo = async () => {
    log('get device info');
    socket.emit('message', data, (response) => {
      log(response, 'response socket'); // ok
    });
    await delay();
    if (!deviceInfo) {
      setError(true);
    }
  };

  // const getSoundInfo = async () => {
  //   socket.once('in_sound', async (data) => {
  //     setSoundInfo(data);
  //     setLoading({
  //       ...loading,
  //       device: false,
  //       sound: false,
  //     });
  //     await delay();
  //     getPictureInfo();
  //   });
  // };

  const getPictureInfo = () => {
    socket.once('in_picture', (data) => {
      log(data, 'data pickture');
      setPictureInfo(data);
      setLoading({
        ...loading,
        device: false,
        sound: false,
        picture: false,
      });
    });
  };

  const handleChangeVolume = (value) => {
    changeVolume(activeScreen._id, value);
    setSoundInfo({
      ...soundInfo,
      level: value,
    });
  };

  // const handleChangeMuted = (checked) => {
  //   changeMute(activeScreen._id, checked);
  //   setSoundInfo({
  //     ...soundInfo,
  //     muted: checked,
  //   });
  // };

  const handleChangeBacklight = (value) => {
    changeDisplay(activeScreen._id, { name: 'backlight', value });
    setPictureInfo({
      ...pictureInfo,
      backlight: value,
    });
  };

  const handleChangeContrast = (value) => {
    changeDisplay(activeScreen._id, { name: 'contrast', value });
    setPictureInfo({
      ...pictureInfo,
      contrast: value,
    });
  };

  const handleChangeBrightness = (value) => {
    changeDisplay(activeScreen._id, { name: 'brightness', value });
    setPictureInfo({
      ...pictureInfo,
      brightness: value,
    });
  };

  const handleCapture = () => {
    screenShot(activeScreen._id);
    // setCaptureLoading(true);
    socket.once('in_capture', (data) => {
      // setCaptureLoading(false);
      if (data.screenId === activeScreen._id) {
        setScreenshoot(data.img);
      }
    });
  };

  // const handleInstallApplication = () => {
  //   installApplication(activeScreen._id, downloadUrl.name)
  //   socket.once('in_install', (data) => {
  //     log(data)
  //   });
  // };

  const handleRestartScreen = () => {
    restartScreen(activeScreen._id);
    socket.once('in_restart', (data) => {
      log(data);
    });
  };

  const handleForceUpdate = () => {
    forceUpdate(activeScreen._id);
    socket.once('in_update', (data) => {
      log(data);
    });
  };

  return (
    <div className="screen p-20">
      {screenLoading ? (
        <ClipLoader />
      ) : (
        <div>
          <div>
            {log(error, 'error')}
            {error ? (
              <div className="screen-not-connected">
                <Empty image={NotConnectedIcon} description="" />
                <h1>The device isn't connected</h1>
                <button
                  className="btn-outline-primary"
                  onClick={handleTryAgain}
                >
                  Try Again
                </button>
              </div>
            ) : (
              <>
                <div className="screen-content">
                  <div className="screen-column">
                    <p className="screen-title">Device Information</p>
                    <div className="screen-table">
                      {loading.device ? (
                        <Skeleton loading />
                      ) : (
                        <>
                          <div>
                            <div>Manufacture</div>
                            <div>{deviceInfo && deviceInfo.manufacturer}</div>
                          </div>
                          <div>
                            <div>SDK Version</div>
                            <div>{deviceInfo && deviceInfo.sdkVersion}</div>
                          </div>
                          <div>
                            <div>Model Name</div>
                            <div>{deviceInfo && deviceInfo.modelName}</div>
                          </div>
                          <div>
                            <div>Serial Number</div>
                            <div>{deviceInfo && deviceInfo.serialNumber}</div>
                          </div>
                          <div>
                            <div>Firmware Version</div>
                            <div>
                              {deviceInfo && deviceInfo.firmwareVersion}
                            </div>
                          </div>
                          <div>
                            <div>Hardware Version</div>
                            <div>
                              {deviceInfo && deviceInfo.hardwareVersion}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="screen-column">
                    <div className="screen-column">
                      <p className="screen-title">Display Configuration</p>
                      <div className="screen-list">
                        {loading.picture ? (
                          <Skeleton loading />
                        ) : (
                          <>
                            <div>
                              <p className="screen-p">Backlight</p>
                              <Slider
                                onChange={handleChangeBacklight}
                                value={pictureInfo.backlight}
                                // tooltipVisible
                                min={0}
                                max={100}
                              />
                            </div>
                            <div>
                              <p className="screen-p">Contrast</p>
                              <Slider
                                onChange={handleChangeContrast}
                                value={pictureInfo.contrast}
                                // tooltipVisible
                                min={0}
                                max={100}
                              />
                            </div>
                            <div>
                              <p className="screen-p">Brightness</p>
                              <Slider
                                onChange={handleChangeBrightness}
                                value={pictureInfo.brightness}
                                // tooltipVisible
                                min={0}
                                max={100}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="Screen-column">
                      <p className="screen-title">Sound Configuration</p>
                      <div className="screen-list">
                        {loading.sound ? (
                          <Skeleton loading />
                        ) : (
                          <>
                            <div>
                              <p className="screen-p">Volume</p>
                              <Slider
                                disabled={soundInfo.muted}
                                onChange={handleChangeVolume}
                                value={soundInfo.level}
                                // tooltipVisible
                                min={0}
                                max={100}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="screen-column">
                      <p className="screen-title">Take a screenshot</p>
                      <p className="screen-p">Capture</p>
                      {loading.picture && loading.sound && loading.picture ? (
                        <Skeleton.Image loading />
                      ) : (
                        <>
                          <div className="screen-shoot">
                            {screenshoot ? (
                              <>
                                <img src={screenshoot} alt="Screenshoot" />
                                <Tooltip title="Delete">
                                  <button
                                    className="button-remove"
                                    onClick={() => setScreenshoot(null)}
                                  >
                                    <TrashIcon color="#CB3A31" size={20} />
                                  </button>
                                </Tooltip>
                              </>
                            ) : (
                              <button
                                className="camera-button"
                                onClick={handleCapture}
                              >
                                <CameraIcon size={18} color="#64D5D2" />
                                Capture
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="screen-content">
                  <div className="screen-column">
                    <div className="screen-button-update">
                      {/* <p className="screen-title">Install Application</p>
                                <div>
                                  <Input
                                    placeholder="IPK Location"
                                    name="name"
                                    type="text"
                                    value={downloadUrl.name}
                                    onChange={onChangeHandler}
                                  />
                                </div>
                                <div>
                                  <button
                                    onClick={handleInstallApplication}
                                    className="install-button">
                                    Install Application
                                  </button>
                                </div> */}
                      <div>
                        <button
                          onClick={handleRestartScreen}
                          className="install-button-outline"
                        >
                          Restart Screen
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={handleForceUpdate}
                          className="install-button"
                        >
                          Force Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ScreenSettings;
