import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  MAPS_FETCH_DATA_INIT,
  MAPS_FETCH_DATA_SUCCESS,
  MAPS_FETCH_DATA_FAIL,
  MAPS_DELETE_MAPS_INIT,
  MAPS_DELETE_MAPS_SUCCESS,
  MAPS_DELETE_MAPS_FAIL,
  MAPS_CLEAR_DATA,
  MAPS_CREATE_MAPS_INIT,
  MAPS_CREATE_MAPS_SUCCESS,
  MAPS_CREATE_MAPS_FAIL,
  MAPS_MODIFY_MAPS_INIT,
  MAPS_MODIFY_MAPS_SUCCESS,
  MAPS_MODIFY_MAPS_FAIL,
  MAPS_CLEAN_UP,
  MAPS_CLEAR_DATA_LOGOUT,
} from 'state/actions/maps';

const initialState = {
  list: [],
  map: {
    name: '',
    description: '',
    fileUrl: null,
    siteId: '',
    paths: [],
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const mapsReducer = createReducer(
  {
    [MAPS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MAPS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      map: payload.map || initialState.map,
      loading: false,
      error: null,
    }),
    [MAPS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPS_DELETE_MAPS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPS_DELETE_MAPS_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [MAPS_DELETE_MAPS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [MAPS_CREATE_MAPS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPS_CREATE_MAPS_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.maps),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [MAPS_CREATE_MAPS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPS_MODIFY_MAPS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPS_MODIFY_MAPS_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.maps.name,
                description: payload.maps.description,
                fileUrl: payload.maps.fileUrl,
                siteId: payload.maps.siteId,
                paths: payload.maps.paths,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.maps.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [MAPS_MODIFY_MAPS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [MAPS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
