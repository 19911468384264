import { createReducer } from 'redux-act';

import {
  INFORMATION_CATEGORIES_FETCH_DATA_INIT,
  INFORMATION_CATEGORIES_FETCH_DATA_SUCCESS,
  INFORMATION_CATEGORIES_FETCH_DATA_FAIL,
  INFORMATION_CATEGORIES_DELETE_CATEGORY_INIT,
  INFORMATION_CATEGORIES_DELETE_CATEGORY_SUCCESS,
  INFORMATION_CATEGORIES_DELETE_CATEGORY_FAIL,
  INFORMATION_CATEGORIES_CLEAR_DATA,
  INFORMATION_CATEGORIES_CREATE_CATEGORY_INIT,
  INFORMATION_CATEGORIES_CREATE_CATEGORY_SUCCESS,
  INFORMATION_CATEGORIES_CREATE_CATEGORY_FAIL,
  INFORMATION_CATEGORIES_MODIFY_CATEGORY_INIT,
  INFORMATION_CATEGORIES_MODIFY_CATEGORY_SUCCESS,
  INFORMATION_CATEGORIES_MODIFY_CATEGORY_FAIL,
  INFORMATION_CATEGORIES_CLEAN_UP,
  INFORMATION_CATEGORIES_CLEAR_DATA_LOGOUT,
  SET_INFORMATION_CATEGORY,
} from 'state/actions/informationCategories';

const initialState = {
  list: [],
  category: {
    _id: '',
    name: '',
    description: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const informationCategoriesReducer = createReducer(
  {
    [INFORMATION_CATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [INFORMATION_CATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      category: payload.category || initialState.category,
      loading: false,
      error: null,
    }),
    [INFORMATION_CATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INFORMATION_CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [INFORMATION_CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_CATEGORIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [INFORMATION_CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INFORMATION_CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.informationCategory),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [INFORMATION_CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_CATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INFORMATION_CATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem._id === payload.id) {
              return {
                _id: payload.id,
                name: payload.informationCategory.name,
                description: payload.informationCategory.description,
                siteId: payload.informationCategory.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.informationCategory.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [INFORMATION_CATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_CATEGORIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [INFORMATION_CATEGORIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [SET_INFORMATION_CATEGORY]: (state, payload) => ({
      ...state,
      category: payload.data,
    }),
  },
  initialState
);
