import React, { memo } from 'react';
import { Table } from 'antd';

const RolesTable = memo(
  ({ columns, data, search, selectedOrganization, loading }) => {
    const filteredData = data
      .filter((x) => {
        if (search) {
          return x.title.toLowerCase().includes(search.toLowerCase());
        }
        return true;
      })
      .filter((x) => {
        if (selectedOrganization !== 'All') {
          return x.organization_id === selectedOrganization;
        }
        return true;
      });

    return (
      <Table
        columns={columns}
        scroll={{
          x: columns.length > 7 ? `${columns.length * 17}vw` : false,
          y: 400,
        }}
        dataSource={filteredData}
        size="small"
        bordered
        pagination={false}
        defaultExpandAllRows
        rowKey={(x) => x.id}
        loading={loading}
        expandable={{
          defaultExpandAllRows: true,
          expandIcon: ({ expanded, onExpand, record }) => {
            return record.children && record.children.length > 0 ? (
              expanded ? (
                <span
                  className="icon-permission-open"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <span
                  className="icon-permission"
                  onClick={(e) => onExpand(record, e)}
                />
              )
            ) : (
              <span style={{ marginRight: '1em' }} />
            );
          },
        }}
      />
    );
  }
);

export default RolesTable;
