import React from 'react';

const ExportIcon = ({ color = '#000', size = 25 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 9H12.75V15.25C12.75 15.66 12.41 16 12 16C11.59 16 11.25 15.66 11.25 15.25V9H7.2C4 9 2 11 2 14.2V16.79C2 20 4 22 7.2 22H16.79C19.99 22 21.99 20 21.99 16.8V14.2C22 11 20 9 16.8 9Z"
        fill={color}
      />
      <path
        d="M12.75 4.55994L14.82 6.62994C14.97 6.77994 15.16 6.84994 15.35 6.84994C15.54 6.84994 15.73 6.77994 15.88 6.62994C16.17 6.33994 16.17 5.85994 15.88 5.56994L12.53 2.21994C12.24 1.92994 11.76 1.92994 11.47 2.21994L8.11997 5.56994C7.82997 5.85994 7.82997 6.33994 8.11997 6.62994C8.40997 6.91994 8.88997 6.91994 9.17997 6.62994L11.25 4.55994V8.99994H12.75V4.55994Z"
        fill={color}
      />
    </svg>
  );
};

export default ExportIcon;
