import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, getClientIp, refreshToken } from 'utils';
import {
  ORGANIZATIONS_FETCH_DATA_INIT,
  ORGANIZATIONS_FETCH_DATA_SUCCESS,
} from './organizations';
import { SITES_FETCH_DATA_INIT, SITES_FETCH_DATA_SUCCESS } from './sites';
import {
  clearUsersDataLogout,
  USERS_FETCH_DATA_FAIL,
  USERS_FETCH_DATA_INIT,
  USERS_FETCH_DATA_SUCCESS,
} from './users';

import Cookies from 'js-cookie';
import { getRole } from 'utils/secrets';

export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');
export const AUTH_SIGN_IN_SUCCESS = createAction('AUTH_SIGN_IN_SUCCESS');
export const AUTH_GET_CONFIG_URI = createAction('AUTH_GET_CONFIG_URI');
export const AUTH_RESTORE_SESSION_INIT = createAction(
  'AUTH_RESTORE_SESSION_INIT'
);
export const AUTH_RESTORE_SESSION_SUCCESS = createAction(
  'AUTH_RESTORE_SESSION_SUCCESS'
);
export const AUTH_RESTORE_SESSION_FAIL = createAction(
  'AUTH_RESTORE_SESSION_FAIL'
);

export const AUTH_FETCH_INFORMATION_SUCCESS = createAction(
  'AUTH_FETCH_INFORMATION_SUCCESS'
);

export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction(
  'AUTH_SET_PASSWORD_SUCCESS'
);
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction(
  'AUTH_RESET_PASSWORD_INIT'
);
export const AUTH_RESET_PASSWORD_SUCCESS = createAction(
  'AUTH_RESET_PASSWORD_SUCCESS'
);
export const AUTH_RESET_PASSWORD_FAIL = createAction(
  'AUTH_RESET_PASSWORD_FAIL'
);

export const AUTH_READ_TOKEN_INIT = createAction('AUTH_READ_TOKEN_INIT');
export const AUTH_READ_TOKEN_SUCCESS = createAction('AUTH_READ_TOKEN_SUCCESS');
export const AUTH_READ_TOKEN_FAIL = createAction('AUTH_READ_TOKEN_FAIL');

export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');

export const AUTH_FETCH_INFORMATION_INIT = createAction(
  'AUTH_FETCH_INFORMATION_INIT'
);

export const AUTH_CHANGE_PASSWORD_INIT = createAction(
  'AUTH_CHANGE_PASSWORD_INIT'
);
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction(
  'AUTH_CHANGE_PASSWORD_SUCCESS'
);
export const AUTH_CHANGE_PASSWORD_FAIL = createAction(
  'AUTH_CHANGE_PASSWORD_FAIL'
);

export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');

export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');

export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');
export const SET_USER = createAction('SET_USER');

axios.defaults.withCredentials = true;

export const logout = () => {
  return async (dispatch, getState) => {
    dispatch(AUTH_LOGOUT_INIT());
    dispatch(clearUsersDataLogout());
    Cookies.remove('adsign')
    const id = getState().users.user.id; //localStorage.getItem('uid');
    try {
      await axios.post(
        `${url}/auth/logout`,
        {
          id,
        }
      );
    } catch (error) {
      log('error on logout', error);
    }
    dispatch(AUTH_LOGOUT_SUCCESS());
  };
};

export const fetchInformation = (id) => {
  return async (dispatch) => {
    dispatch(USERS_FETCH_DATA_INIT());
    // dispatch(ORGANIZATIONS_FETCH_DATA_INIT());
    // dispatch(SITES_FETCH_DATA_INIT());

    let user;
    let site;
    let organization;
    try {
      const token = await refreshToken();
      console.log(token, 'token refresh for get info')
      const res = await axios.post(
        `${url}/user/get`,
        {
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res, 'res fetch information')
      user = res.data.data;
      console.log(res.data.role, 'data role');
      user.roleAccess = res.data.role;
      site = res.data.site;
      organization = res.data.organization;
    } catch (error) {
      console.log(error, 'logout fetch information');
      dispatch(logout(id));
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    dispatch(ORGANIZATIONS_FETCH_DATA_SUCCESS({ list: organization }));
    dispatch(SITES_FETCH_DATA_SUCCESS({ list: site }));
    dispatch(USERS_FETCH_DATA_SUCCESS({ ...user }));

    return dispatch(AUTH_FETCH_INFORMATION_SUCCESS({ id, ...user }));
  };
};

export const auth = (email, password) => {
  return async (dispatch) => {
    dispatch(AUTH_SIGN_IN_INIT());
    let user;
    let ipAddress;
    try {
      ipAddress = await getClientIp();
    } catch (e) {
      log('error on getting ip', e);
    }
    try {
      const res = await axios.post(`${url}/auth/signin`, {
        email: email,
        password: password,
        ipAddress,
      }, {
        withCredentials: true
      });
      const role = await getRole()
      user = res.data;
      console.log(user, 'user');
      // Cookies.set('adsign', res.data.refreshToken);
      // const setCookieHeader = res.headers['set-cookie'];

      // You can now store the cookie or use it for subsequent requests
      // console.log('Cookie received:', setCookieHeader);
      // console.log('on success', res.data.refreshToken)
      if((user.roleMfaEnabled && !user.mfaEnabled)||(role === 'superadmin' && !user.mfaEnabled))dispatch(getConfigUri(user.id))

      if(role !== 'superadmin' && !user.roleMfaEnabled) dispatch(fetchInformation(user.id))
      return dispatch(AUTH_SIGN_IN_SUCCESS({ ...user, role: role }));
    } catch (error) {
      log('error on authentication ', error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.errors;
      }
      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }
  };
};

export const mfa = (otp) => {
  return async (dispatch) => {
    try {
      const token = await refreshToken();
      const res = await axios.post(
        `${url}/auth/verify-otp`,
        {
          code: otp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return dispatch(fetchInformation(res.data.id));
    } catch (error) {
      log(error.response.data.message, 'mfa error');
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : 'Invalid OTP';
      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }
  };
};

export const getConfigUri = (id) => {
  return async (dispatch) => {
    try {
      const token = await refreshToken();
      const res = await axios.post(
        `${url}/auth/mfa-qr`,
        {
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      log(res.data.configUri);
      return dispatch(AUTH_GET_CONFIG_URI({ configUri: res.data.configUri }));
    } catch (error) {
      log(error, 'mfa error');
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : 'Invalid OTP';
      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }
  };
};

export const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());

export const changeUserPassword = (currentPassword, newPassword) => {
  return async (dispatch) => {
    dispatch(AUTH_CHANGE_PASSWORD_INIT());

    try {
      const token = await refreshToken();
      await axios.put(
        `${url}/auth/change`,
        {
          oldPassword: currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      const errorMessage =
        error && error.response && error.response.data.message;
      toastr.error('', errorMessage);
      return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
    }

    toastr.success('', 'Password changed successfully');
    return dispatch(AUTH_CHANGE_PASSWORD_SUCCESS());
  };
};

export const resetUserPassword = (password, password2, email) => {
  return async (dispatch) => {
    dispatch(AUTH_RESET_PASSWORD_INIT());
    try {
      const token = await refreshToken();
      await axios.put(
        `${url}/auth/reset-password`,
        {
          password,
          password2,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      log(error, '<<< ERROR RESPONSE');
      const errorMessage =
        error && error.response && error.response.data.message;
      toastr.error('', errorMessage);
      return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
    }

    toastr.success('', 'Password reset successfully');
    return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
  };
};

export const sendTokenViaEmail = (email) => {
  log(email, '<<< EMAIL TO SEND');
  return async (dispatch) => {
    dispatch(AUTH_RESET_PASSWORD_INIT());
    try {
      const token = await refreshToken();
      await axios.post(
        `${url}/auth/reset-password`,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      log(error.response, '<<< ERROR RESPONSE');
      const errorMessage =
        error && error.response && error.response.data.message;
      toastr.error('', errorMessage);
      return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
    }

    toastr.success('', 'Password reset successfully');
    return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
  };
};

export const readTokenFromEmail = (token) => {
  return async (dispatch) => {
    dispatch(AUTH_READ_TOKEN_INIT());
    let email;
    try {
      const token = await refreshToken();
      const res = await axios.get(
        `${url}/auth/reset-password`,
        {
          params: {
            token,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      email = res.data.email;
    } catch (error) {
      log(error.response, '<<< ERROR RESPONSE');
      const errorMessage =
        error && error.response && error.response.data.message;
      toastr.error('', errorMessage);
      return dispatch(AUTH_READ_TOKEN_FAIL({ error: errorMessage }));
    }

    log(email, '<<<< read token email');

    toastr.success('', 'Password reset successfully');
    return dispatch(AUTH_READ_TOKEN_SUCCESS({ email }));
  };
};

// added by me
export const setUser = (data) => {
  return (dispatch) => {
    dispatch(SET_USER({ data }));
  };
};
