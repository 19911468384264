import { createReducer } from 'redux-act';
import {
  FETCH_SCREEN_USER_INIT,
  FETCH_SCREEN_USER_SUCCESS,
  FETCH_SCREEN_USER_FAIL,
} from 'state/actions/screenusers';

const initialState = {
  list: [],
  screenuser: {
    name: '',
    email: '',
    location: '',
    isAdmin: false,
    role: '',
    file: null,
    createdAt: new Date().toDateString(),
    password: '',
    organizationId: '',
    organizationName: '',
  },
  loading: false,
  error: null,
};

export const screenusersReducer = createReducer(
  {
    [FETCH_SCREEN_USER_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [FETCH_SCREEN_USER_SUCCESS]: (state, payload) => {
      return {
        ...state,
        list: payload.list || [],
        loading: false,
        error: null,
      };
    },
    [FETCH_SCREEN_USER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
