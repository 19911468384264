import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg, refreshToken } from 'utils';
import { url } from 'utils/url';

export const FETCH_SCREEN_USER_INIT = createAction('FETCH_SCREEN_USER_INIT');
export const FETCH_SCREEN_USER_SUCCESS = createAction(
  'FETCH_SCREEN_USER_SUCCESS'
);
export const FETCH_SCREEN_USER_FAIL = createAction('FETCH_SCREEN_USER_FAIL');

axios.defaults.withCredentials = true;

export const fetchScreenUser = () => {
  return async (dispatch, getState) => {
    dispatch(FETCH_SCREEN_USER_INIT());
    const token = await refreshToken();
    axios.get(`${url}/user/screens`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return dispatch(
          FETCH_SCREEN_USER_SUCCESS({
            list: res.data.data,
            user: getState().users.user,
          })
        );
      })
      .catch((err) => {
        toastr.error('', `${errorMsg(dispatch, err)}`);
        return dispatch(
          FETCH_SCREEN_USER_FAIL({
            error: err,
          })
        );
      });
  };
};
