import { addDays } from 'date-fns';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import { getAnalyticsReport } from '../../utils/queryReport';
import { ChartTitle, ChartWrapper, colors, ReportWrapper } from './styles';
import { formatDate } from './utils';
import { log } from 'utils';

const SearchPOIReport = (props) => {
  const INITIAL_STATE = {
    labels: [],
    datasets: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());
  // const [totalSources, setTotalSources] = useState(0);

  const transformAPIData = (data) => {
    let transformedData = [];
    let datesArray = [];
    data.map((row) => datesArray.push(formatDate(row.createdAt)));
    // const datesFormatted = datesArray;
    // const uniqueDates = [...new Set(datesFormatted)];
    // var value =
    //   uniqueDates.map(uDate => {
    //     const obj = {};
    //     obj.date = uDate
    //     obj.data = data.reduce(function (prev, cur) {
    //       const name = cur.name;
    //       const date = formatDate(cur.createdAt)
    //       if (uDate === date) {
    //         prev[name] = (prev[name] || 0) + 1 * cur.value;
    //       }
    //       return prev;
    //     }, {})
    //     Object.entries(obj.data).map(row => {
    //       // log(row)
    //       transformedData.push({
    //         date: uDate,
    //         source: row[0],
    //         values: row[1],
    //       })
    //     })
    //     return obj
    //   })
    return [transformedData, datesArray];
  };

  const groupDataBySource = (data) => {
    return data.reduce((r, a) => {
      r[a.source] = r[a.source] || [];
      r[a.source].push(a);
      return r;
    }, Object.create(null));
  };

  const sortSourcesByTotalVisits = (data) => {
    let sumedVisits = [];
    log(data);
    for (let [key, value] of Object.entries(data)) {
      sumedVisits.push({
        source: key,
        // values: value[0].values
        values: value.map((x) => x.values),
      });
    }
    log(sumedVisits);
    return sumedVisits.sort((a, b) => b.visits - a.visits);
  };

  const createDataForChart = (datesArray, sumedVisits, groupedBySource) => {
    datesArray.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    const datesFormatted = datesArray.map((date) =>
      moment(date, 'YYYYMMDD').format('DD-MM-YYYY')
    );
    const uniqueDates = [...new Set(datesFormatted)];
    let datasetsArray = [];
    let i = 0;
    sumedVisits.forEach((item, id) => {
      const label = item.source;
      const backgroundColor = colors[i + 3];
      i++;
      let data = [];
      item.values.map((value) => data.push(value));
      data.push(item.values);
      datasetsArray.push({
        fill: false,
        borderColor: backgroundColor,
        label,
        backgroundColor,
        data,
      });
    });
    return { labels: uniqueDates, data: datasetsArray };
  };

  const displayResults = (response) => {
    const queryResult = response.data;
    if (!queryResult) {
      setReportData(INITIAL_STATE);
      return;
    }
    const data = transformAPIData(queryResult);
    let transformedData = data[0];
    let datesArray = data[1];

    const groupedBySource = groupDataBySource(transformedData);
    // setTotalSources(Object.keys(groupedBySource).length);

    const sumedVisits = sortSourcesByTotalVisits(groupedBySource);

    const dataForChart = createDataForChart(
      datesArray,
      sumedVisits,
      groupedBySource
    );

    log(dataForChart);

    setReportData({
      ...reportData,
      labels: dataForChart.labels,
      datasets: dataForChart.data,
    });
  };

  const options = {
    tooltips: {
      displayColors: true,
      callbacks: {
        mode: 'x',
      },
    },
    scales: {
      xAxes: [
        {
          stacked: false,
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
          type: 'linear',
        },
      ],
    },
    maintainAspectRatio: false,
    legend: { position: 'bottom' },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const data = {
    labels: reportData.labels,
    datasets: reportData.datasets,
  };

  useEffect(() => {
    const request = {
      siteId: props.siteId,
      startDate: startDate,
      endDate: endDate,
      title: 'screensavers',
    };
    const getData = async () => {
      try {
        const report = await getAnalyticsReport(request);
        displayResults(report.data);
      } catch (e) {
        console.error(e);
      }
    };
    getData();
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReportWrapper>
      <ChartTitle>Screensaver Report</ChartTitle>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{useFormatMessage('MediaForm.start')}</label>
        </div>
        <div className="field-body">
          <div className="field">
            <DatePicker
              name="start"
              selected={startDate}
              onChange={setStartDate}
            />
          </div>
        </div>
        <div className="field-label is-normal">
          <label className="label">{useFormatMessage('MediaForm.end')}</label>
        </div>
        <div className="field-body">
          <div className="field">
            <DatePicker name="end" selected={endDate} onChange={setEndDate} />
          </div>
        </div>
      </div>
      {reportData && (
        <ChartWrapper>
          <Line data={data} width={100} height={250} options={options} />
        </ChartWrapper>
      )}
    </ReportWrapper>
  );
};

export default SearchPOIReport;
