import React from 'react';

const BagIcon = ({ size = 14, color = '#000' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.13901 1.16699L3.02734 3.28449"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.86133 1.16699L10.973 3.28449"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16602 4.57878C1.16602 3.49961 1.74352 3.41211 2.46102 3.41211H11.5377C12.2552 3.41211 12.8327 3.49961 12.8327 4.57878C12.8327 5.83294 12.2552 5.74544 11.5377 5.74544H2.46102C1.74352 5.74544 1.16602 5.83294 1.16602 4.57878Z"
        stroke={color}
      />
      <path d="M5.69336 8.16699V10.2378" stroke={color} strokeLinecap="round" />
      <path d="M8.37695 8.16699V10.2378" stroke={color} strokeLinecap="round" />
      <path
        d="M2.04102 5.83301L2.86352 10.873C3.05018 12.0047 3.49935 12.833 5.16768 12.833H8.68518C10.4994 12.833 10.7677 12.0397 10.9777 10.943L11.9577 5.83301"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BagIcon;
