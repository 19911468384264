import { createReducer } from 'redux-act';

import {
  PANEL_FETCH_DATA_INIT,
  PANEL_FETCH_DATA_SUCCESS,
  PANEL_FETCH_DATA_FAIL,
  PANEL_MODIFY_PANEL_INIT,
  PANEL_MODIFY_PANEL_SUCCESS,
  PANEL_MODIFY_PANEL_FAIL,
} from 'state/actions/panel';

const initialState = {
  data: {

  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false
};

export const panelReducer = createReducer(
  {
    [PANEL_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
      updated: false,
    }),
    [PANEL_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data || {},
      loading: false,
      error: null,
    }),
    [PANEL_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    
    [PANEL_MODIFY_PANEL_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PANEL_MODIFY_PANEL_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
      updated: true,
      message: payload.message,
    }),
    [PANEL_MODIFY_PANEL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
