import { Table, Tooltip, Tag } from 'antd';
import ModalOrganizationForm from 'components/ModalOrganizationForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import DataCard from '../../design-components/DataCard';
import { log } from 'utils';
import {
  createOrganization,
  deleteOrganization,
  fetchOrganizations,
  modifyOrganization,
} from 'state/actions/organizations';
import { EditGrey, TrashGrey } from 'assets/icon';
import { fetchSites } from 'state/actions/sites';
import { regexInput } from 'utils/regex';
import ModalDeleteConfirmation from 'components/ModalDeleteConfirmation';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import _ from 'underscore';
import PageHeader from 'design-components/PageHeader';
import { getRole } from 'utils/secrets';
import './Organizations.scss';


const Organizations = () => {
  const {
    loading,
    siteLoading,
    deleted,
    siteList,
    organizationsList,
    userData,
    updated,
  } = useSelector(
    (state) => ({
      loading: state.organizations.loading,
      siteLoading: state.sites.loading,
      deleted: state.organizations.deleted,
      siteList: state.sites.list,
      organizationsList: state.organizations.list,
      userData: state.auth.userData,
      updated: state.organizations.updated,
    }),
    shallowEqual
  );

  const defaultOrganization = {
    name: '',
    description: '',
    createdAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    updatedBy: userData.email ? userData.email : '',
  };

  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [organization, setOrganization] = useState(defaultOrganization);
  const [isGridSelected, setIsGridSelected] = useState(false);

  const [deleteModal, setDeleteModal] = useState({
    organizationId: '',
    organizationName: '',
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

 const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };


  useEffect(() => {
    if (role && role === 'superadmin') {
      dispatch(fetchOrganizations());
      dispatch(fetchSites());
    }
  }, [dispatch, role]);
  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchOrganizations());
      dispatch(fetchSites());
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updated && !loading) {
      dispatch(fetchOrganizations());
      dispatch(fetchSites());
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(setBreadcrumb([{ menu: 'Organization', link: 'organizations' }]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const redirect = error && dispatch(logout()) && <Redirect to={paths.LOGIN} />;

  const handleGridMenu = () => {
    setIsGridSelected(true);
  };

  const handleListMenu = () => {
    setIsGridSelected(false);
  };

  const onRemoveButtonClickHandler = (organizationId, organizationName) => {
    setDeleteModal((prevState) => ({
      organizationId,
      organizationName,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ organizationId: null, isOpen: false });
  };

  const editAction = (record) => {
    setOrganization({ ...record, id: record._id });
    setVisible(true);
    setIsEditing(true);
  };

  const onDeleteOrganizationHandler = () => {
    dispatch(deleteOrganization(deleteModal.organizationId));
  };

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const columns = [
    {
      title: useFormatMessage('Organizations.name'),
      dataIndex: 'name',
      render: (text) => <div className="table-name">{text.length > 19 ? text.substr(0, 20)+"..." : text}</div>,
    },
    {
      title: useFormatMessage('Organizations.description'),
      dataIndex: 'description',
      width: '30%',
      render: (text) => <div className="table-desc">{text}</div>,
    },
    {
      title: 'SITE',
      dataIndex: 'site',
      render: (site) => <div>{site ? site.length : 0}</div>,
    },
    {
      title: useFormatMessage('Organizations.modifiedDate').toUpperCase(),
      dataIndex: 'modifiedDate',
      render: (text, record) => {
        if (record.modifiedAt) {
          return (
            <Tag className="table-date">
              <div>{`${moment(record.modifiedAt)
                .utcOffset('+08:00')
                .format('h:mm A, MMM Do, YYYY')}`}</div>
            </Tag>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: useFormatMessage('Organizations.modifiedBy').toUpperCase(),
      dataIndex: 'modifiedBy',
    },

    {
      title: 'ACTION',
      dataIndex: 'option',
      render: (text, record) => {
        return (
          <div className="sites__actions">
            <Tooltip title="Edit Organization">
              <button
                onClick={() => {
                  editAction(record);
                }}
                data-tooltip="Edit"
                className="btn btn--edit"
              >
                <EditGrey /> <span className='btn-text'>Edit</span>
              </button>
            </Tooltip>

            <Tooltip title="Delete Organization">
              <button
                type="button"
                onClick={() =>
                  onRemoveButtonClickHandler(record._id, record.name)
                }
                data-tooltip="Delete"
                className="btn btn--delete"
              >
                <TrashGrey /> <span className='btn-text'>Delete</span>
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onCreate = (values) => {
    const action = isEditing ? modifyOrganization : createOrganization;
    dispatch(action(values));
  };

  const onNewOrganization = () => {
    log('handle new site');
    setOrganization(defaultOrganization);
    setIsEditing(false);
    setVisible(true);
  };

  const handleOrganizationSites = () => {
    organizationsList &&
      siteList &&
      _.sortBy(organizationsList, (i) => i.name.toLowerCase()).map((org) => {
        org.site = [];
        siteList.map((site) => {
          if (site.organizationId === org._id) {
            org.site.push(site.name);
          }
          return site;
        });
        return org;
      });
    return true;
  };

  const data = search
    ? _.sortBy(organizationsList, (i) => i.name.toLowerCase()).filter((el) => {
      const clonedElem = { ...el };
      delete clonedElem.id;
      return clonedElem.name.toLowerCase().includes(search.toLowerCase());
    })
    : _.sortBy(organizationsList, (i) => i.name.toLowerCase()).filter((el) => {
      if (role && role === 'superadmin') {
        return el;
      } else {
        return el.name === userData.organizationName;
      }
    });

  // const deleteMessage = useFormatMessage('Organizations.delete');

  const permDeleteMessage = useFormatMessage('Organizations.permDelete');

  // const cancelMessage = useFormatMessage('Organizations.cancel');

  return (
    <section className="organizations">
      <PageHeader
        title="Organization"
        isGrid={true}
        isGridSelected={isGridSelected}
        gridAction={handleGridMenu}
        listAction={handleListMenu}
        isSearch={true}
        searchValue={search}
        searchAction={handleSearch}
        searchPlaceholder="Enter Organization's Name"
        searchError={error}
        isAdd={true}
        addAction={onNewOrganization}
        addLabel="Add Organization"
      />
      {deleteModal.isOpen && (
        <ModalDeleteConfirmation
          title={'Delete Organization'}
          onCloseHandler={onCloseModalHandler}
          onDeleteHandler={onDeleteOrganizationHandler}
          description={permDeleteMessage}
          visible={deleteModal.isOpen}
        />
      )}
      {visible && (
        <ModalOrganizationForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          organization={organization}
          setOrganization={setOrganization}
          isEditing={isEditing}
        />
      )}
      {isGridSelected ? (
        handleOrganizationSites() && (
          <DataCard
            data={data}
            editAction={editAction}
            removeAction={(id, name) => onRemoveButtonClickHandler(id, name)}
          ></DataCard>
        )
      ) : (
        <div className="organizations__table">
          {loading && siteLoading ? (
            <ClipLoader />
          ) : (
            <>
              {handleOrganizationSites() && (
                <Table columns={columns} dataSource={data} rowKey="_id" />
              )}
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default Organizations;
