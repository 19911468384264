/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import paths from 'pages/Router/paths';
import { useChangeHandler, useFormatMessage } from 'hooks';
import './OrganizationForm.scss';

const OrganizationForm = ({ organization, setOrganization, action, user, history, success, error }) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.organizations.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (success) {
      history.push('/organizations')
    }
  }, [success]) // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const onChangeHandler = useChangeHandler(setOrganization);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(
      action({
        ...organization,
        modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
        modifiedBy: user.email,
      })
    );
  };

  const goBackMessage = useFormatMessage('OrganizationForm.goBack');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-menu default" />
                </span>
                {useFormatMessage('OrganizationForm.organizationInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={onSubmitHandler}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('OrganizationForm.name')} <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="name"
                          className={`input ${error && error.errors && error.errors.name
                            ? 'is-danger'
                            : ''
                            }`}
                          type="text"
                          required
                          name="name"
                          value={organization.name}
                          onChange={onChangeHandler}
                        />
                      </div>
                      {error && error.errors && error.errors.name && (
                        <p className="help is-danger">
                          {error.errors.name[0].msg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('OrganizationForm.description')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          style={{
                            height: 100,
                          }}
                          id="description"
                          className="input"
                          type="text"
                          required
                          name="description"
                          value={organization.description}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="button"
                            onClick={onSubmitHandler}
                            className={`button is-primary ${loading && 'is-loading'
                              }`}
                          // disabled={!canSubmit}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.ORGANIZATIONS} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

OrganizationForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  action: PropTypes.func.isRequired,
};

export default OrganizationForm;
