import React from 'react';
import './style.css';

const DashboardHeader = ({
  id,
  title,
  clickAction,
  isActive,
}) => {

  return (
    <div className='dashboard-menu-header' onClick={() => clickAction(id)}>
      <h1 className="title">{title.length > 30 ? `${title.substring(0, 30)}...` : title}</h1>
      <i
        className={`${isActive ? "feather-chevron-up" : "feather-chevron-down"}`}
      />
    </div>
  );
};

export default DashboardHeader;
