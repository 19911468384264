import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { doOpenChildren } from 'state/actions/menu';
import './style.css';

const SidebarMenu = ({
  title,
  activeIcon,
  icon,
  parentName,
  isDropdown = true,
  to,
  clickAction,
  children,
}) => {
  const { openChildren, openParent, openMenu } = useSelector(
    (state) => ({
      openChildren: state.menu.openChildren,
      openParent: state.menu.openParent,
      openMenu: state.menu.openMenu,
    }),
    shallowEqual
  );

  const [isHover, setIsHover] = useState(false);

  const dispatch = useDispatch();

  const handleOpenChildren = (id) => {
    const newChildren = [...openChildren];
    const findChildren = newChildren.find((child) => child._id === id);
    if (findChildren) {
      findChildren.open = !findChildren.open;
      dispatch(doOpenChildren(newChildren));
    } else {
      dispatch(
        doOpenChildren([
          ...openChildren,
          {
            _id: id,
            open: true,
          },
        ])
      );
    }
  };

  const findChildrenOpen = (id) => {
    const findChildren = openChildren.find((x) => x._id === id);
    if (findChildren) {
      return findChildren.open;
    } else {
      return false;
    }
  };

  return (
    <li
      className={`${
        isDropdown
          ? `sub-has-children ${openParent === parentName ? 'active' : ''}`
          : `${openMenu === parentName ? 'active' : ''}`
      }`}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onFocus={() => {}}
    >
      {isDropdown ? (
        <div className="submenu-has-children">
          <div
            className="submenu-children"
            onClick={() => handleOpenChildren(parentName)}
          >
            <div>
              {isHover || findChildrenOpen(parentName) ? (
                <> {activeIcon}</>
              ) : (
                <> {icon}</>
              )}
              <div>{title}</div>
            </div>
            <i
              className={`feather-chevron-down ${
                findChildrenOpen(parentName)
                  ? 'icon-chevron-open'
                  : 'icon-chevron-close'
              }`}
            />
          </div>
          <ul
            className={`submenu-sidebar-children ${
              findChildrenOpen(parentName)
                ? 'submenu-sidebar-children-open'
                : 'submenu-sidebar-children-close'
            }`}
          >
            {children}
          </ul>
        </div>
      ) : (
        <Link to={to} onClick={clickAction}>
          {isHover || openMenu === parentName ? (
            <> {activeIcon}</>
          ) : (
            <> {icon}</>
          )}
          <div>{title}</div>
        </Link>
      )}
    </li>
  );
};

export default SidebarMenu;
