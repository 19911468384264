import { Modal } from 'antd';
import { DeleteIcon } from 'assets/icon/bigicon';
import React from 'react';

function ModalDelete({ isVisible, title, subtitle, onOk, onCancel, loading }) {
  return (
    <Modal
      visible={isVisible}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      className="adsign-modal"
      footer={null}
      closeIcon={<i className="feather-x" />}
      width={450}
    >
      <div className="modal-content-delete">
        <img src={DeleteIcon} alt="delete-icon" />
        <p className="modal-content-title">Delete Confirmation</p>
        <p className="modal-content-description">
          Are you sure want to delete this data? Deleted data cannot be
          restored, and will be destroyed permanently.
        </p>
        {subtitle && <p className="modal-content-subtitle">{subtitle}</p>}
      </div>
      <div className="modal-footer-delete">
        {!loading && (
          <button className="button-cancel" onClick={onCancel}>
            Cancel
          </button>
        )}
        {loading ? (
          <button className="button-delete is-loading">
            <i className="mdi mdi-loading mdi-spin" /> Loading...
          </button>
        ) : (
          <button className="button-delete" onClick={onOk}>
            Delete
          </button>
        )}
      </div>
    </Modal>
  );
}

export default ModalDelete;
