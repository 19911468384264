import { Tooltip } from 'antd';
import React from 'react';
import './style.css';


const CardInfo = ({
  title,
}) => {
  return (
    <h4>
      {title && title.length > 20 ? (
        <Tooltip
          title={title}
        >{`${title.substr(0, 20)}...`}</Tooltip>
      ) : (
        title
      )}
    </h4>
  );
};

export default CardInfo;
