export const userImage = `<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10pt" height="10pt" viewBox="0 0 10 10" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(81.176471%,94.117647%,61.960784%);fill-opacity:1;" d="M 14.148438 7.5 C 14.148438 9.792969 12.292969 11.648438 10 11.648438 C 7.707031 11.648438 5.851562 9.792969 5.851562 7.5 C 5.851562 5.207031 7.707031 3.347656 10 3.347656 C 12.292969 3.347656 14.148438 5.207031 14.148438 7.5 Z M 14.148438 7.5 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(31.372549%,48.627451%,36.078431%);fill-opacity:1;" d="M 10 12.210938 C 7.402344 12.210938 5.289062 10.097656 5.289062 7.5 C 5.289062 4.902344 7.402344 2.789062 10 2.789062 C 12.597656 2.789062 14.710938 4.902344 14.710938 7.5 C 14.710938 10.097656 12.597656 12.210938 10 12.210938 Z M 10 3.910156 C 8.019531 3.910156 6.410156 5.519531 6.410156 7.5 C 6.410156 9.480469 8.019531 11.089844 10 11.089844 C 11.980469 11.089844 13.589844 9.480469 13.589844 7.5 C 13.589844 5.519531 11.980469 3.910156 10 3.910156 Z M 10 3.910156 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(31.372549%,48.627451%,36.078431%);fill-opacity:1;" d="M 10 20 C 9.863281 20 9.726562 19.949219 9.621094 19.851562 C 9.546875 19.785156 7.835938 18.203125 6.09375 15.898438 C 3.726562 12.761719 2.527344 9.925781 2.527344 7.472656 C 2.527344 6.304688 2.789062 5.183594 3.308594 4.140625 C 3.445312 3.863281 3.785156 3.75 4.058594 3.890625 C 4.335938 4.027344 4.449219 4.363281 4.3125 4.640625 C 3.871094 5.527344 3.648438 6.480469 3.648438 7.472656 C 3.648438 12.035156 8.578125 17.253906 10 18.660156 C 10.433594 18.238281 11.183594 17.464844 12.015625 16.476562 C 12.214844 16.238281 12.570312 16.207031 12.808594 16.40625 C 13.042969 16.605469 13.074219 16.960938 12.875 17.199219 C 11.535156 18.792969 10.425781 19.808594 10.378906 19.851562 C 10.273438 19.949219 10.136719 20 10 20 Z M 10 20 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(31.372549%,48.627451%,36.078431%);fill-opacity:1;" d="M 14.304688 14.945312 C 14.195312 14.945312 14.085938 14.914062 13.992188 14.851562 C 13.734375 14.675781 13.667969 14.328125 13.839844 14.070312 C 15.507812 11.597656 16.351562 9.378906 16.351562 7.476562 C 16.351562 3.972656 13.503906 1.121094 10 1.121094 C 8.636719 1.121094 7.339844 1.546875 6.242188 2.351562 C 5.992188 2.535156 5.644531 2.480469 5.460938 2.230469 C 5.277344 1.980469 5.328125 1.628906 5.578125 1.445312 C 6.867188 0.5 8.398438 0 10 0 C 14.121094 0 17.472656 3.351562 17.472656 7.472656 C 17.472656 9.605469 16.566406 12.035156 14.769531 14.699219 C 14.660156 14.859375 14.484375 14.945312 14.304688 14.945312 Z M 14.304688 14.945312 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(31.372549%,48.627451%,36.078431%);fill-opacity:1;" d="M 9.695312 9.183594 C 9.550781 9.183594 9.410156 9.128906 9.300781 9.019531 L 7.777344 7.496094 C 7.558594 7.277344 7.558594 6.921875 7.777344 6.703125 C 7.996094 6.484375 8.351562 6.484375 8.570312 6.703125 L 9.695312 7.828125 L 11.429688 6.09375 C 11.648438 5.875 12.003906 5.875 12.222656 6.09375 C 12.441406 6.3125 12.441406 6.667969 12.222656 6.886719 L 10.09375 9.019531 C 9.984375 9.128906 9.839844 9.183594 9.695312 9.183594 Z M 9.695312 9.183594 "/>
</g>
</svg>
`;

export const MEDIA = "media";
export const TENANT = "tenant";
export const INFORMATION = "information";
export const SCREEN = "screen";
