import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  STATIC_MAPS_FETCH_DATA_INIT,
  STATIC_MAPS_FETCH_DATA_SUCCESS,
  STATIC_MAPS_FETCH_DATA_FAIL,
  // STATIC_MAPS_DELETE_MAPS_INIT,
  // STATIC_MAPS_DELETE_MAPS_SUCCESS,
  // STATIC_MAPS_DELETE_MAPS_FAIL,
  STATIC_MAPS_CLEAR_DATA,
  STATIC_MAPS_CREATE_MAPS_INIT,
  STATIC_MAPS_CREATE_MAPS_SUCCESS,
  STATIC_MAPS_CREATE_MAPS_FAIL,
  STATIC_MAPS_MODIFY_MAPS_INIT,
  STATIC_MAPS_MODIFY_MAPS_SUCCESS,
  STATIC_MAPS_MODIFY_MAPS_FAIL,
  STATIC_MAPS_CLEAN_UP,
  STATIC_MAPS_CLEAR_DATA_LOGOUT,
} from 'state/actions/mapsStatic';

const initialState = {
  map: {
    name: '',
    images: [],
    data: null,
    siteId: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const mapsStaticReducer = createReducer(
  {
    [STATIC_MAPS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [STATIC_MAPS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      map: payload.map || initialState.map,
      loading: false,
      error: null,
    }),
    [STATIC_MAPS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [STATIC_MAPS_CLEAR_DATA]: (state) => ({
      ...initialState,
      map: state.map,
    }),
    [STATIC_MAPS_CREATE_MAPS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [STATIC_MAPS_CREATE_MAPS_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [STATIC_MAPS_CREATE_MAPS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [STATIC_MAPS_MODIFY_MAPS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [STATIC_MAPS_MODIFY_MAPS_SUCCESS]: (state, payload) => ({
      ...state,
      map: {
        id: payload.id,
        name: payload.staticMaps.name,
        images: payload.staticMaps.images,
        data: payload.staticMaps.data,
        siteId: payload.staticMaps.siteId,
        modifiedAt: new Date().toDateString(),
        modifiedBy: payload.staticMaps.modifiedBy,
      },
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [STATIC_MAPS_MODIFY_MAPS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [STATIC_MAPS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [STATIC_MAPS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
