/* eslint-disable jsx-a11y/label-has-associated-control */
import { Breadcrumb, Input } from 'antd';
import { useChangeHandler, useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeUserPassword } from 'state/actions/auth';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import './ChangePassword.scss';

const ChangePassword = () => {
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirmation: '',
  });

  const onChangeHandler = useChangeHandler(setPasswords);

  const { loading, changedPassword } = useSelector(
    (state) => ({
      loading: state.auth.loading,
      changedPassword: state.auth.changedPassword,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    current: '',
    new: '',
    confirmation: '',
  });

  const dispatch = useDispatch();

  // useEffect(() => {
  //   return () => dispatch(authCleanUp());
  // }, [dispatch]);
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { menu: 'Profile', link: '/profile' },
        { menu: 'Change Password', link: '/change-password' },
      ])
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (changedPassword) {
      setPasswords({
        current: '',
        new: '',
        confirmation: '',
      });
    }
  }, [changedPassword, setPasswords]);

  let inputs = {
    new: {
      modifier: null,
      message: { modifier: null, content: null },
    },
    confirmation: {
      modifier: null,
      message: { modifier: null, content: null },
    },
  };

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  // const isNewPasswordSecure =
  //   passwords.new && passwords.new.length >= 6 && validatePassword(passwords);

  // const safePasswordMessage = useFormatMessage(`ChangePassword.safePassword`);

  // const insecurePasswordMessage = useFormatMessage(
  //   `ChangePassword.insecurePassword`
  // );

  const newPasswordsAreEqual =
    passwords.new &&
    passwords.confirmation &&
    passwords.new === passwords.confirmation;

  const passwordsMatchMessagge = useFormatMessage(
    `ChangePassword.matchPassword`
  );

  const notMatchPasswordMessage = useFormatMessage(
    `ChangePassword.notMatchPassword`
  );

  if (newPasswordsAreEqual) {
    setInputs('confirmation', {
      modifier: 'is-success',
      message: {
        modifier: 'is-success',
        content: passwordsMatchMessagge,
      },
    });
  } else if (passwords.confirmation) {
    setInputs('confirmation', {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: notMatchPasswordMessage,
      },
    });
  }

  const currentAndNewPasswordsEqual =
    passwords.new && passwords.current === passwords.new;

  const samePasswordMessage = useFormatMessage(`ChangePassword.samePassword`);

  const errorMessage = currentAndNewPasswordsEqual && samePasswordMessage;

  // const canSubmit =
  //   isNewPasswordSecure && newPasswordsAreEqual && !currentAndNewPasswordsEqual;

  const onSubmitHandler = (event) => {
    event.preventDefault();

    const lowercase = passwords.new.match(/[a-z]/g) ? true : false;
    const uppercase = passwords.new.match(/[A-Z]/g) ? true : false;
    const number = passwords.new.match(/[0-9]/g) ? true : false;
    const length = passwords.new.length < 12;
    const special = passwords.new.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g)
      ? true
      : false;

    if (
      length ||
      !lowercase ||
      !uppercase ||
      !number ||
      !special ||
      passwords.new !== passwords.confirmation
    ) {
      const newErrors = {
        ...errors,
      };

      if (length) {
        newErrors.new = 'Minimum 12 Characters';
      }

      if (!lowercase) {
        newErrors.new = 'Must be contain lowercase';
      }

      if (!uppercase) {
        newErrors.new = 'Must be contain uppercase';
      }

      if (!number) {
        newErrors.new = 'Must be contain number';
      }

      if (!special) {
        newErrors.new = 'Must be contain special character';
      }

      if (passwords.new !== passwords.confirmation) {
        newErrors.confirmation = 'Password not match';
      }

      setErrors(newErrors);
    } else {
      setErrors({
        current: '',
        new: '',
        confirmation: '',
      });
      dispatch(changeUserPassword(passwords.current, passwords.confirmation));
    }
  };

  return (
    <section className="change-password">
      <div className="breadcrumb-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-active">
            Change Password
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="profile__title">
          <h1 className="page-title">Change Password</h1>
        </div>
      </div>

      <div className="profile__content">
        <div className="profile__left">
          <form className="change-password__form" onSubmit={onSubmitHandler}>
            <div className="change-password__form-controls">
              <label className="form-label change-password__label">
                {useFormatMessage(`ChangePassword.currentPassword`)}
              </label>
              <Input.Password
                type="password"
                required
                value={passwords.current}
                name="current"
                onChange={onChangeHandler}
                placeholder="*******"
              />
              {errors.current && (
                <div className="error-message">{errors.current}</div>
              )}
            </div>
            <div className="change-password__form-controls">
              <label className="form-label change-password__label">
                {useFormatMessage(`ChangePassword.newPassword`)}
              </label>
              <Input.Password
                type="password"
                required
                value={passwords.new}
                name="new"
                onChange={onChangeHandler}
                placeholder="*******"
              />
              {errors.new && <div className="error-message">{errors.new}</div>}
              {inputs.new.message.content ? (
                <p className={`help is-${inputs.new.message.modifier}`}>
                  {inputs.new.message.content}
                </p>
              ) : null}
            </div>
            <div className="change-password__message">
              <h3 className="change-password__message-title">
                Password must contain the following:
              </h3>
              <p
                className="change-password__message-invalid"
                style={{
                  color: passwords.new.match(/[a-z]/g)
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[a-z]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                A lowercase letter
              </p>
              <p
                className="change-password__message-invalid"
                style={{
                  color: passwords.new.match(/[A-Z]/g)
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[A-Z]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                A capital (uppercase) letter
              </p>
              <p
                className="change-password__message-invalid"
                style={{
                  color: passwords.new.match(/[0-9]/g)
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[0-9]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                A number
              </p>
              <p
                className="change-password__message-invalid"
                style={{
                  color: passwords.new.match(
                    /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g
                  )
                    ? 'green'
                    : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.match(/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?-]/g) ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                Special Character
              </p>
              <p
                className="change-password__message-invalid"
                style={{
                  color:
                    passwords.new.length >= 12
                      ? 'green'
                      : 'hsla(0, 79%, 63%, 1)',
                }}
              >
                {passwords.new.length >= 12 ? (
                  <i className="feather-check-circle"></i>
                ) : (
                  <i className="feather-x-circle"></i>
                )}
                Minimum 12 Characters
              </p>
            </div>
            <div className="change-password__form-controls">
              <label className="form-label change-password__label">
                {useFormatMessage(`ChangePassword.confirmPassword`)}
              </label>
              <Input.Password
                type="password"
                required
                value={passwords.confirmation}
                name="confirmation"
                onChange={onChangeHandler}
                placeholder="*******"
              />
              {errors.confirmation && (
                <div className="error-message">{errors.confirmation}</div>
              )}
            </div>

            {errorMessage && <p className="help is-danger">{errorMessage}</p>}

            <div className="change-password__submit">
              {loading ? (
                <button type="button" className="button-primary button-loading">
                  Loading...
                </button>
              ) : (
                <button type="submit" className="button-primary">
                  Save Changes
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
