import ProgrammeForm from 'components/ProgrammeForm';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchProgramme } from 'state/actions/programme';
import {
  createProgramme,
  modifyProgramme,
  setProgramme,
} from 'state/actions/programme';

const Programme = ({ history }) => {
  const { id, siteId, siteName } = useParams();
  const dispatch = useDispatch();

  const { programme, error, programmeList } = useSelector((state) => ({
    programme: state.programme.programme,
    error: state.programme.error,
    programmeList: state.programme.list,
  }));

  useEffect(() => {
    if (id) {
      const programmeFetched = programmeList.find(
        (fetchedProgramme) => fetchedProgramme._id === id
      );
      if (programmeFetched) {
        dispatch(setProgramme({ ...programme, ...programmeFetched }));
      } else if (programme._id === id) {
        dispatch(setProgramme(programme));
      } else {
        dispatch(fetchProgramme(id));
      }
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const editProgrammeMessage = 'Edit Programme';

  const newProgrammeMessage = 'Add New Programme';

  const isEditing = !!id;

  const programmeForm =
    !programme && id ? (
      <ClipLoader />
    ) : (
      <ProgrammeForm
        history={history}
        id={id}
        error={error}
        action={isEditing ? modifyProgramme : createProgramme}
      />
    );

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editProgrammeMessage : newProgrammeMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{programmeForm}</div>
    </div>
  );
};

export default Programme;
