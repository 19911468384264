import React from 'react';

function MessageIcon({ color = '#000000', size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 19.15H7C3.66 19.15 1.75 17.24 1.75 13.9V8.5C1.75 5.16 3.66 3.25 7 3.25H9.7C9.94 3.25 10.17 3.37006 10.31 3.56006C10.45 3.75006 10.49 4.00999 10.41 4.23999C10.3 4.54999 10.25 4.89 10.25 5.25V9.15002C10.25 9.91002 10.48 10.54 10.92 10.98C11.36 11.42 11.99 11.65 12.75 11.65C13.16 11.65 13.5 11.99 13.5 12.4V13.6L16.23 11.77C16.35 11.69 16.5 11.64 16.65 11.64H19.25C19.61 11.64 19.95 11.59 20.26 11.48C20.49 11.4 20.74 11.44 20.94 11.58C21.14 11.72 21.25 11.9499 21.25 12.1899V13.89C21.25 17.24 19.34 19.15 16 19.15ZM7 4.75C4.48 4.75 3.25 5.98 3.25 8.5V13.9C3.25 16.42 4.48 17.65 7 17.65H16C18.52 17.65 19.75 16.42 19.75 13.9V13.12C19.59 13.14 19.42 13.15 19.25 13.15H16.88L14.18 14.95C13.74 15.23 13.19 15.26 12.74 15.01C12.29 14.77 12.01 14.3 12.01 13.79V13.09C11.17 12.96 10.43 12.59 9.87 12.04C9.16 11.33 8.76 10.3 8.76 9.15002V5.25C8.76 5.08 8.77 4.91 8.78 4.75H7Z"
        fill={color}
      />
      <path
        d="M13.4 15.1801C13.17 15.1801 12.94 15.12 12.73 15.01C12.28 14.77 12 14.3 12 13.79V13.09C11.16 12.96 10.42 12.59 9.86 12.04C9.15 11.33 8.75 10.3 8.75 9.15002V5.25C8.75 4.72 8.83 4.22001 8.99 3.76001C9.55 2.19001 10.95 1.25 12.75 1.25H19.25C21.6 1.25 23.25 2.89 23.25 5.25V9.15002C23.25 10.95 22.32 12.35 20.75 12.91C20.27 13.07 19.77 13.15 19.25 13.15H16.88L14.18 14.95C13.94 15.11 13.67 15.1801 13.4 15.1801ZM12.75 2.75C11.6 2.75 10.75 3.3 10.41 4.25C10.31 4.55 10.25 4.89 10.25 5.25V9.15002C10.25 9.91002 10.48 10.54 10.92 10.98C11.36 11.42 11.99 11.65 12.75 11.65C13.16 11.65 13.5 11.99 13.5 12.4V13.6L16.23 11.77C16.35 11.69 16.5 11.64 16.65 11.64H19.25C19.61 11.64 19.95 11.59 20.26 11.48C21.21 11.14 21.75 10.29 21.75 9.14001V5.23999C21.75 3.69999 20.79 2.73999 19.25 2.73999H12.75V2.75Z"
        fill={color}
      />
      <path
        d="M15.0999 22.75H7.8999C7.4899 22.75 7.1499 22.41 7.1499 22C7.1499 21.59 7.4899 21.25 7.8999 21.25H15.0999C15.5099 21.25 15.8499 21.59 15.8499 22C15.8499 22.41 15.5099 22.75 15.0999 22.75Z"
        fill={color}
      />
      <path
        d="M11.5 22.7499C11.09 22.7499 10.75 22.4099 10.75 21.9999V18.3999C10.75 17.9899 11.09 17.6499 11.5 17.6499C11.91 17.6499 12.25 17.9899 12.25 18.3999V21.9999C12.25 22.4099 11.91 22.7499 11.5 22.7499Z"
        fill={color}
      />
      <path
        d="M15.9399 8C15.5199 8 15.1899 7.66 15.1899 7.25C15.1899 6.84 15.5299 6.5 15.9399 6.5C16.3499 6.5 16.6899 6.84 16.6899 7.25C16.6899 7.66 16.3499 8 15.9399 8Z"
        fill={color}
      />
      <path
        d="M18.75 8C18.33 8 18 7.66 18 7.25C18 6.84 18.34 6.5 18.75 6.5C19.16 6.5 19.5 6.84 19.5 7.25C19.5 7.66 19.16 8 18.75 8Z"
        fill={color}
      />
      <path
        d="M13.1201 8C12.7001 8 12.3701 7.66 12.3701 7.25C12.3701 6.84 12.7101 6.5 13.1201 6.5C13.5301 6.5 13.8701 6.84 13.8701 7.25C13.8701 7.66 13.5401 8 13.1201 8Z"
        fill={color}
      />
    </svg>
  );
}

export default MessageIcon;
