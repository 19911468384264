import React from 'react';
import './style.css';

const PageTitle = ({ children }) => {
  return (
    <div className={`${children === 'General Overview' ? "overview-title" : "page-title"}`}>{children}</div>
  );
};

export default PageTitle;
