import React from 'react';
import Lottie from 'react-lottie';
import './FullLayout.css';
import globe from '../../assets/animation/globe.json';
import loadingAnim from '../../assets/animation/loading.json';
import { shallowEqual, useSelector } from 'react-redux';

function FullLayout({ children }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: globe,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const { loading } = useSelector(
    (state) => ({
      loading: state.auth.loading,
    }),
    shallowEqual
  );
  

  return (
    <div className="full-layout">
      {loading && (
        <div className="full-layout-loading">
          <Lottie options={loadingOptions} speed={5} height={100} width={100} />
        </div>
      )}
      <Lottie
        options={defaultOptions}
        speed={0.5}
        height={'100vh'}
        width={'100vw'}
      />
      <div className="full-layout-container">{children}</div>
    </div>
  );
}

export default FullLayout;
