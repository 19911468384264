import { createReducer } from 'redux-act';

import {
  SETTINGS_FETCH_DATA_INIT,
  SETTINGS_FETCH_DATA_SUCCESS,
  SETTINGS_FETCH_DATA_FAIL,
  SETTINGS_CLEAR_DATA,
  SETTINGS_MODIFY_INIT,
  SETTINGS_MODIFY_SUCCESS,
  SETTINGS_MODIFY_FAIL,
  SETTINGS_CLEAN_UP,
  SETTINGS_CLEAR_DATA_LOGOUT,
} from 'state/actions/settings';

const initialState = {
  settings: {},
  loading: false,
  error: null,
  success: false,
};

export const settingsReducer = createReducer(
  {
    [SETTINGS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SETTINGS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      settings: payload.settings,
      loading: false,
      error: null,
    }),
    [SETTINGS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SETTINGS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [SETTINGS_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SETTINGS_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      settings: payload.settings,
      loading: false,
      error: null,
      success: true,
    }),
    [SETTINGS_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SETTINGS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [SETTINGS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
