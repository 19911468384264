import React from 'react';

const StickyNoteIcon = ({ color = '#000', size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29004 6.29C7.87004 6.29 7.54004 5.95 7.54004 5.54V2.75C7.54004 2.34 7.87004 2 8.29004 2C8.71004 2 9.04004 2.34 9.04004 2.75V5.53C9.04004 5.95 8.71004 6.29 8.29004 6.29Z"
        fill={color}
      />
      <path
        d="M15.71 6.29C15.29 6.29 14.96 5.95 14.96 5.54V2.75C14.96 2.33 15.3 2 15.71 2C16.13 2 16.46 2.34 16.46 2.75V5.53C16.46 5.95 16.13 6.29 15.71 6.29Z"
        fill={color}
      />
      <path
        d="M21.5004 15.8199C21.5004 15.9699 21.4504 16.1199 21.3204 16.2499C19.8704 17.7099 17.2904 20.3099 15.8104 21.7999C15.6804 21.9399 15.5104 21.9999 15.3404 21.9999C15.0104 21.9999 14.6904 21.7399 14.6904 21.3599V17.8599C14.6904 16.3999 15.9304 15.1899 17.4504 15.1899C18.4004 15.1799 19.7204 15.1799 20.8504 15.1799C21.2404 15.1799 21.5004 15.4899 21.5004 15.8199Z"
        fill={color}
      />
      <path
        d="M21.5004 15.8199C21.5004 15.9699 21.4504 16.1199 21.3204 16.2499C19.8704 17.7099 17.2904 20.3099 15.8104 21.7999C15.6804 21.9399 15.5104 21.9999 15.3404 21.9999C15.0104 21.9999 14.6904 21.7399 14.6904 21.3599V17.8599C14.6904 16.3999 15.9304 15.1899 17.4504 15.1899C18.4004 15.1799 19.7204 15.1799 20.8504 15.1799C21.2404 15.1799 21.5004 15.4899 21.5004 15.8199Z"
        fill={color}
      />
      <path
        d="M19.57 4.5C18.91 4.01 17.96 4.48 17.96 5.31V5.41C17.96 6.58 17.12 7.66 15.95 7.78C14.6 7.92 13.46 6.86 13.46 5.54V4.5C13.46 3.95 13.01 3.5 12.46 3.5H11.54C10.99 3.5 10.54 3.95 10.54 4.5V5.41C10.54 6.29 10.07 7.11 9.34 7.51C9.3 7.54 9.26 7.56 9.22 7.58C9.21 7.58 9.21 7.59 9.2 7.59C9.13 7.62 9.06 7.65 8.98 7.68C8.96 7.69 8.94 7.69 8.92 7.7C8.8 7.74 8.67 7.77 8.53 7.78H8.52C8.37 7.8 8.21 7.8 8.06 7.78H8.05C7.91 7.77 7.78 7.74 7.66 7.7C7.56 7.67 7.46 7.63 7.36 7.58C6.58 7.23 6.04 6.45 6.04 5.54V5.31C6.04 4.54 5.22 4.08 4.57 4.41C4.56 4.42 4.55 4.42 4.54 4.43H4.53C4.46 4.48 4.4 4.53 4.33 4.58C4.22 4.67 4.11 4.76 4.01 4.86C3.94 4.93 3.87 5 3.81 5.07C3.73 5.15 3.66 5.23 3.59 5.32C3.54 5.38 3.48 5.44 3.44 5.51C3.39 5.57 3.35 5.64 3.31 5.7C3.3 5.71 3.29 5.72 3.28 5.74C3.19 5.87 3.11 6.02 3.04 6.16C3.02 6.18 3.01 6.19 3.01 6.21C2.95 6.33 2.89 6.45 2.85 6.58C2.82 6.63 2.81 6.67 2.79 6.72C2.77 6.76 2.76 6.81 2.74 6.85C2.71 6.95 2.68 7.06 2.65 7.17C2.61 7.31 2.58 7.46 2.56 7.61C2.54 7.72 2.53 7.83 2.52 7.95C2.51 8.09 2.5 8.23 2.5 8.37V17.13C2.5 19.82 4.68 22 7.37 22H12.19C12.74 22 13.19 21.55 13.19 21V17.86C13.19 15.56 15.1 13.69 17.45 13.69C17.98 13.68 19.27 13.68 20.5 13.68C21.05 13.68 21.5 13.23 21.5 12.68V8.37C21.5 6.78 20.74 5.39 19.57 4.5ZM11.13 15.91C11.04 16.21 10.76 16.42 10.43 16.42H6.79C6.74 16.42 6.7 16.42 6.66 16.4C6.3 16.35 6.04 16.04 6.04 15.67C6.04 15.25 6.37 14.91 6.79 14.91H10.43C10.84 14.91 11.18 15.25 11.18 15.67C11.18 15.75 11.17 15.84 11.13 15.91ZM13.91 12.2C13.82 12.5 13.54 12.71 13.21 12.71H6.79C6.74 12.71 6.7 12.71 6.66 12.69C6.3 12.64 6.04 12.33 6.04 11.96C6.04 11.54 6.37 11.2 6.79 11.2H13.21C13.63 11.2 13.96 11.54 13.96 11.96C13.96 12.04 13.95 12.13 13.91 12.2Z"
        fill={color}
      />
    </svg>
  );
};

export default StickyNoteIcon;
