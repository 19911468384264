import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getAccess, log } from 'utils';
import { deleteInformation, fetchInformation } from 'state/actions/information';
// import { regexInput } from 'utils/regex';
// import Table from 'components/Table';
import './Information.scss';
import _ from 'underscore';
import { EmptyIcon } from 'assets/icon/bigicon';
import { FilterIcon } from 'assets/icon';
import ModalInformationCategoryForm from 'components/ModalInformationCategoryForm';
import {
  createInformationCategory,
  deleteInformationCategory,
  fetchInformationCategories,
  modifyInformationCategory,
  setInformationCategory,
} from 'state/actions/informationCategories';
import ModalDelete from 'components/ModalDelete';
import Cards from 'design-components/Cards';
import CategoryDropdown from 'components/CategoryDropdown';
import { getRole } from 'utils/secrets';

const Information = ({ history }) => {
  const { siteId, siteName } = useParams();

  const {
    informationList,
    informationCategories,
    loading,
    userData,
    updated,
    category,
    categoryLoading,
  } = useSelector(
    (state) => ({
      informationList: state.information.list,
      informationCategories: state.informationCategories.list,
      error: state.information.error,
      loading: state.information.loading,
      deleted: state.information.deleted,
      userData: state.auth.userData,
      updated: state.information.updated,
      category: state.informationCategories.category,
      categoryLoading: state.informationCategories.loading,
      informationData: state.information.information,
    }),
    shallowEqual
  );

  if (informationList) {
    log(informationList, '<<< infor list');
  }

  const [deleteModal, setDeleteModal] = useState({
    information: null,
    isOpen: false,
  });
  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

 const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };


  const dispatch = useDispatch();

  const [search, setSearch] = useState();
  // const [isEditing, setIsEditing] = useState(false);
  // const [visible, setVisible] = useState(false);
  // const [searchError, setSearchError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [tempSelectedCategory, setTempSelectedCategory] = useState(
    selectedCategory
  );
  const [playlistOpen, setPlaylistOpen] = useState(false);
  // const [minValue, setMinValue] = useState(0);
  // const [maxValue, setMaxValue] = useState(16);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalDeleteCategory, setModalDeleteCategory] = useState({
    show: false,
    category: null,
  });

  const [access, setAccess] = useState(null);

  const uid = userData.id;

  useEffect(() => {
    dispatch(fetchInformation({ siteId }));
    dispatch(fetchInformationCategories({ siteId }));
    setSelectedCategory('all');
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchInformation({ siteId }));
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData && userData.roleAccess) {
      setAccess(getAccess(userData, 'information', siteId));
    }
  }, [userData, siteId]);

  const onRemoveButtonClickHandler = (information) => {
    setDeleteModal({
      information,
      isOpen: true,
    });
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ information: null, isOpen: false });
  };

  const onDeleteInformationHandler = () => {
    dispatch(
      deleteInformation({ id: deleteModal.information, siteId }, () => {
        onCloseModalHandler();
      })
    );
  };

  // const onNewInformationHandler = () => {
  //   setInformation(defaultInformation);
  //   setIsEditing(false);
  //   setVisible(true);
  // };

  const handleSearch = (e) => {
    // setSearchError(null);
    // if (!regexInput(e.target.value) && e.target.value) {
    //   setSearchError('Search cannot using special character');
    // }
    setSearch(e.target.value);
  };

  const dataByCategory = informationList.filter((information) => {
    return selectedCategory !== 'all'
      ? information.categoryId === selectedCategory
      : true;
  });

  const filteredData = search
    ? informationList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : dataByCategory;

  // const confirmMessage = useFormatMessage('Information.confirm');

  // const permDeleteMessage = useFormatMessage('Information.permDelete');

  const getCategory = (categoryId) => {
    const findCategory = informationCategories.find(
      (cat) => cat._id === categoryId
    );
    return findCategory?.name;
  };

  const handleSetPlaylist = () => {
    setSelectedCategory(tempSelectedCategory);
    setPlaylistOpen(false);
  };

  const handleEdit = (id) => {
    history.push(`/${siteId}/information/${siteName}/information/${id}`);
  };

  const onSubmitCategory = () => {
    const action = category._id
      ? modifyInformationCategory
      : createInformationCategory;

    dispatch(
      action({ uid, ...category, siteId, id: category._id }, () =>
        setModalCategory(false)
      )
    );
  };

  const handleSetCategory = (data) => {
    dispatch(setInformationCategory(data));
  };

  const handleEditCategory = (category) => {
    // setIsEditing(true);
    dispatch(setInformationCategory(category));
    setModalCategory(true);
  };

  const handleDeleteCategory = (category) => {
    setModalDeleteCategory({
      show: true,
      category,
    });
  };

  const onDeleteCategory = () => {
    dispatch(
      deleteInformationCategory(modalDeleteCategory.category._id, () =>
        onCloseModalCategoryHandler()
      )
    );
  };

  const onCloseModalCategoryHandler = () => {
    setModalDeleteCategory({
      show: false,
      category: null,
    });
  };

  const handleNew = () => {
    dispatch(
      setInformationCategory(
        {
          _id: '',
          name: '',
          description: '',
        },
        () => setModalCategory(true)
      )
    );
  };

  return (
    <div className="media-library">
      <ModalDelete
        isVisible={modalDeleteCategory.show}
        onOk={onDeleteCategory}
        onCancel={onCloseModalCategoryHandler}
        title="Delete Category"
        subtitle={
          modalDeleteCategory.category ? modalDeleteCategory.category.name : ''
        }
        loading={categoryLoading}
      />

      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={onDeleteInformationHandler}
        onCancel={onCloseModalHandler}
        title="Delete Exhibition"
        subtitle={deleteModal.information ? deleteModal.information.name : ''}
        loading={loading}
      />

      <ModalInformationCategoryForm
        visible={modalCategory}
        onCancel={() => {
          setModalCategory(false);
        }}
        onCreate={onSubmitCategory}
        category={category}
        setCategory={handleSetCategory}
        isEditing={category._id ? true : false}
      />

      <div className="media-header">
        <div className="media-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                // value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div
            className="playlist-wrapper"
            onClick={() => setPlaylistOpen(!playlistOpen)}
          >
            <div className="playlist-selection">
              <FilterIcon />
              <span>
                {selectedCategory === 'all'
                  ? 'Show All'
                  : getCategory(selectedCategory)}
              </span>
            </div>
            <i
              className={`feather-chevron-down ${
                playlistOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={informationCategories}
              setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
              handleEditCategory={(cat) => handleEditCategory(cat)}
              handleDeleteCategory={(cat) => handleDeleteCategory(cat)}
              modalOpen={playlistOpen}
              handleSetPlaylist={handleSetPlaylist}
              tempSelectedCategory={tempSelectedCategory}
              handleNew={handleNew}
              filter={true}
            />
            {/* <div
              className={`playlist-dropdown ${
                playlistOpen
                  ? 'playlist-dropdown-open'
                  : 'playlist-dropdown-closed'
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <ul>
                <li className="header" onClick={handleNew}>
                  <i className="feather-plus" />
                  Add New Category
                </li>
                <li>
                  <div
                    className={`radio ${
                      tempSelectedCategory === 'all' ? 'active' : ''
                    }`}
                    onClick={() => setTempSelectedCategory('all')}
                  >
                    {tempSelectedCategory === 'all' && (
                      <div className="radio-checked" />
                    )}
                  </div>
                  Show All
                </li>

                {informationCategories.map((cat) => {
                  return (
                    <li key={cat._id}>
                      <div className="playlist-filter">
                        <div className="playlist-filter-radio">
                          <div
                            className={`radio ${
                              tempSelectedCategory === cat._id ? 'active' : ''
                            }`}
                            onClick={() => setTempSelectedCategory(cat._id)}
                          >
                            {tempSelectedCategory === cat._id && (
                              <div className="radio-checked" />
                            )}
                          </div>
                          {cat.name}
                        </div>
                        <Tooltip title="Options">
                          <Dropdown
                            trigger="click"
                            overlay={
                              <Menu>
                                <Menu.Item
                                  key="edit"
                                  onClick={() => handleEditCategory(cat)}
                                >
                                  <i className="feather-edit mr-2" />
                                  Edit
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item
                                  key="delete"
                                  onClick={() => handleDeleteCategory(cat)}
                                >
                                  <i className="feather-trash-2 mr-2" />
                                  Delete
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <button>
                              <i className="feather-more-horizontal" />
                            </button>
                          </Dropdown>
                        </Tooltip>
                      </div>
                    </li>
                  );
                })}
                <li className="playlist-button" onClick={handleSetPlaylist}>
                  Set Category
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="actions__wrapper">
          {role !== 'superadmin' ? (
            access &&
            access.create && (
              <Link
                to={`/${siteId}/information/${siteName}/information/new`}
                className="btn-primary"
              >
                <i className="feather-plus mr-2" />
                New Exhibition
              </Link>
            )
          ) : (
            <Link
              to={`/${siteId}/information/${siteName}/information/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-2" />
              New Exhibition
            </Link>
          )}
        </div>
      </div>

      <div className="media-content-wrapper">
        <div className="media-content">
          <div className="media-list-content">
            {informationList.length > 0 && filteredData.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk, index) => {
                return (
                  <div className="media-list-chunk" key={index}>
                    {chunk.map((information) => {
                      return (
                        <div
                          key={information._id}
                          data-id={information._id}
                          className="media-list"
                        >
                          <Cards
                            data={information}
                            type="information"
                            isShowMedia={true}
                            editAction={() => handleEdit(information._id)}
                            deleteAction={() =>
                              onRemoveButtonClickHandler(information._id)
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="media-list-empty" />}
                    {chunk.length < 3 && <div className="media-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="media-empty">
                <Empty image={EmptyIcon} description="No Exhibition" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
