import { createReducer } from 'redux-act';

import {
  SCREENSHOT_FETCH_DATA_INIT,
  SCREENSHOT_FETCH_DATA_SUCCESS,
  SCREENSHOT_FETCH_DATA_FAIL,
  SCREENSHOT_FETCH_DATA_PER_HOUR_INIT,
  SCREENSHOT_FETCH_DATA_PER_HOUR_SUCCESS,
  SCREENSHOT_FETCH_DATA_PER_HOUR_FAIL,
  SCREENSHOT_FETCH_DATA_STATUS_INIT,
  SCREENSHOT_FETCH_DATA_STATUS_SUCCESS,
  SCREENSHOT_FETCH_DATA_STATUS_FAIL,
  SCREENSHOT_DELETE_SCREENSHOT_INIT,
  SCREENSHOT_DELETE_SCREENSHOT_SUCCESS,
  SCREENSHOT_DELETE_SCREENSHOT_FAIL,
  SCREENSHOT_CLEAR_DATA,
  SCREENSHOT_CREATE_SCREENSHOT_INIT,
  SCREENSHOT_CREATE_SCREENSHOT_SUCCESS,
  SCREENSHOT_CREATE_SCREENSHOT_FAIL,
  SCREENSHOT_CLEAN_UP,
  SCREENSHOT_CLEAR_DATA_LOGOUT,
} from 'state/actions/screenshots';

const initialState = {
  list: [],
  status: null,
  screenshot: {
    name: '',
    siteId: '',
    mapId: '',
    images: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const screenshotReducer = createReducer(
  {
    [SCREENSHOT_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SCREENSHOT_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      screenshot: payload.screenshot || initialState.screenshot,
      loading: false,
      error: null,
    }),
    [SCREENSHOT_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENSHOT_FETCH_DATA_PER_HOUR_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SCREENSHOT_FETCH_DATA_PER_HOUR_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      screenshot: payload.screenshot || initialState.screenshot,
      loading: false,
      error: null,
    }),
    [SCREENSHOT_FETCH_DATA_PER_HOUR_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENSHOT_FETCH_DATA_STATUS_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SCREENSHOT_FETCH_DATA_STATUS_SUCCESS]: (state, payload) => ({
      ...state,
      status: payload.status,
      loading: false,
      error: null,
    }),
    [SCREENSHOT_FETCH_DATA_STATUS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENSHOT_DELETE_SCREENSHOT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SCREENSHOT_DELETE_SCREENSHOT_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [SCREENSHOT_DELETE_SCREENSHOT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENSHOT_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [SCREENSHOT_CREATE_SCREENSHOT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SCREENSHOT_CREATE_SCREENSHOT_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.screenshot),
      loading: false,
      error: null,
      success: true,
    }),
    [SCREENSHOT_CREATE_SCREENSHOT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENSHOT_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [SCREENSHOT_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
