import {
  Modal,
  Table,
  Tooltip,
  Form,
  Input,
  Empty,
  // Empty,
  // Select,
} from 'antd';
// import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { log } from 'utils';
// import Table from 'components/Table';
import './ConfigVariable.css';
import {
  createconfig,
  deleteconfig,
  fetchConfig,
  modifyconfig,
} from 'state/actions/config';
import _ from 'underscore';
// import { fetchScreens } from 'state/actions/screens';
import { regexInput } from 'utils/regex';
import { EmptyIcon } from 'assets/icon/bigicon';
import { BagIcon, EditIcon } from 'assets/icon/dynamic';
import ModalDelete from 'components/ModalDelete';
import { toastr } from 'react-redux-toastr';
// import { EmptyIcon } from 'assets/icon/bigicon';

const ConfigVariable = ({ handleChangeTab }) => {
  const { siteId, screenId } = useParams();

  const {
    configList,
    error,
    loading,
    // screenList,
  } = useSelector(
    (state) => ({
      configList: state.config.list,
      error: state.config.error,
      loading: state.config.loading,
      deleted: state.config.deleted,
      updated: state.config.updated,
      success: state.config.success,
      screenList: state.screens.list,
    }),
    shallowEqual
  );

  if (configList) {
    log(configList, '<<<< information categories list');
  }

  const dispatch = useDispatch();

  const [search, setSearch] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataValues, setDataValues] = useState([]);
  const [data, setData] = useState({
    variable: '',
    id: '',
  });

  const [errors, setErrors] = useState(error);
  // const [searchError, setSearchError] = useState(null);
  const [deletedItem, setDeletedItem] = useState({
    item: null,
    isOpen: false,
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

 const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };


  // useEffect(() => {
  //   dispatch(fetchScreens({ siteId }));
  // }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrors(error);
  }, [error]);

  useEffect(() => {
    if (screenId) {
      dispatch(fetchConfig({ siteId, screenId: screenId }));
    }
  }, [siteId, screenId]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setVisible(false);
  //   setDataValues([]);
  //   setData({
  //     variable: '',
  //   });
  // }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   log(updated);
  //   if (updated && !loading) {
  //     dispatch(fetchConfig({ siteId, screenId: screenId }));
  //     setVisible(false);
  //   }
  // }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (deleted) {
  //     setDeleteModal({
  //       ...deleteModal,
  //       isOpen: false,
  //     });
  //   }
  // }, [deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect = !role && <Redirect to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (item) => {
    setDeletedItem({
      item,
      isOpen: true,
    });
  };

  const onCloseModalHandler = () => {
    setDeletedItem({ item: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteconfig(deletedItem.item._id, () => onCloseModalHandler()));
  };

  const onNewCategoryHandler = () => {
    setIsEditing(false);
    setVisible(true);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeRow = (id, value) => {
    const newDataValues = [...dataValues];
    const findDataValues = newDataValues.find((x) => x.id === id);
    if (findDataValues) {
      findDataValues.value = value;
      setDataValues(newDataValues);
    }
  };

  const handleDeleteRow = (id) => {
    const filterDataValues = dataValues.filter((x) => x.id !== id);
    setDataValues(filterDataValues);
  };

  const handleAddRow = () => {
    const id = Math.random();
    setDataValues([
      ...dataValues,
      {
        id,
        value: '',
      },
    ]);
    log(dataValues);
  };

  const handleSearch = (e) => {
    // setSearchError(null);
    // if (!regexInput(e.target.value) && e.target.value) {
    //   // setSearchError('Search cannot using special character');
    // }
    setSearch(e.target.value);
  };

  const handleSave = () => {
    log(dataValues, 'data values');
    if (!regexInput(data.variable) && data.variable) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.variable = !regexInput(data.variable) &&
        data.variable && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      setErrors(newErrors);
    } else if (dataValues.find((data) => data.value === '')) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      setErrors(newErrors);
      toastr.error("Values can't be null");
    } else {
      setErrors({
        errors: null,
        message: '',
      });
      dispatch(
        isEditing
          ? modifyconfig(
              {
                ...data,
                values: _.pluck(dataValues, 'value'),
                siteId,
                screenId: screenId,
              },
              () => handleReset()
            )
          : createconfig(
              {
                ...data,
                values: _.pluck(dataValues, 'value'),
                siteId,
                screenId: screenId,
              },
              () => handleReset()
            )
      );
    }
  };

  const handleReset = () => {
    setVisible(false);
    setDataValues([]);
    setData({
      variable: '',
    });
  };

  const columns = [
    {
      title: '',
      width: 30,
      render: () => null,
    },
    {
      title: 'Variable',
      dataIndex: 'variable',
      render: (name) => {
        return <div className="table-name">{name}</div>;
      },
    },
    {
      title: 'Values',
      dataIndex: 'values',
      render: (values) => {
        return values && values.length > 0 ? values.join(', ') : '';
      },
    },
    {
      title: 'Actions',
      dataIndex: 'option',
      width: 240,
      align: 'start',
      render: (text, record) => (
        <div className="button-wrapper">
          <Tooltip title="Edit Variable">
            <button
              data-tooltip="Edit"
              onClick={() => {
                setDataValues(
                  record.values.map((x) => {
                    log(x);
                    return {
                      id: Math.random(),
                      value: x,
                    };
                  })
                );
                setData({
                  variable: record.variable,
                  id: record._id,
                });
                setVisible(true);
                setIsEditing(true);
              }}
              className="btn btn--edit"
            >
              <EditIcon color="#A0ACB7" size={18} />
              Edit
            </button>
          </Tooltip>

          <Tooltip title="Delete Variable">
            <button
              data-tooltip="Delete"
              type="button"
              className="btn btn--delete"
              onClick={() => onRemoveButtonClickHandler(record)}
            >
              <BagIcon color="#A0ACB7" size={18} />
              Delete
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const configVariables = search
    ? configList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.variable.toLowerCase().includes(search.toLowerCase());
      })
    : configList;

  // const confirmMessage = useFormatMessage('Categories.confirm');

  // const permDeleteMessage = useFormatMessage('Categories.permDelete');

  return (
    <section className="information-categories">
      {/* {loading ? (
        <ClipLoader />
      ) : ( */}
      {screenId && (
        <>
          {role && redirect}
          <ModalDelete
            isVisible={deletedItem.isOpen}
            onOk={onDeleteUserHandler}
            onCancel={onCloseModalHandler}
            title="Delete Config Variable"
            subtitle={deletedItem.item ? deletedItem.item.name : ''}
            loading={loading}
          />

          <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            className="adsign-modal"
            closeIcon={<i className="feather-x" />}
            okText="Submit"
            okButtonProps={{
              className: 'button-submit',
              loading,
            }}
            cancelButtonProps={{
              className: 'button-cancel',
            }}
            onOk={handleSave}
          >
            <Form layout="vertical">
              <Form.Item
                label="Variable"
                validateStatus={
                  errors.errors && errors.errors.variable ? 'error' : 'success'
                }
                help={
                  errors.errors && errors.errors.variable
                    ? errors.errors.variable[0].msg
                    : ''
                }
              >
                <Input
                  placeholder="Variable"
                  value={data.variable}
                  onChange={(e) => handleChange('variable', e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Value">
                <div className="justify-left">
                  <button className="button-primary" onClick={handleAddRow}>
                    <i className="feather-plus mr-5" />
                    Add Row
                  </button>
                </div>
                <div>
                  <Table
                    rowKey={(data) => data.id}
                    showHeader={false}
                    columns={[
                      {
                        title: '',
                        dataIndex: 'value',
                        render: (value, data) => {
                          log(value, data);
                          return (
                            <Input
                              value={value}
                              onChange={(e) =>
                                handleChangeRow(data.id, e.target.value)
                              }
                              placeholder="Value"
                            />
                          );
                        },
                      },
                      {
                        title: '',
                        key: 'option',
                        dataIndex: 'id',
                        render: (id) => {
                          return (
                            <button
                              className="button-delete"
                              onClick={() => handleDeleteRow(id)}
                            >
                              <i className="feather-trash-2" />
                            </button>
                          );
                        },
                      },
                    ]}
                    pagination={false}
                    dataSource={dataValues}
                  />
                </div>
              </Form.Item>
            </Form>
          </Modal>

          <div className="categories__nav config-variable-header px-20">
            <div className="categories__search-wrapper searchbar-wrapper">
              <div className="categories__search searchbar">
                <i className="feather-search"></i>
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                  className="search-input"
                />
              </div>
            </div>

            <button
              type="primary"
              className="button-primary"
              onClick={onNewCategoryHandler}
            >
              <i className="feather-plus" />
              Add Variable
            </button>
          </div>

          <div className="categories__table mb-20">
            <Table
              className="adsign-table"
              columns={columns}
              dataSource={configVariables}
              rowKey={(data) => data._id}
              bordered
              size="small"
              locale={{
                emptyText: (
                  <Empty image={EmptyIcon} description="No Config Variable" />
                ),
              }}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </div>
        </>
      )}
    </section>
  );
};

export default ConfigVariable;
