import ModalScreenForm from 'components/ModalScreenForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { log } from 'utils';
import {
  createScreen,
  deleteScreen,
  fetchScreens,
  modifyScreen,
  setActiveScreen,
} from 'state/actions/screens';
import { clearUsersData } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import './Screens.scss';
import ModalDeleteConfirmation from 'components/ModalDeleteConfirmation';
import Cards from 'design-components/Cards';
import _ from 'underscore';
export default function Screens({ history }) {
  const { siteId, siteName } = useParams();

  const {
    sites,
    error,
    loading,
    deleted,
    updated,
    screensList,
    userData,
  } = useSelector(
    (state) => ({
      sites: state.sites.list,
      error: state.screens.error,
      loading: state.screens.loading,
      deleted: state.screens.deleted,
      updated: state.screens.updated,
      screensList: state.screens.list,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

 const fetchData = async () => {
    try {
      const roleData = await getRole();
      setRole(roleData);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };


  const defaultScreen = {
    name: '',
    description: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: userData.email ? userData.email : '',
  };

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [screen, setScreen] = useState(defaultScreen);
  // const [siteName, setSiteName] = useState('');
  // const [organizationId, setOrganizationId] = useState('');
  const [searchError, setSearchError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
    return () => dispatch(clearUsersData());
  }, [dispatch, role, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchScreens({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchScreens({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadJSONConfig = (selectedScreen) => {
    const site = sites.filter(site => site._id === siteId);
    const organizationId = site && site[0] ? site.organizationId : '';
    const json = {
      organizationId: organizationId,
      siteId: siteId,
      screenId: selectedScreen._id,
      email: '',
      password: '',
      url: 'https://premise-api.adactive.asia',
      mode: 'offline',
    };
    const content = JSON.stringify(json);
    const filename = `${siteName} ${selectedScreen.name}.json`;
    const contentType = 'text/json';

    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyScreen : createScreen;
    log(values, '<<<< values screen oncreate');
    dispatch(action(values));
  };

  const onNewScreenHandler = () => {
    log('handle new screen');
    setScreen(defaultScreen);
    setIsEditing(false);
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (screenId) => {
    setDeleteModal((prevState) => ({
      screenId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ screenId: null, isOpen: false });
  };

  const onDeleteScreenHandler = () => {
    dispatch(deleteScreen(deleteModal.screenId));
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const handleGoTo = (screen) => {
    dispatch(
      setActiveScreen(screen, () =>
        history.push(`/${siteId}/screen/${siteName}/${screen._id}`)
      )
    );
  };

  const data = search
    ? screensList.filter((el) => {
      const clonedElem = { ...el };
      return clonedElem.name.toLowerCase().includes(search.toLowerCase());
    })
    : screensList;

  const permDeleteMessage = useFormatMessage('Screens.permDelete');

  return (
    <section className="screens">
      {deleteModal.isOpen && (
        <ModalDeleteConfirmation
          title={'Delete Screen'}
          onCloseHandler={onCloseModalHandler}
          onDeleteHandler={onDeleteScreenHandler}
          description={permDeleteMessage}
          visible={deleteModal.isOpen}
        />
      )}
      <div className="screens__nav">
        <div className="screens__search-wrapper">
          <div className="media-categories__search">
            <i className="feather-search"></i>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {searchError && <div className="search-error">{searchError}</div>}
        </div>

        <div className="screens__buton-add">
          <button className="button-primary" onClick={onNewScreenHandler}>
            <i className="feather-plus"></i> Add Screen
          </button>
        </div>
      </div>
      {visible && (
        <ModalScreenForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          screen={screen}
          setScreen={setScreen}
          isEditing={isEditing}
        />
      )}
      <div className="screens__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <div className="media-content-wrapper">
            <div className="media-content">
              <div className="media-list-content">
                {_.chunk(data, 3).map((chunk, index) => {
                  return (
                    <div className="media-list-chunk" key={index}>
                      {chunk.map((screen) => (
                        <div
                          key={screen._id}
                          data-id={screen._id}
                          className="media-list"
                        >
                          <Cards
                            data={screen}
                            type="screen"
                            isShowMedia={true}
                            editAction={() => {
                              setScreen(screen);
                              setVisible(true);
                              setIsEditing(true);
                            }}
                            cardClickAction={() => handleGoTo(screen)}
                            deleteAction={() => onRemoveButtonClickHandler(screen._id)}
                            downloadAction={() => downloadJSONConfig(screen)}
                            isDownload={true}
                            isClickable={true}
                            onClick={() => handleGoTo(screen)}
                          />
                        </div>
                      ))}
                      {chunk.length < 2 && <div className="media-list-empty" />}
                      {chunk.length < 3 && <div className="media-list-empty" />}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          // <ScreenCard
          //   data={data}
          //   onRemoveButtonClickHandler={(id) => onRemoveButtonClickHandler(id)}
          //   onEditButtonClickHandler={(record) => {
          //     setScreen(record);
          //     setVisible(true);
          //     setIsEditing(true);
          //   }}
          //   handleGoTo={handleGoTo}
          // />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
