import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg, refreshToken } from 'utils';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { getCsrf } from 'components/Report/utils';

export const POIS_FETCH_DATA_INIT = createAction('POIS_FETCH_DATA_INIT');
export const POIS_FETCH_DATA_SUCCESS = createAction('POIS_FETCH_DATA_SUCCESS');
export const POIS_FETCH_DATA_FAIL = createAction('POIS_FETCH_DATA_FAIL');

export const POIS_DELETE_POI_INIT = createAction('POIS_DELETE_POI_INIT');
export const POIS_DELETE_POI_SUCCESS = createAction('POIS_DELETE_POI_SUCCESS');
export const POIS_DELETE_POI_FAIL = createAction('POIS_DELETE_POI_FAIL');

export const POIS_CLEAR_DATA = createAction('POIS_CLEAR_DATA');

export const POIS_CREATE_POI_INIT = createAction('POIS_CREATE_POI_INIT');
export const POIS_CREATE_POI_SUCCESS = createAction('POIS_CREATE_POI_SUCCESS');
export const POIS_CREATE_POI_FAIL = createAction('POIS_CREATE_POI_FAIL');

export const POIS_MODIFY_POI_INIT = createAction('POIS_MODIFY_POI_INIT');
export const POIS_MODIFY_POI_SUCCESS = createAction('POIS_MODIFY_POI_SUCCESS');
export const POIS_MODIFY_POI_FAIL = createAction('POIS_MODIFY_POI_FAIL');

export const POIS_CLEAN_UP = createAction('POIS_CLEAN_UP');

export const POIS_CLEAR_DATA_LOGOUT = createAction('POIS_CLEAR_DATA_LOGOUT');
export const POIS_EXPORT_POI_INIT = createAction('POIS_EXPORT_POI_INIT');
export const POIS_EXPORT_POI_SUCCESS = createAction('POIS_EXPORT_POI_SUCCESS');
export const POIS_EXPORT_POI_FAIL = createAction('POIS_EXPORT_POI_FAIL');

axios.defaults.withCredentials = true;

export const fetchPois = ({
  poiId,
  siteId,
  categoryId = 'all',
  type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(POIS_FETCH_DATA_INIT());
    if (poiId) {
      let poiData;
      try {
        const token = await refreshToken();
        poiData = await axios.get(`${url}/poi/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        console.log(error, 'error get poi')
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(POIS_FETCH_DATA_FAIL({ error }));
      }
      const poiFind = poiData.data.data.find((x) => x._id === poiId);
      const poi = { ...poiFind, id: poiId };
      return dispatch(
        POIS_FETCH_DATA_SUCCESS({
          list: getState().pois.list,
          poi,
        })
      );
    }

    if (siteId) {
      let pois;
      try {
        const token = await refreshToken();
        pois = await axios.post(
          `${url}/poi/filter`,
          {
            siteId,
            categoryId,
            type,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.log(error, 'error get poi')
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(POIS_FETCH_DATA_FAIL({ error }));
      }
      pois = pois.data.data.filter((poi) => poi.siteId === siteId);
      console.log(pois);
      return dispatch(
        POIS_FETCH_DATA_SUCCESS({
          list: pois,
          poi: getState().pois.poi,
        })
      );
    }
  };
};

export const removePoi = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(POIS_DELETE_POI_INIT());
    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      await axios.post(
        `${url}/poi/remove`,
        {
          poiId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-CSRF-TOKEN': csrfToken,
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        POIS_DELETE_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The poi was removed.');
    cb();
    return dispatch(POIS_DELETE_POI_SUCCESS({ id }));
  };
};

export const deletePoi = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(POIS_DELETE_POI_INIT());
    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      await axios.post(
        `${url}/poi/delete`,
        {
          poiId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-CSRF-TOKEN': csrfToken,
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        POIS_DELETE_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The poi was deleted.');
    cb();
    return dispatch(POIS_DELETE_POI_SUCCESS({ id }));
  };
};

export const clearPoisData = () => {
  return (dispatch) => {
    dispatch(POIS_CLEAR_DATA());
  };
};

export const clearPoisDataLogout = () => {
  return (dispatch) => {
    dispatch(POIS_CLEAR_DATA_LOGOUT());
  };
};

export const createPoi = ({
  uid,
  name,
  phone,
  priority,
  description,
  files,
  qrFile,
  thumbFile,
  opentime,
  url: poiUrl,
  categoryId,
  categoryName,
  floorId,
  floorName,
  siteId,
  tag,
  location,
  floor,
  modifiedAt,
  modifiedBy,
  start,
  end,
}) => {
  return async (dispatch, getState) => {
    dispatch(POIS_CREATE_POI_INIT());

    // let createPoi;

    let poi = new FormData();
    poi.set('path', 'poi');
    poi.set('name', name);
    poi.set('phone', phone);
    poi.set('priority', priority);
    poi.set('description', description);
    poi.set('opentime', opentime);
    poi.set('url', poiUrl);
    if (categoryId) {
      poi.set('categoryId', categoryId);
      poi.set('categoryName', categoryName);
    }
    if (floorId) {
      poi.set('floorId', floorId);
      poi.set('floorName', floorName);
    }
    poi.set('siteId', siteId);
    poi.set('tag', tag);
    poi.set('location', location);
    poi.set('floor', floor);
    qrFile && poi.append('file', qrFile, `qrcode-${qrFile.name}`);
    thumbFile && poi.append('file', thumbFile, `thumbnail-${thumbFile.name}`);
    files &&
      files.forEach((file) => {
        poi.append('file', file, `detail-${file.name}`);
      });
    if (start && end) {
      poi.set('start', moment(start).format('YYYY-MM-DD'));
      poi.set('end', moment(end).format('YYYY-MM-DD'));
    }

    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      await axios.post(`${url}/poi/create`, poi, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        POIS_CREATE_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const poiJson = JSON.stringify(Object.fromEntries(poi));
    toastr.success('', 'Poi created successfully');
    return dispatch(POIS_CREATE_POI_SUCCESS({ poi: poiJson }));
  };
};

export const modifyPoi = ({
  id,
  name,
  phone,
  priority,
  description,
  files,
  logo,
  opentime,
  url: poiUrl,
  categoryId,
  categoryName,
  floorId,
  floorName,
  siteId,
  tag,
  location,
  eventTime,
  ticketing,
  floor,
  deletedFiles,
  qrCode,
  thumbnail,
  qrFile,
  thumbFile,

  modifiedAt,
  modifiedBy,
  start,
  end,
}) => {
  return async (dispatch, getState) => {
    dispatch(POIS_MODIFY_POI_INIT());

    let poi = new FormData();
    poi.set('path', 'poi');
    poi.set('id', id);
    poi.set('name', name);
    poi.set('phone', phone);
    poi.set('priority', priority);
    poi.set('description', description);
    poi.set('opentime', opentime);
    poi.set('url', poiUrl);
    if (categoryId && categoryName) {
      poi.set('categoryId', categoryId);
      poi.set('categoryName', categoryName);
    }
    if (floorId && floorName) {
      poi.set('floorId', floorId);
      poi.set('floorName', floorName);
    }
    poi.set('siteId', siteId);
    poi.set('tag', tag);
    location && poi.set('location', location);
    eventTime && poi.set('eventTime', eventTime);
    ticketing && poi.set('ticketing', ticketing);

    qrCode && poi.set('qrCode', qrCode);
    thumbnail && poi.set('thumbnail', thumbnail);

    if (start && end) {
      poi.set('start', moment(start).format('YYYY-MM-DD'));
      poi.set('end', moment(end).format('YYYY-MM-DD'));
    }
    qrFile && poi.append('file', qrFile, `qrcode-${qrFile.name}`);
    thumbFile && poi.append('file', thumbFile, `thumbnail-${thumbFile.name}`);
    files &&
      files
        .filter((file) => !file.logo)
        .forEach((file) => {
          poi.append('file', file, `detail-${file.name}`);
        });

    const deleted = deletedFiles && deletedFiles.map((file) => file.logo);

    poi.set('files', JSON.stringify(deleted));

    try {
      const csrfToken = await getCsrf();
      const token = await refreshToken();
      await axios.put(`${url}/poi/edit`, poi, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        POIS_MODIFY_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const poiJson = JSON.stringify(Object.fromEntries(poi));
    toastr.success('', 'Poi updated successfully');
    return dispatch(POIS_MODIFY_POI_SUCCESS({ poiJson, id }));
  };
};

export const downloadPois = ({ siteId, siteName }) => {
  return async (dispatch, getState) => {
    dispatch(POIS_EXPORT_POI_INIT());
    const token = await refreshToken();
    axios.get(`${url}/poi/export`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
      params: {
        siteId,
      },
    })
      .then((res) => {
        fileDownload(
          res.data,
          `poi_export_${siteName}_${moment(new Date()).format(
            'YYYY-MM-DD'
          )}.csv`
        );
        return dispatch(POIS_EXPORT_POI_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(POIS_EXPORT_POI_FAIL({ error }));
      });
  };
};

export const poisCleanUp = () => (dispatch) => dispatch(POIS_CLEAN_UP());
