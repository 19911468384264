/* eslint-disable jsx-a11y/label-has-associated-control */
import { Modal } from 'antd';
import './Modal.scss';
import React from 'react';
import DeleteImage from 'assets/delete.png';

const ModalDeleteConfirmation = ({
  visible,
  title,
  description,
  onDeleteHandler,
  onCloseHandler,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onDeleteHandler}
      onCancel={onCloseHandler}
      footer={
        <div className="delete__modal-footer">
          <button className="button-secondary" onClick={() => onCloseHandler()}>
            Cancel
          </button>
          <button className="button-danger" onClick={() => onDeleteHandler()}>
            Delete
          </button>
        </div>
      }
    >
      <div className="delete__modal">
        <img src={DeleteImage} alt="delete confirmation" />
        <h3>Delete Confirmation</h3>
        {description && <p>{description}</p>}
      </div>
    </Modal>
  );
};

export default ModalDeleteConfirmation;
