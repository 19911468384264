import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import OrganizationForm from 'components/OrganizationForm';
import {
  createOrganization,
  fetchOrganizations,
  modifyOrganization,
} from 'state/actions/organizations';
import { useFormatMessage } from 'hooks';

const Organization = ({ history }) => {
  const { id } = useParams();

  const {
    success,
    userData,
    organizationsList,
    organizationData,
    error,
  } = useSelector(
    (state) => ({
      success: state.organizations.success,
      userData: state.auth.userData,
      organizationsList: state.organizations.list,
      organizationData: state.organizations.organization,
      error: state.organizations.error,
    }),
    shallowEqual
  );

  const [organization, setOrganization] = useState(
    organizationData || {
      name: '',
      description: '',
      createdAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
      updatedBy: userData.email ? userData.email : '',
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const organizationFetched = organizationsList.find(
        (fetchedOrganization) => fetchedOrganization.id === id
      );
      if (organizationFetched) {
        setOrganization(organizationFetched);
      } else if (organizationData.id === id) {
        setOrganization(organizationData);
      } else {
        dispatch(fetchOrganizations(id));
      }
    }
  }, [id, organizationData]); // eslint-disable-line 

  const isEditing = !!id;

  const organizationForm =
    !organization && id ? (
      <ClipLoader />
    ) : (
      <OrganizationForm
        isEditing={isEditing}
        organization={organization}
        user={userData}
        success={success}
        history={history}
        setOrganization={setOrganization}
        action={isEditing ? modifyOrganization : createOrganization}
        error={error}
      />
    );

  const editOrganizationMessage = useFormatMessage(
    'Organization.editOrganization'
  );

  const newOrganizationMessage = useFormatMessage(
    'Organization.newOrganization'
  );

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editOrganizationMessage : newOrganizationMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">{organizationForm}</section>
    </>
  );
};

export default Organization;
