import React, { useState, useEffect } from 'react';
import './FileManagement.scss';
import { Tooltip, Table, Empty, Tag } from 'antd';
import socketIoClient from 'socket.io-client';
import { url } from 'utils/url';
import { log } from 'utils';
// import { useFormatMessage } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchScreens } from 'state/actions/screens';
import { fetchFiles, createFile, deleteFile } from 'state/actions/files';
import moment from 'moment';
import { connectSocket, installApplication } from 'state/actions/settings';
import { useParams } from 'react-router';
// import { ClipLoader } from 'react-spinners';
// import { Select, Form, Empty } from 'antd';
import ModalFileManagementForm from 'components/ModalFileManagementForm';
import { EmptyIcon, NotConnectedIcon } from 'assets/icon/bigicon';
import { BagIcon, ImportOutlineIcon } from 'assets/icon/dynamic';
import ModalDelete from 'components/ModalDelete';

function FileManagement({ handleChangeTab }) {
  const {
    // siteId,
    screenId,
  } = useParams();

  const {
    // screenList,
    // screenLoading,
    fileList,
    updated,
    // deleted,
    loadingFiles,
  } = useSelector((state) => ({
    screenList: state.screens.list,
    screenLoading: state.screens.loading,
    fileList: state.files.list,
    updated: state.files.updated,
    deleted: state.files.deleted,
    loadingFiles: state.files.loading,
  }));

  // const [screenId, setscreenId] = useState('');
  const [socket, setSocket] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState();
  const [deleteModal, setDeleteModal] = useState({
    file: null,
    isOpen: false,
  });

  // const [downloadUrl, setDownloadUrl] = useState('');

  const dispatch = useDispatch();
  const data = {
    name: 'adsign',
  };

  // const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    if (!deviceInfo) {
      setError(true);
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (updated) {
      setVisible(false);
    }
  }, [updated]);

  useEffect(() => {
    setSocket(
      socketIoClient(url, {
        query: {
          name: 'adsign',
        },
      })
    );
  }, []);

  // useEffect(() => {
  //   if (deleted && !loadingFiles) {
  //     setDeleteModal((prevState) => ({
  //       floorId: null,
  //       isOpen: !prevState.isOpen,
  //     }));
  //     dispatch(fetchFiles({ id: screenId }));
  //   }
  // }, [deleted, loadingFiles, dispatch, screenId]);

  // useEffect(() => {
  //   if (updated && !loadingFiles) {
  //     dispatch(fetchFiles({ id: screenId }));
  //     setVisible(false);
  //   }
  // }, [updated, loadingFiles, dispatch, screenId]);

  useEffect(() => {
    setDeviceInfo(null);
    if (socket && screenId) {
      socket.on('connect', () => {
        socket.emit('in_device', data);
      });
      socket.on('in_device', (data) => {
        log(data);
        if (data.screenId === screenId) {
          setError(false);
          dispatch(fetchFiles({ id: screenId }));
          setDeviceInfo(data);
          setLoading({
            ...loading,
            device: false,
          });
        } else {
          setDeviceInfo(null);
        }
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, screenId]);

  // useEffect(() => {
  //   dispatch(fetchScreens({ siteId }));
  // }, [dispatch, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (screenId) {
  //     connectSocket(screenId);

  //   }
  // }, [dispatch, screenId]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  // const confirmMessage = useFormatMessage('FileManagement.confirm');
  // const permDeleteMessage = useFormatMessage('FileManagement.permDelete');

  const handleTryAgain = async () => {
    await connectSocket(screenId);
    dispatch(fetchFiles({ id: screenId }));
    setError(false);
    getDeviceInfo();
  };

  const handleInstallApplication = (downloadUrl) => {
    installApplication(screenId, downloadUrl);
    socket.once('in_install', (data) => {
      log(data);
    });
  };

  const getDeviceInfo = async () => {
    log('get device info');
    socket.emit('message', data, (response) => {
      log(response); // ok
    });
    await delay();
    if (!deviceInfo) {
      setError(true);
    }
  };

  const onCreate = () => {
    dispatch(createFile({ screenId: screenId, ipk: file }));
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteFile(deleteModal.file._id, () => onCloseModalHandler()));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ fileId: null, isOpen: false });
  };

  const onNewIPK = (value) => {
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (file) => {
    setDeleteModal({
      file,
      isOpen: true,
    });
  };

  // const newMessage = useFormatMessage('FileManagement.newFile');

  const columns = [
    {
      title: '',
      width: 30,
      render: () => null,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name) => {
        return <div className="table-name">{name}</div>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text, record) => {
        if (record.modifiedAt) {
          return (
            <Tag className="table-date">{`${moment(record.modifiedAt)
              .utcOffset('+08:00')
              .format('h:mm A, MMM Do YYYY')}`}</Tag>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Option',
      width: 240,
      align: 'start',
      render: (text, record) => (
        <div className="button-wrapper">
          <Tooltip title="Install IPK">
            <button
              data-tooltip="Install"
              className="btn btn--download"
              onClick={() => {
                handleInstallApplication(record.url);
              }}
            >
              <ImportOutlineIcon size={18} color="#A0ACB7" />
              Download
            </button>
          </Tooltip>

          <Tooltip title="Delete Floor">
            <button
              type="button"
              className="btn btn--delete"
              data-tooltip="Delete"
              onClick={() => {
                onRemoveButtonClickHandler(record);
              }}
            >
              <BagIcon size={18} color="#A0ACB7" />
              Delete
            </button>
          </Tooltip>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <section className="files-management">
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={onDeleteUserHandler}
        onCancel={onCloseModalHandler}
        title="Delete File"
        subtitle={deleteModal.file ? deleteModal.file.name : ''}
        loading={loadingFiles}
      />

      {visible && (
        <ModalFileManagementForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          setFile={setFile}
          file={file}
        />
      )}
      {
        <div>
          <div>
            {screenId && (
              <>
                {log(error, 'error')}
                {error ? (
                  <div className="screen-not-connected">
                    <Empty image={NotConnectedIcon} description="" />
                    <h1>The device isn't connected</h1>
                    <button
                      className="btn-outline-primary"
                      onClick={handleTryAgain}
                    >
                      Try Again
                    </button>
                  </div>
                ) : (
                  <div>
                    <div className="files-management__nav px-20">
                      <button
                        type="primary"
                        className="button-primary"
                        onClick={onNewIPK}
                      >
                        <i className="feather-plus" /> Add IPK
                      </button>
                    </div>
                    <div className="files-management__table">
                      <Table
                        className="adsign-table"
                        columns={columns}
                        dataSource={fileList}
                        rowKey="_id"
                        bordered
                        size="small"
                        locale={{
                          emptyText: (
                            <Empty image={EmptyIcon} description="No File" />
                          ),
                        }}
                        pagination={{
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total}`,
                          pageSize: 5,
                          showSizeChanger: true,
                          defaultCurrent: 1,
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      }
    </section>
  );
}

export default FileManagement;
