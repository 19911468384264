import PageTitle from 'elements/page-title';
import React from 'react';
import './style.scss';

const PageHeader = ({ title,
  isGrid,
  isGridSelected,
  gridAction,
  listAction,
  isSearch,
  searchValue,
  searchPlaceholder,
  searchAction,
  searchError,
  isAdd,
  addAction,
  addLabel,
  isDownload,
  isDownloading,
  downloadAction }) => {
  return (
    <div className="header-content">
      <div className="header-left-content">
        <PageTitle>{title}</PageTitle>
      </div>
      <div className="header-right-content">
        {isGrid && <div className='grid-icon'>
          <div
            className={
              isGridSelected ? 'icon-container-active' : 'icon-container'
            }
            onClick={() => gridAction()}
          >
            <i className="feather-grid"></i>
          </div>
          <div
            className={
              isGridSelected ? 'icon-container' : 'icon-container-active'
            }
            onClick={() => listAction()}
          >
            <i className="feather-list"></i>
          </div>
        </div>}

        {isSearch && <div className="searchbar-wrapper">
          <div className="search-bar">
            <i className="feather-search icons"></i>
            <input
              type="text"
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={searchAction}
            />
          </div>
          {searchError && <div className="search-error">{searchError}</div>}
        </div>}
        {isAdd && <button className="button-primary-header" onClick={addAction}>
          <i className="feather-plus" />
          {addLabel}
        </button>}
        {isDownload && (isDownloading ? (
          <button className="button-primary-outline-header button-loading mr-1">
            <i className="mdi mdi-spin mdi-loading" />
            Loading...
          </button>
        ) : (
          <button
            className="button-primary-outline-header mr-1"
            onClick={downloadAction}
          >
            Download CSV
          </button>
        ))}
      </div>
    </div>
  );
};

export default PageHeader;
