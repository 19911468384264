import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import {url} from 'utils/url';
import { log, errorMsg, refreshToken  } from 'utils';

export const MAPS_FETCH_DATA_INIT = createAction('MAPS_FETCH_DATA_INIT');
export const MAPS_FETCH_DATA_SUCCESS = createAction('MAPS_FETCH_DATA_SUCCESS');
export const MAPS_FETCH_DATA_FAIL = createAction('MAPS_FETCH_DATA_FAIL');

export const MAPS_DELETE_MAPS_INIT = createAction('MAPS_DELETE_MAPS_INIT');
export const MAPS_DELETE_MAPS_SUCCESS = createAction(
  'MAPS_DELETE_MAPS_SUCCESS'
);
export const MAPS_DELETE_MAPS_FAIL = createAction('MAPS_DELETE_MAPS_FAIL');

export const MAPS_CLEAR_DATA = createAction('MAPS_CLEAR_DATA');

export const MAPS_CREATE_MAPS_INIT = createAction('MAPS_CREATE_MAPS_INIT');
export const MAPS_CREATE_MAPS_SUCCESS = createAction(
  'MAPS_CREATE_MAPS_SUCCESS'
);
export const MAPS_CREATE_MAPS_FAIL = createAction('MAPS_CREATE_MAPS_FAIL');

export const MAPS_MODIFY_MAPS_INIT = createAction('MAPS_MODIFY_MAPS_INIT');
export const MAPS_MODIFY_MAPS_SUCCESS = createAction(
  'MAPS_MODIFY_MAPS_SUCCESS'
);
export const MAPS_MODIFY_MAPS_FAIL = createAction('MAPS_MODIFY_MAPS_FAIL');

export const MAPS_CLEAN_UP = createAction('MAPS_CLEAN_UP');

export const MAPS_CLEAR_DATA_LOGOUT = createAction('MAPS_CLEAR_DATA_LOGOUT');

axios.defaults.withCredentials = true;

export const fetchMaps = ({ mapsId, siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(MAPS_FETCH_DATA_INIT());
    if (mapsId) {
      let mapsData;
      try {
        const token = await refreshToken();
        mapsData = await axios.get(`${url}/map/get`, {
          params: {
            id: mapsId,
            siteId
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => res.data.data);
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MAPS_FETCH_DATA_FAIL({ error }));
      }
      const map = { ...mapsData, id: mapsId };
      return dispatch(
        MAPS_FETCH_DATA_SUCCESS({
          list: getState().maps.list,
          map,
        })
      );
    }

    if (siteId) {
      let maps;
      try {
        const token = await refreshToken();
        maps = await axios.get(`${url}/map`, {
          params:{
            siteId
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => res.data.data);
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(MAPS_FETCH_DATA_FAIL({ error }));
      }
      const map = maps.filter((maps) => maps.siteId === siteId);
      return dispatch(
        MAPS_FETCH_DATA_SUCCESS({
          list: map,
          map: getState().maps.map,
        })
      );
    }
  };
};

export const deleteMaps = (id, siteId) => {
  return async (dispatch, getState) => {
    dispatch(MAPS_DELETE_MAPS_INIT());

    try {
      const token = await refreshToken();
      await axios.delete(`${url}/map/delete`, {
        params: {
          id,
          siteId
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MAPS_DELETE_MAPS_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The maps was deleted.');
    return dispatch(MAPS_DELETE_MAPS_SUCCESS({ id }));
  };
};

export const clearMapsData = () => {
  return (dispatch) => {
    dispatch(MAPS_CLEAR_DATA());
  };
};

export const clearMapsDataFileut = () => {
  return (dispatch) => {
    dispatch(MAPS_CLEAR_DATA_LOGOUT());
  };
};

export const createMaps = ({
  uid,
  name,
  description,
  file,
  siteId,
  paths,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(MAPS_CREATE_MAPS_INIT());

    let fd = new FormData();
    fd.set('path', 'map');
    fd.set('name', name);
    fd.set('description', description);
    fd.set('siteId', siteId);
    if (file) {
      fd.append('file', file);
    }
    let createMaps;

    try {
      const token = await refreshToken();
      createMaps = await axios.post(`${url}/map/create`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.data.data);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        MAPS_CREATE_MAPS_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Maps created successfully');
    return dispatch(MAPS_CREATE_MAPS_SUCCESS({ maps: createMaps }));
  };
};

export const modifyMaps = ({
  id,
  name,
  description,
  file,
  fileUrl,
  siteId,
  paths,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(MAPS_MODIFY_MAPS_INIT());

    let fd = new FormData();
    fd.set('id', id);
    fd.set('path', 'map');
    fd.set('name', name);
    fd.set('description', description);
    fd.set('siteId', siteId);
    if (file) {
      fd.append('file', file);
    }
    let updateMap;

    try {
      const token = await refreshToken();
      updateMap = await axios.post(`${url}/map/edit`, fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.data.data);
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        MAPS_CREATE_MAPS_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Maps updated successfully');

    return dispatch(MAPS_MODIFY_MAPS_SUCCESS({ maps: updateMap, id }));
  };
};

export const modifyMapsPath = ({
  id,
  map,
  mapPos,
  userPos,
  pathsObject,
}) => async (dispatch, getState) => {
  dispatch(MAPS_MODIFY_MAPS_INIT());
  const paths = JSON.parse(JSON.stringify(pathsObject));
  const mapData = {
    ...map,
    mapPos,
    paths,
    userPos,
  };
  log(mapData);
  const token = await refreshToken();
  const updateUserAccess = await axios.post(
    `${url}/map/edit`,
    {
      id,
      ...mapData,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => res.data.data);
  try {
    await updateUserAccess;
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error && error.response && error.response.data.message;
    }
    toastr.error('', errorMessage);
    return dispatch(
      MAPS_MODIFY_MAPS_FAIL({
        error: errorMessage,
      })
    );
  }
  toastr.success('', 'Map path updated successfully');
  return dispatch(MAPS_MODIFY_MAPS_SUCCESS({ map: mapData, id }));
};

export const mapsCleanUp = () => (dispatch) => dispatch(MAPS_CLEAN_UP());
