import { Form, Input, InputNumber, Modal } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { regexDescription, regexInput } from 'utils/regex';
import './ModalMediaCategoryForm.scss';
import { log } from 'utils';

const ModalPanelForm = ({ role, visible, onCancel, onCreate }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(role);

  const { loading } = useSelector(
    (state) => ({
      loading: state.roles.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const onChangeHandler = useChangeHandler(setData);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else {
      // setErrors({
      //   name: '',
      //   description: '',
      // });
      onCreate(data);
      form.resetFields();
    }
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={'Roles Authentication Policy'}
      closeIcon={<i className="feather-x" />}
      okText="Submit"
      okButtonProps={{
        className: 'button-submit',
      }}
      cancelButtonProps={{
        className: 'button-cancel',
      }}
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: role.name,
          expirationDuration: role.expirationDuration,
          failedAttempt: role.failedAttempt,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: `'Name' is required` }]}
          help={errors.name ? errors.name : null}
        >
          <Input
            value={role.name}
            name="name"
            placeholder="Enter playlist's name"
            disabled
          />
        </Form.Item>
        <Form.Item
          label="Password age (in days)"
          name="expirationDuration"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.expirationDuration ? errors.expirationDuration : null}
        >
          <InputNumber
            value={role.expirationDuration}
            precision={0}
            name={'expirationDuration'}
            placeholder="Enter password age"
          />
        </Form.Item>
        <Form.Item
          label="Max Failed Login Attempt"
          name="failedAttempt"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.failedAttempt ? errors.failedAttempt : null}
        >
          <Input
            value={role.failedAttempt}
            name="failedAttempt"
            placeholder="Enter max failed attempt"
          />
        </Form.Item>

        {/* <Form.Item
          label="Orientation"
          name="orientation"
          rules={[{ required: true, message: `This field is required` }]}
        >
          <Select placeholder="Select orientation">
            <Select.Option value="Portrait">Portrait</Select.Option>
            <Select.Option value="Landscape">Landscape</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

ModalPanelForm.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    failedAttempt: PropTypes.number,
    expirationDuration: PropTypes.number,
  }),
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
};

export default ModalPanelForm;
