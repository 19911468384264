import React from 'react';

function InfoIcon({ color = '#000000', size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25ZM12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25Z"
        fill={color}
      />
      <path
        d="M12 10.25C12.41 10.25 12.75 10.59 12.75 11L12.75 16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16L11.25 11C11.25 10.59 11.59 10.25 12 10.25Z"
        fill={color}
      />
      <path
        d="M12 7.00012C12.13 7.00012 12.26 7.03012 12.38 7.08012C12.5 7.13012 12.61 7.20012 12.71 7.29012C12.8 7.39012 12.87 7.49012 12.92 7.62012C12.97 7.74012 13 7.87012 13 8.00012C13 8.13012 12.97 8.26012 12.92 8.38012C12.87 8.50012 12.8 8.61012 12.71 8.71012C12.61 8.80012 12.5 8.87012 12.38 8.92012C12.14 9.02012 11.86 9.02012 11.62 8.92012C11.5 8.87012 11.39 8.80012 11.29 8.71012C11.2 8.61012 11.13 8.50012 11.08 8.38012C11.03 8.26012 11 8.13012 11 8.00012C11 7.87012 11.03 7.74012 11.08 7.62012C11.13 7.49012 11.2 7.39012 11.29 7.29012C11.39 7.20012 11.5 7.13012 11.62 7.08012C11.74 7.03012 11.87 7.00012 12 7.00012Z"
        fill={color}
      />
    </svg>
  );
}

export default InfoIcon;
