/* eslint-disable jsx-a11y/label-has-associated-control */
import { Modal } from 'antd';
import './Modal.scss';
import React from 'react';

const ModalConfirmation = ({
  visible,
  title,
  header,
  description,
  onConfirmHandler,
  onCloseHandler,
  icon,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onConfirmHandler}
      onCancel={onCloseHandler}
      footer={
        <div className="delete__modal-footer">
          <button className="button-secondary" onClick={() => onCloseHandler()}>
            Cancel
          </button>
          <button className="button-primary" onClick={() => onConfirmHandler()}>
            Confirm
          </button>
        </div>
      }
    >
      <div className="delete__modal">
        <img src={icon} alt="delete confirmation" />
        <h3>{header}</h3>
        {description && <p>{description}</p>}
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
