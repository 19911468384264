import { Breadcrumb, Input } from 'antd';
import { useChangeHandler } from 'hooks';
import { useParams } from 'react-router-dom';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { modifyRole, fetchRole } from 'state/actions/roles';
import './Panel.css';

function PanelRole() {
  const { id } = useParams();
  const {
    // isAdmin,
    loading,
    panel,
    success,
    updated,
  } = useSelector(
    (state) => ({
      error: state.roles.error,
      success: state.roles.success,
      sites: state.sites.list,
      organizations: state.organizations.list,
      loading: state.roles.loading,
      roles: state.roles.list,
      panel: state.roles.role,
      udpated: state.panel.updated,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [policy, setPolicy] = useState({});

  useEffect(() => {
    dispatch(fetchRole(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPolicy(panel);
  }, [panel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updated) {
      dispatch(fetchRole(id));
    }
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setPolicy);

  const onSubmitHandler = () => {
    const submitRole = {
      expirationDuration: parseInt(policy.expirationDuration),
      failedAttempt: parseInt(policy.failedAttempt),
    };

    dispatch(modifyRole({ ...submitRole, id }));
  };

  const redirect = success && <Redirect to={paths.PANEL} />;

  return (
    <section className="panel-role">
      {role && redirect}
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Panel</Breadcrumb.Item>
              {/* <Breadcrumb.Item className="breadcrumb-active">
                Add New Roles
              </Breadcrumb.Item> */}
            </Breadcrumb>
          </div>
          <h1 className="page-title">Authentication Policy</h1>
        </div>
        <div className="header-right-content">
          {/* <Link to="/roles" className="button-secondary">
            <i className="feather-arrow-left" />
            Back
          </Link> */}
        </div>
      </div>
      <div className="role-form">
        <div className="form-wrapper">
          <div className="form">
            <label className="form-label">Role Name</label>
            <Input
              value={policy ? policy.name : ''}
              placeholder="name"
              type="text"
              disabled={true}
            />
          </div>
          <div className="form">
            <label className="form-label">
              Password age (in days)<span className="is-required">*</span>
            </label>
            <Input
              onChange={onChangeHandler}
              value={policy ? policy.expirationDuration : ''}
              placeholder="180"
              name="expirationDuration"
              type="number"
            />
          </div>
          <div className="form">
            <label className="form-label">
              Max Failed Login Attempt <span className="is-required">*</span>
            </label>
            <Input
              onChange={onChangeHandler}
              value={policy ? policy.failedAttempt : ''}
              placeholder="5"
              name="failedAttempt"
              type="number"
            />
          </div>

          <div>
            {loading ? (
              <button className="button-primary" disabled>
                Loading...
              </button>
            ) : (
              <button className="button-primary" onClick={onSubmitHandler}>
                Save
              </button>
            )}
            <button
              className="button-secondary button-reset"
              onClick={() => {
                dispatch(fetchRole(id));
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      {/* <div className="users__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="id" />
        )}
        {error && 'Show error'}
      </div> */}
    </section>
  );
}

export default PanelRole;
