import { Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { floorCleanUp } from 'state/actions/floor';
import { log } from 'utils';
import { regexDescription, regexInput } from 'utils/regex';

export default function ModalFloorForm({
  setFloor,
  visible,
  isEditing,
  loading,
  onCancel,
  floor,
  onCreate,
}) {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(floorCleanUp());
  }, [dispatch]);

  // const onChangeHandler = useChangeHandler(setFloor);

  const onSave = (values) => {
    if (!regexInput(values.name) && values.name) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
      });
    } else if (!regexInput(values.description) && values.description) {
      setErrors({
        ...errors,
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain a special character',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...floor,
        id: floor._id,
        name: values.name,
        description: values.description,
      });
      form.resetFields();
    }
  };

  const handleChange = (name, value) => {
    setFloor({
      ...floor,
      [name]: value,
    });
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={isEditing ? 'Edit Floor' : 'Create New Floor'}
      okText="Submit"
      closeIcon={<i className="feather-x" />}
      confirmLoading={loading}
      okButtonProps={{
        className: 'button-submit',
      }}
      cancelButtonProps={{
        className: 'button-cancel',
      }}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        // onChange={onChangeHandler}
        initialValues={{
          name: floor.name,
          description: floor.description,
        }}
      >
        <Form.Item
          label="Name"
          // name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input
            placeholder="Enter Floor's name"
            value={floor.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          // name="description"
          rules={[{ required: true }]}
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea
            rows={4}
            placeholder="Description"
            value={floor.description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ModalFloorForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};
