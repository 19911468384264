import { createReducer } from 'redux-act';

import {
  ROLES_FETCH_DATA_INIT,
  ROLES_FETCH_DATA_SUCCESS,
  ROLES_FETCH_DATA_FAIL,
  ROLES_GET_DATA_INIT,
  ROLES_GET_DATA_SUCCESS,
  ROLES_GET_DATA_FAIL,
  ROLES_DELETE_ROLE_INIT,
  ROLES_DELETE_ROLE_SUCCESS,
  ROLES_DELETE_ROLE_FAIL,
  ROLES_CLEAR_DATA,
  ROLES_CREATE_ROLE_INIT,
  ROLES_CREATE_ROLE_SUCCESS,
  ROLES_CREATE_ROLE_FAIL,
  ROLES_MODIFY_ROLE_INIT,
  ROLES_MODIFY_ROLE_SUCCESS,
  ROLES_MODIFY_ROLE_FAIL,
  ROLES_CLEAN_UP,
  ROLES_CLEAR_DATA_LOGOUT,
} from 'state/actions/roles';

const initialState = {
  list: [],
  user: {
    name: '',
    email: '',
    location: '',
    isAdmin: false,
    role: '',
    file: null,
    createdAt: new Date().toDateString(),
    password: '',
    organizationId: '',
    organizationName: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  created: false,
  updated: false,
};

export const roleReducer = createReducer(
  {
    [ROLES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ROLES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      loading: false,
      error: null,
    }),
    [ROLES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_GET_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ROLES_GET_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      role: payload.role || {},
      loading: false,
      error: null,
    }),
    [ROLES_GET_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_DELETE_ROLE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ROLES_DELETE_ROLE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [ROLES_DELETE_ROLE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [ROLES_CREATE_ROLE_INIT]: (state) => ({
      ...state,
      loading: true,
      created: false,
    }),
    [ROLES_CREATE_ROLE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.role),
      loading: false,
      error: null,
      success: true,
      created: true,
    }),
    [ROLES_CREATE_ROLE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_MODIFY_ROLE_INIT]: (state) => ({
      ...state,
      loading: true,
      updated: false,
    }),
    [ROLES_MODIFY_ROLE_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem._id === payload.role._id) {
              return {
                ...payload.role,
              };
            }
            return elem;
          }),
      // list: payload.role,
      loading: false,
      error: null,
      success: true,
      updated: true,
      message: payload.message,
    }),
    [ROLES_MODIFY_ROLE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ROLES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ROLES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
