import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { authReducer } from './auth';
import { usersReducer } from './users';
import { organizationsReducer } from './organizations';
import { sitesReducer } from './sites';
import { screensReducer } from './screens';
import { filesReducer } from './files';
import { poisReducer } from './pois';
import { categoriesReducer } from './categories';
import { mediaCategoriesReducer } from './mediaCategories';
import { mediaReducer } from './media';
import { programmeCategoriesReducer } from './programmeCategories';
import { programmeReducer } from './programme';
import { preferencesReducer } from './preferences';
import { mapsReducer } from './maps';
import { mapsStaticReducer } from './mapsStatic';
import { feedbackReducer } from './feedback';
import { respondentReducer } from './respondent';
import { screenshotReducer } from './screenshot';
import { informationReducer } from './information';
import { informationCategoriesReducer } from './informationCategories';
import { logsReducer } from './log';
import { tabReducer } from './tab';
import { configReducer } from './config';
import { settingsReducer } from './settings';
import { floorReducer } from './floors';
import { roleReducer } from './roles';
import { panelReducer } from './panel';
import { menuReducer } from './menu';
import { screenusersReducer } from './screenusers';
import { breadcrumbReducer } from './breadcrumb';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  organizations: persistReducer(
    {
      key: 'organizations',
      storage,
      blacklist: ['error', 'loading'],
    },
    organizationsReducer
  ),
  sites: persistReducer(
    {
      key: 'sites',
      storage,
      blacklist: ['error', 'loading'],
    },
    sitesReducer
  ),
  screens: persistReducer(
    {
      key: 'screens',
      storage,
      blacklist: ['error', 'warning'],
    },
    screensReducer
  ),
  files: persistReducer(
    {
      key: 'files',
      storage,
      blacklist: ['error', 'warning'],
    },
    filesReducer
  ),
  pois: persistReducer(
    {
      key: 'pois',
      storage,
      blacklist: ['error', 'loading'],
    },
    poisReducer
  ),
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: ['error', 'loading', 'category'],
    },
    categoriesReducer
  ),
  mediaCategories: persistReducer(
    {
      key: 'mediaCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    mediaCategoriesReducer
  ),
  media: persistReducer(
    {
      key: 'media',
      storage,
      blacklist: ['error', 'loading', 'media'],
    },
    mediaReducer
  ),
  programmeCategories: persistReducer(
    {
      key: 'programmeCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    programmeCategoriesReducer
  ),
  programme: persistReducer(
    {
      key: 'programme',
      storage,
      blacklist: ['error', 'loading', 'programme'],
    },
    programmeReducer
  ),
  maps: persistReducer(
    {
      key: 'maps',
      storage,
      blacklist: ['error', 'loading'],
    },
    mapsReducer
  ),
  mapsStatic: persistReducer(
    {
      key: 'mapStatic',
      storage,
      blacklist: ['error', 'loading'],
    },
    mapsStaticReducer
  ),
  feedback: persistReducer(
    {
      key: 'feedback',
      storage,
      blacklist: ['error', 'loading'],
    },
    feedbackReducer
  ),
  floor: persistReducer(
    {
      key: 'floor',
      storage,
      blacklist: ['error', 'loading'],
    },
    floorReducer
  ),
  respondent: persistReducer(
    {
      key: 'respondent',
      storage,
      blacklist: ['error', 'loading'],
    },
    respondentReducer
  ),
  screenshot: persistReducer(
    {
      key: 'screenshot',
      storage,
      blacklist: ['error', 'loading'],
    },
    screenshotReducer
  ),
  information: persistReducer(
    {
      key: 'information',
      storage,
      blacklist: ['error', 'loading', 'information'],
    },
    informationReducer
  ),
  informationCategories: persistReducer(
    {
      key: 'informationCategories',
      storage,
      blacklist: ['error', 'loading', 'category'],
    },
    informationCategoriesReducer
  ),
  config: persistReducer(
    {
      key: 'config',
      storage,
      blacklist: ['error', 'loading'],
    },
    configReducer
  ),
  settings: persistReducer(
    {
      key: 'settings',
      storage,
      blacklist: ['error', 'loading'],
    },
    settingsReducer
  ),

  logs: persistReducer(
    {
      key: 'logs',
      storage,
      blacklist: ['error', 'loading'],
    },
    logsReducer
  ),
  roles: persistReducer(
    {
      key: 'roles',
      storage,
      blacklist: ['error', 'loading'],
    },
    roleReducer
  ),
  panel: persistReducer(
    {
      key: 'panel',
      storage,
      blacklist: ['error', 'loading'],
    },
    panelReducer
  ),
  toastr: toastrReducer,
  tab: tabReducer,
  breadcrumb: breadcrumbReducer,
  menu: persistReducer(
    {
      key: 'menu',
      storage,
    },
    menuReducer
  ),
  screenusers: persistReducer(
    {
      key: 'screenusers',
      storage,
      blacklist: ['error', 'loading'],
    },
    screenusersReducer
  ),
});
