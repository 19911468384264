import { createReducer } from 'redux-act';
import {
  ACTIVE_MENU,
  ACTIVE_TAB_MEDIA,
  ACTIVE_TAB_TENANT,
} from 'state/actions/breadcrumb';

const initialState = {
  list: [{ menu: '', link: '' }],
  media: 'published',
  tenant: 'published',
};

export const breadcrumbReducer = createReducer(
  {
    [ACTIVE_MENU]: (state, payload) => ({
      ...state,
      list: payload.list,
    }),
    [ACTIVE_TAB_MEDIA]: (state, payload) => ({
      ...state,
      media: payload.media,
    }),
    [ACTIVE_TAB_TENANT]: (state, payload) => ({
      ...state,
      tenant: payload.tenant,
    }),
  },
  initialState
);
