import { useState, useEffect } from 'react';
import axios from 'axios';
import { log, refreshToken } from 'utils';
import { url } from 'utils/url';
import { logout } from 'state/actions/auth';
// import { useDispatch } from 'react-redux';

axios.defaults.withCredentials = true;

export const getRole = async () => {
  try {
    const token = await refreshToken();
    const response = await axios.get(`${url}/user/role`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    log('Error retrieving role:', error);
    logout();
    return null;
  }
};

export const getIsPasswordChanged = async () => {
  try {
    const token = await refreshToken();
    const response = await axios.get(`${url}/user/isPasswordChanged`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    log('isPasswordChanged', response.data.isPasswordChanged)
    return response.data.isPasswordChanged;
  } catch (error) {
    log('Error retrieving role:', error);
    logout();
    return false;
  }
};

export const getIsPasswordExpired = async () => {
  try {
    const token = await refreshToken();
    const response = await axios.get(`${url}/user/isPasswordExpired`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    log('isPasswordExpired', response.data.isPasswordExpired)
    return response.data.isPasswordExpired;
  } catch (error) {
    log('Error retrieving role:', error);
    logout();
    return false;
  }
};

export const getRoleDispatch = async (dispatch) => {

  try {
    const token = await refreshToken();
    const response = await axios.get(`${url}/user/role`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    log('Error retrieving role:', error);
    dispatch(logout());
    return null;
  }
};

export const getRoleHooks = (accessToken) => {
  const [role, setRole] = useState(null);
  const [isUserRoleChecked, setIsUserRoleChecked] = useState(false);
  // const dispatch = useDispatch();

  const checkUserRole = async () => {
    log(`check userrole token ? ${accessToken}`);
    try {
      const token = await refreshToken();
      const response = await axios.get(`${url}/user/role`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsUserRoleChecked(true);
      setRole(response.data.data);
    } catch (error) {
      log('Error retrieving role:', error);
      setIsUserRoleChecked(true);
      // dispatch(logout());
      return null;
    }
  };

  useEffect(() => {
    if (!isUserRoleChecked) {
      checkUserRole();
    }
  }, [isUserRoleChecked]);

  return [role, checkUserRole];
};
