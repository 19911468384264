import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import SiteForm from 'components/SiteForm';
import { createSite, fetchSites, modifySite } from 'state/actions/sites';
import { useFormatMessage } from 'hooks';

const Site = ({ history }) => {
  const { id } = useParams();

  const {
    success,
    userData,
    sitesList,
    siteData,
    error,
    organizations,
  } = useSelector(
    (state) => ({
      success: state.sites.success,
      userData: state.auth.userData,
      sitesList: state.sites.list,
      siteData: state.sites.site,
      error: state.sites.error,
      organizations: state.organizations.list,
    }),
    shallowEqual
  );

  const [site, setSite] = useState(
    siteData || {
      name: '',
      description: '',
      modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
      modifiedBy: userData.email ? userData.email : '',
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const siteFetched = sitesList.find(
        (fetchedSite) => fetchedSite.id === id
      );
      if (siteFetched) {
        setSite(siteFetched);
      } else if (siteData.id === id) {
        setSite(siteData);
      } else {
        dispatch(fetchSites(id));
      }
    }
  }, [id, siteData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEditing = !!id;

  const siteForm =
    !site && id ? (
      <ClipLoader />
    ) : (
      <SiteForm
        isEditing={isEditing}
        id={id}
        site={site}
        user={userData}
        organizations={organizations}
        setSite={setSite}
        action={isEditing ? modifySite : createSite}
        history={history}
        success={success}
        error={error}
      />
    );

  const editSiteMessage = useFormatMessage('Site.editSite');

  const newSiteMessage = useFormatMessage('Site.newSite');

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editSiteMessage : newSiteMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">{siteForm}</section>
    </>
  );
};

export default Site;
