import React from 'react';
import Report from 'components/Report';
import { useParams } from 'react-router-dom';

const Analytics = () => {

  const { siteId } = useParams();

  return (
    <>
      <section className="section is-main-section">
        <Report siteId={siteId} />
      </section>
    </>
  )
}

export default Analytics;
